// @flow
import React, { useState } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useDispatch } from 'react-redux';
import { makeBrandAdmin, brandApply } from '@ovrture/react-redux';
const BrandAdminSetting = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatchAction = useDispatch();
    const handleBrandAdmin = () => {
        dispatchAction(makeBrandAdmin(props.data.data));
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
    };
    const handleBrandAccess = () => {
        dispatchAction(brandApply(props.data.accessUpdateData));
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
    };
    return (
        <>
            {props.data.name === 'barndAdminConfirmation' ? (
                <p>Are you sure you want make this/these administrator assignment(s)?</p>
            ) : props.data.name === 'brandAllUserConfirmation' ? (
                <p>Are you sure you want to provide access to this brand to all users?</p>
            ) : props.data.name === 'brandSelectGroup' ? (
                <p>Are you sure you want to provide access to this brand to these selected groups?</p>
            ) : props.data.name === 'brandSelectUser' ? (
                <p>Are you sure you want to provide access to this brand to these selected users?</p>
            ) : (
                ''
            )}
            <div className="modal-footer d-flex justify-content-center">
                <ButtonElement
                    name="Yes"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        props.data.name === 'barndAdminConfirmation' ? handleBrandAdmin() : handleBrandAccess();
                    }}
                />
                <ButtonElement
                    name="No"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default BrandAdminSetting;
