// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import HyperDatepicker from '../../../components/datepicker/Datepicker';
import { useModalContext } from '../../../contexts/ModalContext';
import Moment from 'moment';
import { modalActions } from '../../../reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { userList, analyticRecordsExportDownload, analyticDataExportReset } from '@ovrture/react-redux';

const ExportRecordBasedDataAndSnapshotsModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [isDate, setIsDate] = useState('all');
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [user, setUser] = useState('');
    const [toggle, setToggle] = useState(true);

    const dispatchAction = useDispatch();
    const { recordBasedDataSnapshot, users } = useSelector((state) => ({
        users: state?.user?.userList,
        recordBasedDataSnapshot: state?.analyticDataExport?.recordExportDownload,
    }));
    useEffect(() => {
        dispatchAction(
            userList({
                isAnd: true,
                isUnpaged: true,
            })
        );
    }, []);

    useEffect(() => {
        if (recordBasedDataSnapshot) {
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            dispatchAction(analyticDataExportReset());
        }
    }, [recordBasedDataSnapshot]);

    const onSelectStartDate = (date) => {
        setSelectedStartDate(date);
    };
    const onSelectEndDate = (date) => {
        setSelectedEndDate(date);
    };

    const recordBasedDownload = () => {
        const payload = {
            userId: user,
        };
        if (isDate == 'date') {
            selectedStartDate && (payload.startDate = Moment(selectedStartDate).format('MM/DD/YYYY'));
            selectedEndDate && (payload.endDate = Moment(selectedEndDate).format('MM/DD/YYYY'));
        }
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
        dispatchAction(analyticRecordsExportDownload(payload));
    };
    return (
        <>
            <div className="custom-padding">
                <Col md={6}>
                    <FormInput
                        type="select"
                        className="custom-select"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        label="User(s)">
                        <option value="">All Users</option>
                        {users?.content.map((usr) => {
                            return (
                                <option key={usr.id} value={usr.id}>
                                    {usr.colFullName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
                <Col md={12} className="mt-3">
                    <p>
                        <b>Date Range:</b>
                    </p>
                    <FormInput
                        type="radio"
                        id="selectedTimeBitForAll"
                        name="selectedTimeBit"
                        label="All"
                        value="all"
                        className="ms-1 mt-1"
                        onChange={(e) => setIsDate(e.target.value)}
                        checked={isDate == 'all' ? true : false}
                    />
                    <Row className="mt-1">
                        <Col md={1}>
                            <FormInput
                                type="radio"
                                id="selectedTimeBitForSelectedDates"
                                name="selectedTimeBit"
                                label=""
                                className="ms-1 mt-1"
                                value="date"
                                onChange={(e) => setIsDate(e.target.value)}
                                checked={isDate == 'date' ? true : false}
                            />
                        </Col>
                        <Col md={5} className="mt-2 mt-md-0 ps-md-0 ps-2">
                            <HyperDatepicker
                                value={selectedStartDate}
                                inputClass="form-control-light"
                                onChange={(date) => {
                                    onSelectStartDate(date);
                                }}
                                disabled={isDate == 'all' ? true : false}
                            />
                        </Col>
                        <Col md={1} className="mt-1">
                            to
                        </Col>
                        <Col md={5} className="ps-md-1 ps-2">
                            <HyperDatepicker
                                value={selectedEndDate}
                                inputClass="form-control-light"
                                onChange={(date) => {
                                    onSelectEndDate(date);
                                }}
                                disabled={isDate == 'all' ? true : false}
                            />
                        </Col>
                    </Row>
                </Col>
            </div>
            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Download"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        if (toggle) {
                            setToggle(false);
                            recordBasedDownload();
                        }
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default ExportRecordBasedDataAndSnapshotsModal;
