// @flow
import React, { useState } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const ExportFundDetailsModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(true);

    return (
        <>
            <div className="custom-padding">
                <p>Download all fund details for all funds.</p>
            </div>
            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Download"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        if (toggle) {
                            setToggle(false);
                            props?.hideModal();
                        }
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default ExportFundDetailsModal;
