export const modalActions = {
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
};

export const notificationActions = {
    OPEN_NOTIFICATION: 'OPEN_NOTIFICATION',
    CLOSE_NOTIFICATION: 'CLOSE_NOTIFICATION',
};

export const configActions = {
    PLATFORM_ADMIN: 'PLATFORM_ADMIN',
    SYSTEM_ADMIN: 'SYSTEM_ADMIN',
    APPLICATION_ADMIN: 'APPLICATION_ADMIN',
    USER: 'USER',
    REPORT_ADMIN: 'REPORT_ADMIN',
    BRAND_ADMIN: 'BRAND_ADMIN',
    CONTENT_ADMIN: 'CONTENT_ADMIN',
    RESET: 'RESET',
};

export const treeViewActions = {
    ADD_FOLDER: 'ADD_fOLDER',
    GET_TREE_DATA: 'GET_TREE_DATA',
    GET_SELECTED_NODE: 'GET_SELECTED_NODE',
    DELETE_SELECTED_NODE: 'DELETE_SELECTED_NODE',
    RENAME_SELECTED_NODE: 'RENAME_SELECTED_NODE',
    RELOCATE_SELECTED_NODE: 'RELOCATE_SELECTED_NODE',
    GET_LCATION_SELECTED_NODE: 'GET_LCATION_SELECTED_NODE',
    GET_CONTENT_PANEL: 'GET_CONTENT_PANEL',
    ADD_CONTENT_PANEL: 'ADD_CONTENT_PANEL',
    DUPLICATE_CONTENT_PANEL: 'DUPLICATE_CONTENT_PANEL',
};
