//@flow
import React from 'react';
//components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const DeleteTransferFinalConfirmModal = (props) => {
    const { modalDispatch } = useModalContext();
    return (
        <>
            {props?.data?.actionType === 'softDeleteUserandGroups' ? (
                <p>Last chance. Are you sure you want to delete user account(s) and all associated data?</p>
            ) : props?.data?.actionType === 'bulkDeleteUserandGroups' ? (
                <p>Last chance. Are you sure you want to delete user account(s) and all associated data?</p>
            ) : props?.data?.actionType === 'bulkRecordsDelete' ? (
                <p>
                    Are you sure you want to delete Barnard College's client record permanently? Doing so will discard
                    all related sites, reports, content, and data.
                </p>
            ) : props?.data?.item === 'deleteRecord' ? (
                <p>
                    Are you sure you want to delete {props.data.original?.user?.organaisationName} client record
                    permanently? Doing so will discard all related sites, reports, content, and data.
                </p>
            ) : props?.data?.actionType === 'deleteRecordsRestore' ? (
                <p>
                    WARNING: If you elect to delete, you will not be able to retrieve any information related to this
                    record.
                </p>
            ) : props?.data?.item === 'deleteSite' ? (
                <p>Are you sure you want to delete this site permanently</p>
            ) : props?.data?.item === 'deleteReport' ? (
                <p>Are you sure you want to delete this report permanently</p>
            ) : props?.data?.actionType === 'bulkSitesDelete' ? (
                <p>Are you sure you want to delete these sites permanently?</p>
            ) : props?.data?.actionType === 'bulkReportsDelete' ? (
                <p>Are you sure you want to delete these reports permanently?</p>
            ) : (
                ''
            )}
            <div className="modal-footer">
                {props.active !== 0 && (
                    <ButtonElement
                        name="Previous"
                        variant="outline-primary"
                        className="btn-prev"
                        type="button"
                        onClick={() => props.setActive(props.active - 1)}
                    />
                )}
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() => modalDispatch({ type: modalActions.CLOSE_MODAL })}
                />
                <ButtonElement
                    name="Delete"
                    variant="primary"
                    className="btn-next"
                    type="button"
                    onClick={() => props.data.action(props?.data?.actionType, props?.data)}
                />
            </div>
        </>
    );
};

export default DeleteTransferFinalConfirmModal;
