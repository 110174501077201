import { GenericFilter, DateRangeFilter, YearFilter } from '../components/table/filter';

const table = {
    platFormAdmin: {
        dashbard: (cell) => [
            {
                Header: 'Tenant',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Status',
                accessor: 'isActive',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Admin Sign In',
                accessor: 'login',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Users',
                accessor: 'userCount',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'action',
                Cell: cell[2],
                sort: false,
            },
        ],
        GenericFilter: (props) => <GenericFilter {...props} />,
    },
    editElent: {
        core: {
            basicElements: (cell) => [
                {
                    Header: 'Elements Name',
                    accessor: 'elementName',
                    sort: true,
                },
                {
                    Header: 'Last Edited',
                    accessor: 'lastEdited',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sort: false,
                    Cell: cell[0],
                },
            ],
            broadcast: (cell) => [
                {
                    Header: 'Elements Name',
                    accessor: 'name',
                    sort: true,
                },
                {
                    Header: 'Last Edited',
                    accessor: 'dateModified',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sort: false,
                    Cell: cell[0],
                },
            ],
        },
        brands: (cell) => [
            {
                Header: 'Elements Name',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Last Edited',
                accessor: 'dateModified',
                sort: true,
            },
            {
                Header: 'Site Edited',
                accessor: 'siteEditedDate',
                sort: false,
            },
            {
                Header: 'Report Edited',
                accessor: 'reportEditedDate',
                sort: false,
            },
            {
                Header: 'General',
                accessor: 'general',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Site Elements',
                accessor: 'siteElements',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Report Elements',
                accessor: 'reportElements',
                sort: false,
                Cell: cell[2],
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[3],
            },
        ],
        contentPanel: {
            stadardTemplate: (cell) => [
                {
                    Header: 'Elements Name',
                    accessor: 'name',
                    sort: true,
                },
                {
                    Header: 'Palette',
                    accessor: 'paletteName',
                    sort: true,
                },
                {
                    Header: 'Panel Type',
                    accessor: 'forSystemName',
                    sort: true,
                    customAccessor: 'isForSystem',
                },
                {
                    Header: 'Last Edited',
                    accessor: 'dateModified',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'actions1',
                    sort: false,
                    Cell: cell[0],
                },
            ],
            sitePrintBridge: (cell) => [
                {
                    Header: 'Elements Name',
                    accessor: 'name',
                    sort: true,
                },
                {
                    Header: 'Last Edited',
                    accessor: 'dateModified',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sort: false,
                    Cell: cell[0],
                },
            ],
        },
        reportData: {
            reportData: (cell) => [
                {
                    Header: 'Elements Name',
                    accessor: 'elementName',
                    sort: true,
                },
                {
                    Header: 'Last Edited',
                    accessor: 'lastEdited',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sort: false,
                    Cell: cell[0],
                },
            ],
            editReport: (cell) => [
                {
                    Header: ' Field Name',
                    accessor: 'fieldName',
                    sort: true,
                },
                {
                    Header: 'Render',
                    accessor: 'render',
                    sort: true,
                    Cell: cell[0],
                },
                {
                    Header: 'Mandatory',
                    accessor: 'mandatory',
                    sort: false,
                    Cell: cell[1],
                },
                {
                    Header: 'Default value',
                    accessor: 'defaultValue',
                    sort: false,
                    Cell: cell[2],
                },
                {
                    Header: 'Preprocessing value',
                    accessor: 'preprocessingValue',
                    sort: false,
                    Cell: cell[3],
                },
            ],
            fundDescription: (cell) => [
                {
                    Header: ' Field Name',
                    accessor: 'fieldName',
                    sort: false,
                    Cell: cell[0],
                },
                {
                    Header: 'Render',
                    accessor: 'render',
                    sort: false,
                    Cell: cell[1],
                },
                {
                    Header: 'Mandatory',
                    accessor: 'mandatory',
                    sort: false,
                    Cell: cell[2],
                },
                {
                    Header: 'Default Value',
                    accessor: 'defaultValue',
                    sort: false,
                    Cell: cell[3],
                },
                {
                    Header: 'Preprocessing Value',
                    accessor: 'preprocessingValue',
                    sort: false,
                    Cell: cell[4],
                },
            ],
            fundDonor: (cell) => [
                {
                    Header: ' Field Name',
                    accessor: 'fieldName',
                    sort: false,
                    Cell: cell[0],
                },
                {
                    Header: 'Render',
                    accessor: 'render',
                    sort: false,
                    Cell: cell[1],
                },
                {
                    Header: 'Mandatory',
                    accessor: 'mandatory',
                    sort: false,
                    Cell: cell[2],
                },
                {
                    Header: 'Default Value',
                    accessor: 'defaultValue',
                    sort: false,
                    Cell: cell[3],
                },
                {
                    Header: 'Preprocessing Value',
                    accessor: 'preprocessingValue',
                    sort: false,
                    Cell: cell[4],
                },
            ],
            fundImpact: (cell) => [
                {
                    Header: ' Field Name',
                    accessor: 'fieldName',
                    sort: false,
                    Cell: cell[0],
                },
                {
                    Header: 'Render',
                    accessor: 'render',
                    sort: false,
                    Cell: cell[1],
                },
                {
                    Header: 'Mandatory',
                    accessor: 'mandatory',
                    sort: false,
                    Cell: cell[2],
                },
                {
                    Header: 'Default Value',
                    accessor: 'defaultValue',
                    sort: false,
                    Cell: cell[3],
                },
                {
                    Header: 'Preprocessing Value',
                    accessor: 'preprocessingValue',
                    sort: false,
                    Cell: cell[4],
                },
            ],
            fundPerformance: (cell) => [
                {
                    Header: ' Field Name',
                    accessor: 'fieldName',
                    sort: false,
                    Cell: cell[0],
                },
                {
                    Header: 'Render',
                    accessor: 'render',
                    sort: false,
                    Cell: cell[1],
                },
                {
                    Header: 'Mandatory',
                    accessor: 'mandatory',
                    sort: false,
                    Cell: cell[2],
                },
                {
                    Header: 'Default Value',
                    accessor: 'defaultValue',
                    sort: false,
                    Cell: cell[3],
                },
                {
                    Header: 'Preprocessing Value',
                    accessor: 'preprocessingValue',
                    sort: false,
                    Cell: cell[4],
                },
            ],
            fundType: (cell) => [
                {
                    Header: 'Fund Type',
                    accessor: 'colFundTypeName',
                    sort: true,
                    Cell: cell[0],
                },
                {
                    Header: 'Fund Code',
                    accessor: 'colFundKey',
                    sort: true,
                    Cell: cell[1],
                },
                {
                    Header: 'Actions',
                    accessor: 'fundTypeActions',
                    sort: false,
                    Cell: cell[2],
                },
            ],
            impactType: (cell) => [
                {
                    Header: 'Impact Type',
                    accessor: 'impactType',
                    sort: true,
                    Cell: cell[0],
                },
                {
                    Header: 'Impact Code',
                    accessor: 'uploaderFundCode',
                    sort: true,
                    Cell: cell[1],
                },
                {
                    Header: 'Action',
                    accessor: 'action',
                    sort: false,
                    Cell: cell[2],
                },
            ],
        },

        reportPanel: {
            reportPanelMap: (cell) => [
                {
                    Header: 'Palette',
                    accessor: 'palette',
                    sort: true,
                    customAccessor: 'tblPalette.colPaletteName',
                },
                {
                    Header: 'Template Name',
                    accessor: 'template',
                    sort: true,
                    customAccessor: 'templateName',
                },
                {
                    Header: 'Created At',
                    accessor: 'colCreatedAt',
                    sort: true,
                },
                {
                    Header: 'Updated At',
                    accessor: 'colUpdatedAt',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'edit',
                    sort: false,
                    Cell: cell[0],
                },
            ],
            reportPanelHtml: (cell) => [
                {
                    Header: 'Palette',
                    accessor: 'colPaletteName',
                    sort: true,
                    customAccessor: 'tblPalette.colPaletteName',
                },
                {
                    Header: 'Template Name',
                    accessor: 'templateName',
                    sort: true,
                },
                {
                    Header: 'RGCP Type',
                    accessor: 'colRgcpTypeName',
                    sort: true,
                    customAccessor: 'tblRgcpType.colRgcpTypeName',
                },
                {
                    Header: 'Default',
                    accessor: 'colDefault',
                    sort: true,
                },
                {
                    Header: 'Html Type Name',
                    accessor: 'colHtmlTypeName',
                    sort: true,
                },
                {
                    Header: 'Created At',
                    accessor: 'colCreatedAt',
                    sort: true,
                },
                {
                    Header: 'Updated At',
                    accessor: 'colUpdatedAt',
                    sort: true,
                },
                {
                    Header: 'Actions',
                    accessor: 'edit',
                    sort: false,
                    Cell: cell[0],
                },
            ],
        },
        reportPrintBridge: (cell) => [
            {
                Header: 'Elements Name',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Last Edited',
                accessor: 'dateModified',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
    },
    restoresystem: {
        restoresystem: (cell) => [
            {
                Header: 'Tenant',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Restore',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
    },
    emailTemplate: {
        emailTemplate: (cell) => [
            {
                Header: 'Email Name',
                accessor: 'colEmailTemplateName',
                sort: true,
            },
            {
                Header: 'Last Edited',
                accessor: 'colUpdatedAt',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
    },
    activeLog: {
        activeLog: (cell) => [
            {
                Header: 'Session ID',
                accessor: 'colSessionId',
                sort: true,
            },
            {
                Header: 'Activity',
                accessor: 'activity',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'App Server',
                accessor: 'colAppServerName',
                sort: true,
            },
            {
                Header: 'Username/Email',
                accessor: 'user.email',
                sort: true,
            },
            {
                Header: 'SSO',
                accessor: 'colLoginWithSso',
                sort: false,
            },
            {
                Header: 'Domain',
                accessor: 'colDomainName',
                sort: true,
            },
            {
                Header: 'Created',
                accessor: 'colCreatedAt',
                sort: true,
            },
            {
                Header: 'Last Active',
                accessor: 'colLastActivityDateTime',
                sort: true,
            },
            {
                Header: 'Destroyed',
                accessor: 'colSessionDestroyedDateTime',
                sort: true,
            },
            {
                Header: 'Time Zone',
                accessor: 'colUserTimeZone',
                sort: true,
            },
            {
                Header: 'Session Link',
                accessor: 'colMouseFlowSessionLink',
                sort: true,
            },
            {
                Header: 'Remote IP',
                accessor: 'colUserRemoteIpAddress',
                sort: true,
            },
            {
                Header: 'User Agent',
                accessor: 'colHttpBrowserInfo',
                sort: true,
            },
            {
                Header: 'As User',
                accessor: 'loginAsUserEmail',
                sort: true,
            },
            {
                Header: 'Session Ended By',
                accessor: 'colLogoutType',
                sort: true,
            },
        ],
        GenericFilter: (props) => <GenericFilter {...props} />,
    },
    activitylogdetail: {
        activitylogdetail: () => [
            {
                Header: 'Session ID',
                accessor: 'colSessionId',
                sort: true,
            },
            {
                Header: 'Remote IP',
                accessor: 'colUserRemoteIpAddress',
                sort: true,
            },
            {
                Header: 'URL',
                accessor: 'colRequestCompleteUrl',
                sort: true,
            },
            {
                Header: 'HTTP Method',
                accessor: 'colHttpMethodType',
                sort: true,
            },
            {
                Header: 'HTTP Status Code',
                accessor: 'colStatusCode',
                sort: true,
            },
            {
                Header: 'HTTP Variables',
                accessor: 'colHttpVariables',
                sort: false,
            },
            {
                Header: 'Started',
                accessor: 'colProcessingStartDateTime',
                sort: true,
            },
            {
                Header: 'Finished',
                accessor: 'colRequestFinishedDateTime',
                sort: true,
            },
            {
                Header: 'Time (ms)',
                accessor: 'colCalculatedTimeSpentInMilisecond',
                sort: true,
            },
            {
                Header: 'User Agent',
                accessor: 'colHttpBrowserInfo',
                sort: true,
            },
            {
                Header: 'As User',
                accessor: 'colLogoutType',
                sort: true,
            },
        ],
    },
    systemAnalytics: {
        systemAnalytics: () => [
            {
                Header: 'Tenant',
                accessor: 'name',
                sort: true,
            },
            {
                Header: 'Active Users',
                accessor: 'activeUserCount',
                sort: true,
            },
            {
                Header: 'Sites',
                accessor: 'siteCount',
                sort: true,
            },
            {
                Header: 'Reports',
                accessor: 'reportCount',
                sort: true,
            },
            {
                Header: 'Sessions',
                accessor: 'sessionCount',
                sort: true,
            },
            {
                Header: 'Image Uploads',
                accessor: 'imageUploadCount',
                sort: true,
            },
            {
                Header: 'PDF Builds',
                accessor: 'pdfBuildCount',
                sort: true,
            },
        ],
        GenericFilter: (props) => <GenericFilter {...props} />,
    },

    systemAdmin: {
        reviewAndApprovalSites: (cell) => [
            {
                Header: 'Last/Corp. Name',
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: 'First Name',
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'colProspectOwner',
                sort: true,
                Cell: cell[2],
            },
            {
                Header: 'Submitted',
                accessor: 'dateSubmited',
                sort: true,
            },
            {
                Header: 'Review/Edit',
                accessor: 'review',
                sort: false,
                Cell: cell[0],
                descriptor: true,
                descriptorMsg:
                    'As the system administrator or a designated brand administrator, you have the authority to access, review, and edit the sites listed below.',
            },
            {
                Header: 'Approve',
                accessor: 'approve',
                sort: false,
                Cell: cell[1],
                descriptor: true,
                descriptorMsg:
                    'As the system administrator or a designated brand administrator, you are responsible for approving the first iteration of the sites listed below. Once approved, the site URL will be activated for sharing. NOTE: Subsequent iterations of each site will not require approval.',
            },
        ],
        GenericFilter: (props) => <GenericFilter {...props} />,
        reviewAndApprovalReports: (cell) => [
            {
                Header: 'Last/Corp. Name',
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: 'First Name',
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'user.colFullName',
                sort: true,
                Cell: cell[2],
            },
            {
                Header: 'Fiscal Year',
                accessor: 'colFiscalYear',
                sort: true,
            },
            {
                Header: 'Submitted',
                accessor: 'colSubmitedDate',
                sort: true,
            },
            {
                Header: 'Review/Edit',
                accessor: 'review',
                sort: false,
                Cell: cell[0],
                descriptor: true,
                descriptorMsg:
                    'As the system administrator or a designated brand administrator, you have the authority to access, review, and edit the report listed below.',
            },
            {
                Header: 'Approve',
                accessor: 'approve',
                sort: false,
                Cell: cell[1],
                descriptor: true,
                descriptorMsg:
                    'As the system administrator or a designated brand administrator, you are responsible for approving the first iteration of the report listed below. Once approved, the site URL will be activated for sharing. NOTE: Subsequent iterations of each report will not require approval.',
            },
        ],
        users: (cell) => [
            {
                Header: 'Last Name',
                accessor: 'colLastName',
                sort: true,
                Cell: cell[1],
            },
            {
                Header: 'First Name',
                accessor: 'colFirstName',
                sort: true,
                Cell: cell[2],
            },
            {
                Header: 'Role',
                accessor: 'roleName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        viewPortfolio: (cell) => [
            {
                Header: 'Last/Corp. Name',
                accessor: 'colRecordLastName',
                sort: true,
            },
            {
                Header: 'First Name',
                accessor: 'colRecordFirstName',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'user.colFullName',
                sort: true,
                cell: cell[3],
            },
            {
                Header: 'Site',
                accessor: 'site',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Latest FY Report',
                accessor: 'latestFyReport',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[2],
            },
        ],
        restoreRecords: (cell) => [
            {
                Header: 'Last/Corp. Name',
                accessor: 'colRecordLastName',
                sort: true,
            },
            {
                Header: 'First Name',
                accessor: 'colRecordFirstName',
                sort: true,
            },
            {
                Header: 'Reference Number',
                accessor: 'colRecordReferenceNo',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'colRecordOwnerEmailId',
                sort: true,
            },

            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],

        viewSites: (cell) => [
            {
                Header: 'Last/Corp. Name',
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: 'First Name',
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'tblRecords.user.colFullName',
                sort: true,
            },
            {
                Header: 'Analytics & Alerts',
                accessor: 'analytics',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Status',
                accessor: 'siteStatus',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Last Modified',
                accessor: 'dateModified',
                sort: true,
            },
            {
                Header: 'Approval',
                accessor: 'colApprovedByAdmin',
                sort: true,
                Cell: cell[2],
            },
            {
                Header: 'Edit',
                accessor: 'editAction',
                sort: false,
                Cell: cell[3],
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[4],
            },
        ],
        viewReports: (cell) => [
            {
                Header: 'Last/Corp. Name',
                accessor: 'tblRecords.colRecordLastName',
                sort: true,
            },
            {
                Header: 'First Name',
                accessor: 'tblRecords.colRecordFirstName',
                sort: true,
            },
            {
                Header: 'Owner',
                accessor: 'tblRecords.user.colFullName',
                sort: true,
            },
            {
                Header: 'Fiscal Year',
                accessor: 'colFiscalYear',
                sort: true,
            },
            {
                Header: 'Analytics & Alerts',
                accessor: 'analytics',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Status',
                accessor: 'siteStatus',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Last Modified',
                accessor: 'colUpdatedAt',
                sort: true,
            },
            {
                Header: 'Approval',
                accessor: 'colIsApproved',
                sort: true,
                Cell: cell[2],
            },
            {
                Header: 'Edit',
                accessor: 'editAction',
                sort: false,
                Cell: cell[3],
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[4],
            },
        ],
        snapshots: (cell) => [
            {
                Header: 'Date & Time',
                accessor: 'dateTime',
                sort: true,
            },
            {
                Header: 'Activity',
                accessor: 'activity',
                sort: true,
            },
            {
                Header: 'Duration',
                accessor: 'duration',
                sort: true,
            },
            {
                Header: 'Location',
                accessor: 'location',
                sort: true,
            },
            {
                Header: 'Details',
                accessor: 'details',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Snapshot',
                accessor: 'snapshot',
                sort: false,
            },
            {
                Header: 'Actions',
                accessor: 'delete',
                sort: false,
                Cell: cell[1],
            },
        ],
        editorsList: (cell) => [
            {
                Header: 'Username',
                accessor: 'userName',
                sort: true,
            },
            {
                Header: 'Last Edited',
                accessor: 'lastEdited',
                sort: true,
            },
            {
                Header: 'Action',
                accessor: 'action',
                sort: true,
            },
        ],
        editMembership: (cell) => [
            {
                Header: 'User Name',
                accessor: 'lastName',
                sort: false,
            },
        ],
        analytics: (cell) => [
            {
                Header: 'Date & Time',
                accessor: 'colCreatedAt',
                sort: true,
            },
            {
                Header: 'Name',
                accessor: 'tblRecords.colRecordFullName',
                sort: true,
                Cell: cell[4],
            },
            {
                Header: 'Owner',
                accessor: 'user.colFullName',
                sort: true,
                Cell: cell[3],
            },
            {
                Header: 'Score',
                accessor: 'score',
                sort: true,
                Cell: cell[0],
                customAccessor: 'colScore',
            },
            {
                Header: 'Activity',
                accessor: 'activity',
                sort: true,
                customAccessor: 'tblActivity.colActivityName',
            },
            {
                Header: 'Type',
                accessor: 'type',
                sort: true,
                customAccessor: 'tblType.colTypeName',
            },
            {
                Header: 'Duration',
                accessor: 'duration',
                sort: true,
                customAccessor: 'tblRecording.colRecordingDuration',
            },
            {
                Header: 'Recording',
                accessor: 'details',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[2],
            },
        ],
        DateRangeFilter: (...props) => <DateRangeFilter {...props} />,
        analyticsDashboardSiteData: (cell) => [
            {
                Header: 'SYSTEM TOTALS (ALL TIME)',
                accessor: 'systemTotal',
            },
            {
                Header: 'Value',
                accessor: 'value',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotal',
            },
        ],
        analyticsDashboardReportData: (cell) => [
            {
                Header: 'SYSTEM TOTALS (ALL TIME)',
                accessor: 'systemTotal',
            },
            {
                Header: 'Value',
                accessor: 'value',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotal',
            },
        ],
        analyticsDashboardSiteFilterData: (cell) => [
            {
                Header: 'FILTER RESULTS',
                accessor: 'filterResults',
            },
            {
                Header: 'Range',
                accessor: 'range',
                descriptor: true,
                descriptorMsg: 'Total results for the period of time selected in the data range filter only.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRange',
            },
            {
                Header: 'Running',
                accessor: 'running',
                descriptor: true,
                descriptorMsg:
                    'Running total results from the beginning of time through the end date selected in the date range filter.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRunning',
            },
            {
                Header: 'All Time Total',
                accessor: 'allTimeTotal',
                descriptor: true,
                descriptorMsg: 'Total results for all time based on user and brand parameters.',
            },
        ],
        analyticsDashboardReportFilterData: (cell) => [
            {
                Header: 'FILTER RESULTS',
                accessor: 'filterResults',
            },
            {
                Header: 'Range',
                accessor: 'range',
                descriptor: true,
                descriptorMsg: 'Total results for the period of time selected in the data range filter only.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRange',
            },
            {
                Header: 'Running',
                accessor: 'running',
                descriptor: true,
                descriptorMsg:
                    'Running total results from the beginning of time through the end date selected in the date range filter.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRunning',
            },
            {
                Header: 'All Time Total',
                accessor: 'allTimeTotal',
                descriptor: true,
                descriptorMsg: 'Total results for all time based on user and brand parameters.',
            },
        ],
        analyticsDashboardSiteOpensData: (cell) => [
            {
                Header: 'OPENS',
                accessor: 'opensResults',
            },
            {
                Header: 'Range',
                accessor: 'range',
                descriptor: true,
                descriptorMsg: 'Total results for the period of time selected in the data range filter only.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRange',
            },
            {
                Header: 'Running',
                accessor: 'running',
                descriptor: true,
                descriptorMsg:
                    'Running total results from the beginning of time through the end date selected in the date range filter.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRunning',
            },
            {
                Header: 'All Time Total',
                accessor: 'allTimeTotal',
                descriptor: true,
                descriptorMsg: 'Total results for all time based on user and brand parameters.',
            },
        ],
        analyticsDashboardReportOpensData: (cell) => [
            {
                Header: 'OPENS',
                accessor: 'opensResults',
            },
            {
                Header: 'Range',
                accessor: 'range',
                descriptor: true,
                descriptorMsg: 'Total results for the period of time selected in the data range filter only.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRange',
            },
            {
                Header: 'Running',
                accessor: 'running',
                descriptor: true,
                descriptorMsg:
                    'Running total results from the beginning of time through the end date selected in the date range filter.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRunning',
            },
            {
                Header: 'All Time Total',
                accessor: 'allTimeTotal',
                descriptor: true,
                descriptorMsg: 'Total results for all time based on user and brand parameters.',
            },
        ],
        analyticsDashboardSiteEngagementData: (cell) => [
            {
                Header: 'ENGAGEMENT',
                accessor: 'engagementResults',
            },
            {
                Header: 'Range',
                accessor: 'range',
                descriptor: true,
                descriptorMsg: 'Total results for the period of time selected in the data range filter only.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRange',
            },
            {
                Header: 'Running',
                accessor: 'running',
                descriptor: true,
                descriptorMsg:
                    'Running total results from the beginning of time through the end date selected in the date range filter.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRunning',
            },
            {
                Header: 'All Time Total',
                accessor: 'allTimeTotal',
                descriptor: true,
                descriptorMsg: 'Total results for all time based on user and brand parameters.',
            },
        ],
        analyticsDashboardReportEngagementData: (cell) => [
            {
                Header: 'ENGAGEMENT',
                accessor: 'engagementResults',
            },
            {
                Header: 'Range',
                accessor: 'range',
                descriptor: true,
                descriptorMsg: 'Total results for the period of time selected in the data range filter only.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRange',
            },
            {
                Header: 'Running',
                accessor: 'running',
                descriptor: true,
                descriptorMsg:
                    'Running total results from the beginning of time through the end date selected in the date range filter.',
            },
            {
                Header: '% of Total',
                accessor: 'percentageTotalRunning',
            },
            {
                Header: 'All Time Total',
                accessor: 'allTimeTotal',
                descriptor: true,
                descriptorMsg: 'Total results for all time based on user and brand parameters.',
            },
        ],
        exportArchiveData: (cell) => [
            {
                Header: 'Export',
                accessor: 'export',
                sort: true,
            },
            {
                Header: 'User',
                accessor: 'user.colFullName',
                sort: true,
            },
            {
                Header: 'Date',
                accessor: 'date',
                sort: true,
            },
            {
                Header: 'File Size',
                accessor: 'fileSize',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        manageDownloads: (cell) => [
            {
                Header: 'Fiscal Year',
                accessor: 'year',
                sort: true,
            },
            {
                Header: 'File Name',
                accessor: 'fileName',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: cell[0],
            },
        ],
        currentEditors: (cell) => [
            {
                Header: 'Username',
                accessor: 'userName',
                sort: true,
            },
            {
                Header: 'Last Edited',
                accessor: 'lastEdited',
                sort: true,
            },
            {
                Header: 'Action',
                accessor: 'action',
                sort: true,
            },
        ],
        manageApiKey: (cell) => [
            {
                Header: 'Access Key ID',
                accessor: 'accessKeyId',
                sort: false,
            },
            {
                Header: 'Created',
                accessor: 'created',
                sort: false,
            },
            {
                Header: 'Actions',
                accessor: 'action',
                Cell: cell[0],
            },
        ],
        createdAccessKey: (cell) => [
            {
                Header: 'Access Key ID',
                accessor: 'accessKeyId',
                sort: false,
            },
            {
                Header: 'Secret Key',
                accessor: 'secretKey',
                Cell: cell[0],
            },
            {
                Header: 'Actions',
                accessor: 'action',
                Cell: cell[1],
            },
        ],
    },
    Common: {
        LinkFunds: (cell) => [
            {
                Header: 'Fund Name',
                accessor: 'fundName',
                sort: true,
            },
            {
                Header: '',
                accessor: 'status',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Reference #',
                accessor: 'referenceNumber',
                sort: true,
            },
        ],
        multipleSitesLeft: (cell) => [
            {
                Header: 'Name',
                accessor: 'lastName',
                sort: true,
            },
        ],
        multipleSitesRight: (cell) => [
            {
                Header: 'Name',
                accessor: 'lastName',
                sort: true,
            },
            {
                Header: 'Brand',
                accessor: 'brand',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Slot 1',
                accessor: 'slot1',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Slot 2',
                accessor: 'slot2',
                sort: false,
                Cell: cell[2],
            },
            {
                Header: 'Slot 3',
                accessor: 'slot3',
                sort: false,
                Cell: cell[3],
            },
            {
                Header: 'Slot 4',
                accessor: 'slot4',
                sort: false,
                Cell: cell[4],
            },
            {
                Header: 'Slot 5',
                accessor: 'slot5',
                sort: false,
                Cell: cell[5],
            },
            {
                Header: 'Slot 6',
                accessor: 'slot6',
                sort: false,
                Cell: cell[6],
            },
            {
                Header: 'Slot 7',
                accessor: 'slot7',
                sort: false,
                Cell: cell[7],
            },
            {
                Header: 'Slot 8',
                accessor: 'slot8',
                sort: false,
                Cell: cell[8],
            },
            {
                Header: 'Slot 9',
                accessor: 'slot9',
                sort: false,
                Cell: cell[9],
            },
            {
                Header: 'Slot 10',
                accessor: 'slot10',
                sort: false,
                Cell: cell[10],
            },
            {
                Header: 'Slot 11',
                accessor: 'slot11',
                sort: false,
                Cell: cell[11],
            },
            {
                Header: 'Slot 12',
                accessor: 'slot12',
                sort: false,
                Cell: cell[12],
            },
        ],
        multipleReportsLeft: (cell) => [
            {
                Header: 'Name',
                accessor: 'lastName',
                sort: true,
            },
        ],
        multipleReportsRight: (cell) => [
            {
                Header: 'Name',
                accessor: 'lastName',
                sort: true,
            },
            {
                Header: 'Brand',
                accessor: 'brand',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'Slot 1',
                accessor: 'slot1',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'Slot 2',
                accessor: 'slot2',
                sort: false,
                Cell: cell[2],
            },
            {
                Header: 'Slot 3',
                accessor: 'slot3',
                sort: false,
                Cell: cell[3],
            },
            {
                Header: 'Slot 4',
                accessor: 'slot4',
                sort: false,
                Cell: cell[4],
            },
            {
                Header: 'Slot 5',
                accessor: 'slot5',
                sort: false,
                Cell: cell[5],
            },
            {
                Header: 'Slot 6',
                accessor: 'slot6',
                sort: false,
                Cell: cell[6],
            },
            {
                Header: 'Slot 7',
                accessor: 'slot7',
                sort: false,
                Cell: cell[7],
            },
            {
                Header: 'Slot 8',
                accessor: 'slot8',
                sort: false,
                Cell: cell[8],
            },
            {
                Header: 'Slot 9',
                accessor: 'slot9',
                sort: false,
                Cell: cell[9],
            },
            {
                Header: 'Slot 10',
                accessor: 'slot10',
                sort: false,
                Cell: cell[10],
            },
            {
                Header: 'Slot 11',
                accessor: 'slot11',
                sort: false,
                Cell: cell[11],
            },
            {
                Header: 'Slot 12',
                accessor: 'slot12',
                sort: false,
                Cell: cell[12],
            },
            {
                Header: 'Slot 13',
                accessor: 'slot13',
                sort: false,
                Cell: cell[13],
            },
            {
                Header: 'Slot 14',
                accessor: 'slot14',
                sort: false,
                Cell: cell[14],
            },
            {
                Header: 'Slot 15',
                accessor: 'slot15',
                sort: false,
                Cell: cell[15],
            },
            {
                Header: 'Slot 16',
                accessor: 'slot16',
                sort: false,
                Cell: cell[16],
            },
            {
                Header: 'Slot 17',
                accessor: 'slot17',
                sort: false,
                Cell: cell[17],
            },
            {
                Header: 'Slot 18',
                accessor: 'slot18',
                sort: false,
                Cell: cell[18],
            },
        ],
    },
    users: {
        users: (cell) => [
            {
                Header: 'Users',
                accessor: 'colFullName',
                sort: true,
            },
            {
                Header: 'Username/Email',
                accessor: 'email',
                sort: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
    },
    accounts: {
        accounts: (cell) => [
            {
                Header: 'Device Name',
                accessor: 'colDeviceName',
                sort: true,
            },
            {
                Header: 'Last Sign In',
                accessor: 'colDeviceSuccessLoginAt',
                sort: true,
            },
            {
                Header: 'Sign In Type',
                accessor: 'colLoginType',
                sort: true,
            },
            {
                Header: 'Unregister',
                accessor: 'unregister',
                sort: false,
                Cell: cell[0],
            },
        ],
    },
    contentPanel: {
        reportData: (cell) => [
            {
                Header: 'Fund Name',
                accessor: 'colFundName',
                sort: true,
                Cell: cell[3],
            },
            {
                Header: 'Reference Number',
                accessor: 'colFundReferenceNo',
                sort: true,
                Cell: cell[4],
            },
            {
                Header: 'Type',
                accessor: 'colFundTypeName',
                customAccessor: 'tblFundType.colFundTypeName',
                sort: true,
                Cell: cell[5],
            },
            {
                Header: 'Fiscal Year',
                accessor: 'reportYear',
                sort: false,
            },
            {
                Header: 'Details',
                accessor: 'details',
                sort: false,
                Cell: cell[0],
            },
            {
                Header: 'FY Performance',
                accessor: 'fyPerformance',
                sort: false,
                Cell: cell[1],
            },
            {
                Header: 'FY Impact',
                accessor: 'fyImpact',
                sort: false,
                Cell: cell[2],
            },
        ],
        GenericFilter: (props) => <GenericFilter {...props} />,
        linkedRecords: (cell) => [
            {
                Header: 'Name',
                accessor: 'Name',
            },
            {
                Header: 'Record Reference Number',
                accessor: 'recordReference',
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        duplicate: (cell) => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Record Reference #',
                accessor: 'recordReference',
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
        impactSort: (cell) => [
            {
                Header: 'Name',
                accessor: 'Name',
            },

            {
                Header: 'Actions',
                accessor: 'actions',
                sort: false,
                Cell: cell[0],
            },
        ],
    },
    sizePerPageList: (data) => [
        {
            text: 'All',
            value: data?.length,
        },
    ],
    table_light: 'table-light',
    pageSize: 50,
};

export { table };
