import React, { useEffect, useState, createContext, useContext } from 'react';
import { authProfile, authRefreshToken, authReset } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { getRoleType } from '../utility';
import { useIdleTimer } from 'react-idle-timer';
import loaderIcon from '../assets/images/loader@2x.gif';

const AuthContext = createContext();

export function AuthProvider(props) {
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);
    const [isOnline, setIsOnline] = useState(true);
    const [defaultLayout, setDefaultLayout] = useState(false);
    const { authUser, me, error, refreshToken, userLogout } = useSelector((state) => ({
        authUser: state?.auth?.user,
        refreshToken: state?.auth?.refreshToken,
        me: state?.auth?.me,
        userLogout: state?.auth?.userLogout,
        error: state?.auth?.error,
    }));
    const [loading, setLoading] = useState(authUser ? true : false);

    const handleConnectionChange = () => {
        setIsOnline(navigator.onLine);
    };

    useEffect(() => {
        if (authUser?.token) {
            handleGetUser();
        }
        const connection = getNetworkConnection();
        connection?.addEventListener('change', handleConnectionChange);
        return () => {
            connection?.removeEventListener('change', handleConnectionChange);
        };
    }, []);

    const handleGetUser = async () => {
        setLoading(true);
        await dispatch(authProfile());
    };

    function getNetworkConnection() {
        return navigator.connection || navigator.mozConnection || navigator.webkitConnection || null;
    }

    useEffect(() => {
        if (authUser || me) {
            sessionStorage.setItem('ROLE_NUM', me?.roleId || authUser.roleId);
            const roleId = getRoleType(me?.roleId); //me?.roleId
            sessionStorage.setItem('ROLE_ID', roleId);
            const roleName = getRoleType(me?.roleId === 3 ? 4 : me?.roleId, 'name');
            setUser({
                ...user,
                ...me,
                ...authUser,
                roleId,
                roleName,
            });
            handlePageLoading();
        }
        if (userLogout) {
            dispatch(authReset());
            setUser({
                user: null,
            });
            window.location.href = '/public/login';
        }
    }, [authUser, me, userLogout, error]);

    const handlePageLoading = () => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    // for Idle time
    const handleOnIdle = async (event) => {
        // console.log('user is idle', event);
        if (user) await dispatch(authRefreshToken());
        // console.log('last active', getLastActiveTime());
    };

    const handleOnActive = async (event) => {
        // console.log('user is active', event);
        if (user) await dispatch(authRefreshToken());
        // console.log('time remaining', getRemainingTime());
    };

    const handleOnAction = async (event) => {
        if (user) await dispatch(authRefreshToken());
        // console.log('user did something', getLastIdleTime());
    };

    const { getRemainingTime, getLastIdleTime } = useIdleTimer({
        timeout: 1000 * 60 * 10,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 100000,
    });

    if (loading) {
        return <img src={loaderIcon} className="spinner-border-loader"></img>;
    }

    return (
        <AuthContext.Provider
            value={{ user, isOnline, loading, error, refreshToken, defaultLayout, setDefaultLayout }}
            {...props}
        />
    );
}

export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
}
