// @flow
import React from 'react';
import { useForm } from 'react-hook-form';

// components
import { ResetPasscodeValidation } from '../../../components/form/validation';
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { masterPassCodeUpdate } from '@ovrture/react-redux';
import { useDispatch } from 'react-redux';
import { convertIntoBase64 } from '../../../utility';

const ResetPasscode = (props) => {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ resolver: ResetPasscodeValidation, mode: 'onBlur' });

    const values = watch();
    const onSubmit = () => {
        if (props?.data?.page === 'record-setting') {
            sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'Passcode updated.');
            values?.accountPassword &&
                dispatch(
                    masterPassCodeUpdate({ isActive: true, passcode: convertIntoBase64(values?.accountPassword) })
                );
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <p className="pb-0">Enter a new 5-digit alpha-numeric code for the site and report:</p>
            <FormInput
                type="text"
                id="accountPassword"
                label="Enter Passcode *"
                maxLength="5"
                errors={errors}
                register={register}
            />
            <div className="modal-footer">
                {props.active !== 0 && (
                    <ButtonElement
                        name="Previous"
                        variant="outline-primary"
                        className="btn-prev"
                        type="button"
                        onClick={() => props.setActive(props.active - 1)}
                    />
                )}
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() => props.hideModal()}
                />
                <ButtonElement
                    name="Reset"
                    variant="primary"
                    className="btn-next"
                    type="submit"
                    disabled={values?.accountPassword ? false : true}
                />
            </div>
        </form>
    );
};
export default ResetPasscode;
