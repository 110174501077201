// @flow
import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import moment from 'moment';
import { FormInput } from '../../../components/form/input';

const UpdateTimerModal = (props) => {
    const { modalDispatch } = useModalContext();
    const d = moment(props?.data?.data?.dateCreated);
    const t = moment(props?.data?.data?.timer);
    const [timer, setTimer] = useState();
    console.log(333, t, d);

    return (
        <>
            <p>When would you like this alert to expire?</p>
            <Row>
                <Col md={5} xs={4} className="align-self-center">
                    <span>This alert is set to expire in</span>
                </Col>
                <Col md={3} xs={4}>
                    <Form.Control
                        type="text"
                        id="setExpire"
                        value={t.diff(d, 'days') === 0 ? 'N/A' : t.diff(d, 'days')}
                        readOnly
                    />
                </Col>
                <Col md={2} xs={3} className="align-self-center px-0">
                    <span>days.</span>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={5} xs={4} className="align-self-center">
                    <span>Remove this alert in</span>
                </Col>
                <Col md={3} xs={4}>
                    <FormInput
                        type="number"
                        id="removeExpire"
                        value={timer}
                        onChange={(e) => setTimer(e.target.value)}
                        negative="false"
                        feedback="hide"
                        floatinglabel="false"
                    />
                </Col>
                <Col md={2} xs={3} className="align-self-center px-0">
                    <span>days.</span>
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Update"
                    variant="primary"
                    type="button"
                    disabled={!timer}
                    onClick={() => props?.data?.handleTimeUpdate(timer)}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default UpdateTimerModal;
