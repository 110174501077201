import { useNotificationContex } from '../contexts/NotificationContext';
import { notificationActions } from '../reducers/actions';

export const ShiftInitialState = {
    rightArry: [],
    leftArry: [],
    ele: {},
};
export const actionTypes = {
    SHIFTRIGHT_REDUNDENT_ITEM: 'SHIFTRIGHT_REDUNDENT_ITEM',
    SHIFTRIGHT: 'SHIFT_RIGHT',
    SHIFTLEFT: 'SHIFT_LEFT',
    CLEARALL: 'CLEARALL',
    SHIFTUPDOWN: 'SHIFT_UP_DOWN',
    UPDATE_LEFT_ARRAY: 'UPDATE_LEFT_ARRAY',
    AUTO_RESET: 'AUTO_RESET',
};
export const shiftRight = (data) => ({
    type: actionTypes.SHIFTRIGHT,
    payload: data,
});
export const Shift_Right_Redundent_Item = (data) => ({
    type: actionTypes.SHIFTRIGHT_REDUNDENT_ITEM,
    payload: data,
});

export const shiftLeft = (data) => ({
    type: actionTypes.SHIFTLEFT,
    payload: data,
});
export const clearAll = (dir) => ({
    type: actionTypes.CLEARALL,
    payload: { dir: dir },
});

export const updateLeftArray = (data) => ({
    type: actionTypes.UPDATE_LEFT_ARRAY,
    payload: data,
});

export const updateRightArray = (data) => ({
    type: actionTypes.UPDATE_RIGHT_ARRAY,
    payload: data,
});

export const autoReset = (data) => ({
    type: actionTypes.AUTO_RESET,
    payload: data,
});

export const upDownHandler = (dir, ele) => ({
    type: actionTypes.SHIFTUPDOWN,
    payload: { dir: dir, ele: ele },
});

export const TableShiftReducer = (state = ShiftInitialState, action) => {
    const { notificationDispatch } = useNotificationContex();

    switch (action.type) {
        case actionTypes.AUTO_RESET:
            state.rightArry.map((item) => {
                item.shiftSelected = null;
            });
            return { ...state, rightArry: state.rightArry };
        case actionTypes.UPDATE_LEFT_ARRAY:
            return { ...state, leftArry: [...action.payload] };
        case actionTypes.UPDATE_RIGHT_ARRAY:
            return { ...state, rightArry: [...action.payload] };
        case actionTypes.SHIFTRIGHT:
            let movedRightItem;
            if (action.payload.length > 0) {
                movedRightItem = state?.leftArry?.filter((letfArryItem) =>
                    action.payload?.find((shiftedItemId) =>
                        shiftedItemId?.orgId ? letfArryItem?.id === shiftedItemId?.orgId : []
                    )
                );

                movedRightItem.map((item, index) => {
                    let dublicateItem = state.rightArry.find((f) => f.id === item.id);
                    if (dublicateItem) movedRightItem.splice(index, 1);
                });

                action.payload = [];
                return {
                    ...state,
                    rightArry: [...state.rightArry, ...movedRightItem],
                    leftCrntItem: null,
                };
            }

        case actionTypes.SHIFTRIGHT_REDUNDENT_ITEM:
            let uniqueArray = [];
            if (action.payload.length > 0) {
                state?.rightArry?.map((r) => {
                    uniqueArray.push(r);
                });
                action?.payload?.map((p) => {
                    let leftObj = state?.leftArry?.find((f) => f.id === p.id);
                    let rightObj = state?.rightArry?.find((f) => f.id === p.id);
                    if (leftObj && !rightObj) uniqueArray.push(leftObj);
                });
                action.payload = [];
                if (uniqueArray.length > 70) {
                    return { ...state };
                }
                return { ...state, rightArry: [...uniqueArray], leftCrntItem: null };
            }

        case actionTypes.SHIFTLEFT:
            let moveLeftItem;
            if (action.payload.length > 0) {
                moveLeftItem = state.rightArry.filter(
                    (rightArryItem) =>
                        !action?.payload?.find((shiftedItemId) =>
                            rightArryItem?.id ? shiftedItemId?.orgId === rightArryItem?.id : []
                        )
                );
                action.payload = [];
                return { ...state, rightArry: [...moveLeftItem] };
            }
        case actionTypes.CLEARALL:
            if (action.payload.dir === 'Right') {
                return { ...state, rightArry: [] };
            } else if (action.payload.dir === 'Left') {
                let updatedRightArry = [];
                if (state.rightArry.length > 0) {
                    updatedRightArry = state.rightArry;
                    state.leftArry.forEach((element) => {
                        if (!updatedRightArry.find((f) => f.id === element.id)) updatedRightArry.push(element);
                    });
                } else {
                    updatedRightArry = state.leftArry;
                }
                return { ...state, rightArry: updatedRightArry };
            }
        case actionTypes.SHIFTUPDOWN:
            if (action?.payload?.ele?.length < 2 && action?.payload?.ele.length > 0) {
                state.rightArry.map((item) => {
                    item.shiftSelected = false;
                    if (item?.id === action?.payload?.ele[0]?.id) {
                        item.shiftSelected = true;
                    }
                });
                const crntPos = state?.rightArry?.findIndex((obj) =>
                    action?.payload?.ele[0]?.orgId ? obj?.id === action?.payload?.ele[0]?.orgId : -1
                );

                if (action?.payload?.dir === 'up' && crntPos !== 0) {
                    [state.rightArry[crntPos], state.rightArry[crntPos - 1]] = [
                        state.rightArry[crntPos - 1],
                        state.rightArry[crntPos],
                    ];
                } else if (action?.payload?.dir === 'down' && state?.rightArry?.length - 1 !== crntPos) {
                    [state.rightArry[crntPos], state.rightArry[crntPos + 1]] = [
                        state.rightArry[crntPos + 1],
                        state.rightArry[crntPos],
                    ];
                }
                return { ...state, ele: [] };
            }
            if (action?.payload?.ele?.length < 1) {
                notificationDispatch({
                    type: notificationActions.OPEN_NOTIFICATION,
                    payload: {
                        role: 'danger',
                        title: 'error',
                        iconClass: 'dripicons-warning h1',
                        className: 'icon',
                        message: 'Please select atleast one checkobox',
                    },
                });

                return { ...state };
            } else if (action?.payload?.ele?.length > 1) {
                notificationDispatch({
                    type: notificationActions.OPEN_NOTIFICATION,
                    payload: {
                        role: 'danger',
                        title: 'error',
                        iconClass: 'dripicons-warning h1',
                        className: 'icon',
                        message: 'Please select one checkobox at a time for move',
                    },
                });
                return { ...state };
            }

        default:
            return {
                ...state,
            };
    }
};
