// @flow
import React from 'react';
import { Card } from 'react-bootstrap';

// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
const ApprovalNotification = (props) => {
    const { modalDispatch } = useModalContext();
    return (
        <>
            <p>
                Would you like to send an email notification to the following user that this{' '}
                <span>{props?.data?.name === 'Site' ? 'site' : 'report'}</span> has been approved?
            </p>
            <p className="text-center">
                <u>{props?.data?.row?.original?.tblRecords?.colRecordOwnerEmailId}</u>
            </p>
            <div className=" d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Send"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        props.data.onAprrove(props.data.name, props.data.row, true);
                    }}
                />

                <ButtonElement
                    name="No"
                    variant="light"
                    type="button"
                    onClick={() => props.data.onAprrove(props.data.name, props.data.row, false)}
                />
            </div>
        </>
    );
};

export default ApprovalNotification;
