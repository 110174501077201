import React from 'react';
import { ListGroup } from 'react-bootstrap';

function ListItem({ children, ...props }) {
    return (
        <ListGroup.Item action {...props}>
            {children}
        </ListGroup.Item>
    );
}

export default ListItem;
