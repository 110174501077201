import config from '../config';
import {
    CommonRoute,
    PublicRoute,
    PlatformAdminRoutes,
    SystemAdminRoutes,
    ApplicationAdminRoutes,
    ReportAdminRoutes,
    ContentAdminRoutes,
    BrandAdminRoutes,
    DataAdminRoutes,
    UserRoutes,
} from './RoutesGroup';
// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];
    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};
const localStorageInfo = JSON.parse(localStorage.getItem(process.env.REACT_APP_DASHBOARD_SESSION_AUTH_KEY));
// All routes
let PlatformAdminRoute =
    config.ROLE_NUM === '0' || localStorageInfo?.roleId === 0 ? flattenRoutes([...PlatformAdminRoutes]) : [];
let SystemAdminRoute =
    config.ROLE_NUM === '1' || localStorageInfo?.roleId === 1 ? flattenRoutes([...SystemAdminRoutes]) : [];
let ReportAdminRoute =
    config.ROLE_NUM === '5' || localStorageInfo?.roleId === 5 ? flattenRoutes([...ReportAdminRoutes]) : [];
let ContentAdminRoute =
    config.ROLE_NUM === '6' || localStorageInfo?.roleId === 6 ? flattenRoutes([...ContentAdminRoutes]) : [];
let BrandAdminRoute =
    config.ROLE_NUM === '2' || localStorageInfo?.roleId === 2 ? flattenRoutes([...BrandAdminRoutes]) : [];
let UserRoute =
    config.ROLE_NUM === '4' || localStorageInfo?.roleId === 4 || config.ROLE_NUM === '3'
        ? flattenRoutes([...UserRoutes])
        : [];
let ApplicationAdminRoute =
    config.ROLE_NUM === '10' || localStorageInfo?.roleId === 10 ? flattenRoutes([...ApplicationAdminRoutes]) : [];
let DataAdminRoute =
    config.ROLE_NUM === '7' || localStorageInfo?.roleId === 7 ? flattenRoutes([...DataAdminRoutes]) : [];
const authProtectedRoutes = [
    ...PlatformAdminRoutes,
    ...SystemAdminRoute,
    ...ApplicationAdminRoute,
    ...ReportAdminRoute,
    ...ContentAdminRoute,
    ...BrandAdminRoute,
    ...DataAdminRoute,
    ...UserRoute,
];
const publicRoutes = [...PublicRoute];
const commonRoutes = CommonRoute?.filter((route) => {
    return route.path !== '*';
});
const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes, ...commonRoutes]);

export {
    CommonRoute,
    publicRoutes,
    authProtectedFlattenRoutes,
    publicProtectedFlattenRoutes,
    PlatformAdminRoute,
    SystemAdminRoute,
    ApplicationAdminRoute,
    ReportAdminRoute,
    ContentAdminRoute,
    BrandAdminRoute,
    DataAdminRoute,
    UserRoute,
};
