import React from 'react';
import { Route } from 'react-router-dom';
const PageNotFound = React.lazy(() => import('../pages/public/PageNotFound'));
const Error = React.lazy(() => import('../pages/public/Error'));
const UnderConstruction = React.lazy(() => import('../pages/public/UnderConstruction'));

export const CommonRoute = [
    {
        path: '/error',
        name: 'error',
        title: 'error',
        exact: true,
        component: Error,
        route: Route,
        pathExact: '/error',
    },
    {
        path: '/under-construction',
        name: 'underconstruction',
        title: 'underconstruction',
        exact: true,
        component: UnderConstruction,
        route: Route,
        pathExact: '/under-construction',
    },
    {
        path: '*',
        name: 'page not found',
        title: '404',
        exact: true,
        component: PageNotFound,
        route: Route,
        pathExact: '*',
    },
];
