// @flow
import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { activeBroadcastReset } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { FormInput } from '../../../components/form/input';

const BroadcastSetTimerModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [timer, setTimer] = useState('');
    const dispatch = useDispatch();

    const { create } = useSelector((state) => ({
        create: state?.activeBroadcast?.create,
    }));

    useEffect(() => {
        if (create) {
            if (create?.code === 400) {
                modalDispatch({
                    type: modalActions.CLOSE_MODAL,
                });
                props?.data?.redirectToLandingPage();
            } else {
                dispatch(activeBroadcastReset());
                props.setActive(props.active + 1);
            }
        }
    }, [create]);

    return (
        <>
            <p>Got it. Would you like to determine when the alert should be removed?</p>
            <p className="pt-0 pb-3">When would you like this alert to expire?</p>
            <Row className="pt-0">
                <Col xs={5} className="d-flex align-items-center col-md-auto">
                    <span>Remove this alert in</span>
                </Col>
                <Col md={3} xs={4}>
                    <FormInput
                        type="number"
                        id="removeExpire"
                        value={timer}
                        onChange={(e) => setTimer(e.target.value)}
                        negative="false"
                        feedback="hide"
                        floatinglabel="false"
                    />
                    {/* <Form.Control
                        value={timer}
                        onChange={(e) => setTimer(e.target.value)}
                        type="number"
                        id="removeExpire"
                    /> */}
                </Col>
                <Col md={2} xs={3} className="d-flex justify-content-start align-items-center px-0">
                    <span>days.</span>
                </Col>
            </Row>
            <div className="modal-footer">
                {props.active !== 0 && (
                    <ButtonElement
                        name="Previous"
                        variant="outline-primary"
                        className="btn-prev"
                        type="button"
                        onClick={() => props.setActive(props.active - 1)}
                    />
                )}
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
                <ButtonElement
                    // name="No; I Will Remove When Appropriate."
                    name="No"
                    variant="outline-primary"
                    className="btn-next"
                    type="button"
                    onClick={() => {
                        props?.data?.handleCreateActiveBroadcast('');
                    }}
                />
                <ButtonElement
                    // name="Yes; Set A Timer To Remove This Alert."
                    name="Yes"
                    variant="primary"
                    className="btn-next"
                    type="button"
                    onClick={() => {
                        props?.data?.handleCreateActiveBroadcast(timer);
                    }}
                    disabled={!timer}
                />
            </div>
        </>
    );
};
export default BroadcastSetTimerModal;
