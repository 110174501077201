// @flow
import React from 'react';
import Routes from './routes/Routes';
import { NotificationProvider } from './contexts/NotificationContext';
import { AuthProvider } from './contexts/AuthContext';
// Themes

// For Saas import Saas.scss
import './assets/scss/Saas.scss';
import ModalProvider from './contexts/ModalContext';
import { TreeViewProvider } from './contexts/TreeViewContext';
import HandleCache from './helpers/handleCache/HandleCache';
import EnterKeyFormSubmit from './helpers/autoSubmit/enterKeyFormSubmit';

/**
 * Main app component
 */
const App = (props) => {
    return (
        <EnterKeyFormSubmit>
            <HandleCache>
                <NotificationProvider>
                    <AuthProvider>
                        <ModalProvider>
                            <TreeViewProvider>
                                <Routes></Routes>
                            </TreeViewProvider>
                        </ModalProvider>
                    </AuthProvider>
                </NotificationProvider>
            </HandleCache>
        </EnterKeyFormSubmit>
    );
};

export default App;
