// @flow
import React, { useState, useEffect } from 'react';
// components
import { Row, Col, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useSelector, useDispatch } from 'react-redux';
import { cpUpdateFolder } from '@ovrture/react-redux';
import { renameContentPanel } from '../../../components/form/validation';

const RenameContentPanelModal = (props) => {
    const { modalDispatch } = useModalContext();
    const {
        handleSubmit,
        register,
        setValue,
        watch,
        formState: { errors },
    } = useForm({ resolver: renameContentPanel, mode: 'onBlur' });
    const [toggle, setToggle] = useState(false);

    const dispatch = useDispatch();
    const { authInfo } = useSelector((state) => ({
        authInfo: state?.auth?.me,
    }));
    const onSubmit = (data) => {
        setToggle(false);
        const formData = {
            id: props?.data?.data?.id,
            fkPaletteId: props?.data?.data?.fkPaletteId,
            fkRoleId: props?.data?.data?.fkRoleId || authInfo?.role?.id,
            name: data.renameContentPanel,
            parentId: props?.data?.data?.parent_id,
            permissionbyId: props?.data?.data?.permissionbyId,
            userId: props?.data?.data?.userId || authInfo?.id,
            brandId: props?.data?.data?.brandId,
            canBeDeleted: props?.data?.data?.canBeDeleted,

            cpContentId: props?.data?.data?.cpContentId,
            parentId: props?.data?.data?.parent_id,
            icon: props?.data?.data?.icon,
            fkCpRootNodeId: props?.data?.data?.fkCpRootNodeId || props?.data?.data?.id,
        };
        dispatch(cpUpdateFolder(formData));
        sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'Content Panel renamed.');
    };
    const textChangeHandle = (e) => {
        if (values?.renameContentPanel === e.target.value) {
            setToggle(false);
        } else {
            setValue('renameContentPanel', e.target.value);
            setToggle(true);
        }
    };
    const values = watch();
    useEffect(() => {
        if (props.data.data) {
            setValue('renameContentPanel', props.data.data.text);
        }
    }, [props.data.data]);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col lg={12}>
                    <FormInput
                        type="text"
                        id="renameContentPanel"
                        label="Content Panel Name *"
                        maxLength="30"
                        errors={errors}
                        register={register}
                        onChange={(e) => textChangeHandle(e)}
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Rename" variant="primary" type="submit" disabled={!toggle ? true : false} />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </form>
    );
};
export default RenameContentPanelModal;
