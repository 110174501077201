// @flow
import React from 'react';
import { ProgressBar } from 'react-bootstrap';

const ProgressBarStatusModal = (props) => {
    return (
        <>
            <ProgressBar now={45} variant="primary" animated></ProgressBar>
            <p className="text-center mt-1 mb-0">
                <span>0</span> | Estimated time remaining: <span>00:00</span>
            </p>
        </>
    );
};

export default ProgressBarStatusModal;
