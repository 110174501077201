// @flow
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
// component
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { assePreview, assetReset } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';

const AssetPanelPreviewModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [assetUrl, setAssetUrl] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(assePreview({ assetFolderViewId: props?.data?.data?.id }));
    }, []);

    const { asset } = useSelector((state) => ({
        asset: state?.asset?.preview,
    }));

    useEffect(() => {
        if (asset?.data) {
            setAssetUrl(asset?.data);
            dispatch(assetReset());
        }
    }, [asset]);

    return (
        <>
            <Card>
                <Card.Body>
                    <div className="preview-box">
                        {assetUrl && (
                            <>
                                {props?.data?.data?.icon === 'glyphicon glyphicon-video' ? (
                                    <iframe
                                        className={assetUrl ? 'video-block' : ''}
                                        src={assetUrl}
                                        alt="preview"
                                        style={{
                                            display: assetUrl ? 'block' : 'none',
                                        }}></iframe>
                                ) : (
                                    <img src={assetUrl} alt="preview image" />
                                )}
                            </>
                        )}
                    </div>
                </Card.Body>
            </Card>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement name="Back" variant="outline-primary" type="button" onClick={() => props.hideModal()} />
            </div>
        </>
    );
};

export default AssetPanelPreviewModal;
