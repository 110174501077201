import PrivateRoute from '../PrivateRoute';
import { COMMON_PATH, SYSTEM_ADMIN_PATH } from '../role/path';
import {
    EditRecordAndSettings,
    NewRecord,
    NewSite,
    RestoreDeletedRecords,
    UpdateRecords,
    UserPortfolio,
} from './generalRoutes';

export const portfolioModule = {
    path: COMMON_PATH.RECORD_PORTFOLIO,
    name: 'RECORD PORTFOLIO',
    title: 'Portfolio',
    exact: true,
    component: UserPortfolio,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Portfolio', path: '/', active: true }],
    children: [
        {
            path: COMMON_PATH.CREATE_SITE,
            name: 'CREATE SITE',
            title: 'Create Site',
            exact: true,
            component: NewSite,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Portfolio', path: COMMON_PATH.RECORD_PORTFOLIO, active: false },
                { label: 'Create Site', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.CREATE_NEW_RECORD,
            name: 'CREATE NEW RECORD(S)',
            title: 'Create New Record(s)',
            exact: true,
            component: NewRecord,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Portfolio', path: COMMON_PATH.RECORD_PORTFOLIO, active: false },
                { label: 'Create New Record(s)', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.RECORD_DATA_AND_SETTINGS + '/:id',
            name: 'RECORD DATA & SETTINGS',
            title: 'Edit Record & Settings',
            exact: true,
            component: EditRecordAndSettings,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Portfolio ', path: COMMON_PATH.RECORD_PORTFOLIO, active: false },
                { label: 'Edit Record & Settings', path: COMMON_PATH.RECORD_DATA_AND_SETTINGS, active: true },
            ],
        },
        {
            path: COMMON_PATH.UPDATE_RECORDS,
            name: 'UPDATE RECORD(S)',
            title: 'Update Record(s)',
            exact: true,
            component: UpdateRecords,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Portfolio', path: COMMON_PATH.RECORD_PORTFOLIO, active: false },
                { label: 'Update Record(s)', path: '/', active: true },
            ],
        },
        {
            path: SYSTEM_ADMIN_PATH.DELETE_RESTORE_RECORDS,
            name: 'DELETED RECORD(S)',
            title: 'Deleted Record(s)',
            exact: true,
            component: RestoreDeletedRecords,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Portfolio', path: COMMON_PATH.RECORD_PORTFOLIO, active: false },
                { label: 'Deleted Record(s)', path: '/', active: true },
            ],
        },
    ],
};
