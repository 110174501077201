//Platform Admin Modal Content
import ResetPasswordPlatform from '../pages/platform/account/ResetPasswordModal';
import ResetPasswordCommon from '../pages/commonpages/commonModals/ResetPasswordModal';
import PrompDialougeModal from '../pages/commonpages/commonModals/PrompDialougeModal';
import FundDescriptionModal from '../pages/platform/editElements/reportData/FundDescriptionModal';
import AddressDefaultModal from '../pages/platform/editElements/core/AddressDefaultModal';
import VimeoAccountModal from '../pages/platform/editElements/core/VimeoAccountModal';
import BroadcastModal from '../pages/platform/editElements/core/BroadcastModal';
import BrandName from '../pages/platform/editElements/brands/BrandName';
import EditBrandDetails from '../pages/platform/editElements/brands/EditBrandDetails';
import EditContentPanel from '../pages/platform/editElements/contentPanels/EditContentPanel';
import FundDonor from '../pages/platform/editElements/reportData/FundDonorModal';
import FundImpactModal from '../pages/platform/editElements/reportData/FundImpactModal';
import FundPerformance from '../pages/platform/editElements/reportData/FundPerformanceModal';
import FundTypeModal from '../pages/platform/editElements/reportData/FundTypeModal';
import NewFundModal from '../pages/platform/editElements/reportData/NewFundModal';
import ImpactTypeModal from '../pages/platform/editElements/reportData/ImpactTypeModal';
import NewImpactModal from '../pages/platform/editElements/reportData/NewImpactModal';
import ReportPanelMapModal from '../pages/platform/editElements/reportPanels/ReportPanelMapModal';
import ReportPanelHtmlModal from '../pages/platform/editElements/reportPanels/ReportPanelHtmlModal';
import SitePrintBridgeModal from '../pages/platform/editElements/contentPanels/SitePrintBridgeModal';
import ReportprintBridgeModal from '../pages/platform/editElements/reportPrintBridge/ReportprintBridgeModal';
import EnableDisableMultiStepForm from '../pages/platform/dashboard/EnableDisableMultiStepForm';
import ClearDeleteMultiStepForm from '../pages/platform/dashboard/ClearDeleteMultiStepForm';
import AccountLockedModal from '../pages/public/AccountLockedModal';
//System Admin Modal Content
import CopyUrl from '../pages/commonpages/records/CopyUrlModal';
import Snapshots from '../pages/commonpages/analytics/SnapshotsModal';
import EditorsList from '../pages/commonpages/records/EditorsListModal';
import UserSettingResetPassword from '../pages/system/users/UserSettingResetPasswordModal';
import EditMembership from '../pages/system/users/EditMembershipModal';
import AddMember from '../pages/system/users/AddMemberModal';
import NewGroup from '../pages/system/users/NewGroupModal';
import RenameGroup from '../pages/system/users/RenameGroupModal';
import ExportUsersDataModal from '../pages/commonpages/exports/ExportUsersDataModal';
import ExportRecords from '../pages/commonpages/exports/ExportRecordsModal';
import ExportUserActivity from '../pages/commonpages/exports/ExportUserActivityModal';
import ExportFundDetailsModal from '../pages/commonpages/exports/ExportFundDetailsModal';
import ExportFyPerformance from '../pages/commonpages/exports/ExportFyPerformanceModal';
import ExportFyDonors from '../pages/commonpages/exports/ExportFyDonorsModal';
import ExportFyImpacts from '../pages/commonpages/exports/ExportFyImpactsModal';
import ExportFyDistributionList from '../pages/commonpages/exports/ExportFyDistributionListModal';
import ExportFyReportsPrintBridge from '../pages/commonpages/exports/ExportFyReportsPrintBridgeModal';
import ExportRecordBasedDataAndSnapshots from '../pages/commonpages/exports/ExportRecordBasedDataAndSnapshotsModal';
import ExportSystemWideDashboardData from '../pages/commonpages/exports/ExportSystemWideDashboardDataModal';
import ExportArchiveData from '../pages/commonpages/exports/ExportArchiveDataModal';
import LinkedRecords from '../pages/commonpages/records/LinkedRecordsModal';
import AddDonor from '../pages/commonpages/contentPanels/reportData/AddDonorModal';
import SessionRecording from '../pages/commonpages/analytics/SessionRecordingModal';
import ManageDownloads from '../pages/commonpages/records/ManageDownloadsModal';
import CurrentEditors from '../pages/commonpages/records/CurrentEditorsModal';
import CreateAccessKey from '../pages/system/settings/CreateAccessKeyModal';
import DeleteAccessKey from '../pages/system/settings/DeleteAccessKeyModal';
import FilterByBrand from '../pages/system/contentPanels/FilterByBrand';
import UpdateTimer from '../pages/system/contentPanels/UpdateTimerModal';
import SetBroadcastTimer from '../pages/system/contentPanels/SetBroadcastTimerModal';
import TransferProceed from '../pages/system/users/TransferProceedModal';
import DeleteMultiStepForm from '../pages/system/users/DeleteMultiStepForm';

//Commonform
import LinkFunds from '../pages/commonpages/records/LinkFundsModal';
import BtdGreetingMessage from '../pages/commonpages/contentPanels/builderDefaults/BtdGreetingMessageModal';
import BtdGreetingImage from '../pages/commonpages/contentPanels/builderDefaults/BtdGreetingImageModal';
import PreflightData from '../pages/commonpages/commonModals/PreflightDataModal';
import ImportData from '../pages/commonpages/contentPanels/reportData/ImportDataModal';
import SelectBrand from '../components/modal/form/SelectBrand';
import BuildReport from '../pages/commonpages/reports/BuildReportModal';
import ProgressBarStatus from '../pages/commonpages/commonModals//ProgressBarStatusModal';
import NewFolder from '../pages/commonpages/commonModals//NewFolderModal';
import RenameFolder from '../pages/commonpages/commonModals/RenameFolderModal';
import DeleteFolder from '../pages/commonpages/commonModals/DeleteFolderModal';
import RenameContentPanel from '../pages/commonpages/contentPanels/RenameContentPanelModal';
import ContentPanelPreview from '../pages/commonpages/contentPanels/ContentPanelPreviewModal';
import AssetPanelPreview from '../pages/commonpages/assets/AssetPanelPreviewModal';
import RenameAsset from '../pages/commonpages/assets/RenameAssetModal';
import SelectContentPanelModal from '../pages/commonpages/contentPanels/builderDefaults/SelectContentPanelModal';
import SelectNewLocationModal from '../pages/commonpages/contentPanels/builderDefaults/SelectNewLocationModal ';
import ApprovalNotificationModal from '../pages/system/users/ApprovalNotificationModal';
import ReplaceAssetModal from '../pages/commonpages/records/ReplaceAssetModal';
import AddNewFyData from '../pages/commonpages/contentPanels/reportData/AddNewFyDataModal';
import DeleteActions from '../pages/system/users/DeleteActions';
import BulkSuspendUnSuspend from '../pages/system/users/BulkSuspendUnSuspend';
import RestoreActions from '../pages/system/users/RestoreActions';
import RestoreOriginalOwner from '../pages/system/users/RestoreOriginalOwner';
import PrivacySecurity from '../pages/public/PrivacySecurity';
import DeleteGroup from '../pages/system/users/DeleteGroup';
import CreateEditConfirmation from '../pages/system/users/CreateEditConfirmation';
import ImageCropper from '../components/cropper/ImageCropper';
import BrandAdminSetting from '../pages/system/settings/BrandAdminSettingModal';
import MultiStepResetPassword from '../pages/commonpages/portfolio/MultiStepResetPassword';
import ManageDownloadReplaceAssetModal from '../pages/commonpages/portfolio/ManageDownloadReplaceAssetModal';
import AssetUploadModal from '../pages/commonpages/assets/AssetUploadModal';
import ChangeLocationModal from '../pages/commonpages/assets/ChangeLocationModal';
import SelectTemplateWithPreviewModal from '../pages/commonpages/contentPanels/selectTemplateWithPreviewModal';
import CreateFundReplaceAssetModal from '../pages/commonpages/reportData/CreateFundReplaceAssetModal';
import PortfolioResetPasscode from '../pages/commonpages/portfolio/PortfolioResetPasscode';
import ChangeLocationConfirmationModal from '../pages/commonpages/contentPanels/ChangeLocationConfirmationModal';
import FundPreviewModal from '../pages/commonpages/contentPanels/reportData/FundPreviewModal';
import ImpactSortModal from '../pages/commonpages/reportData/ImpactSortModal';
import DuplicateModal from '../pages/commonpages/records/DuplicateModal';
import PublishEditedContentPanelMultiStepForm from '../pages/commonpages/contentPanels/PublishEditedContentPanelMultiStepForm';
import BroadcastProceedMultiStepForm from '../pages/system/contentPanels/BroadcastPublishMultiStepForm';
import BroadcastPublishMultiStepForm from '../pages/system/contentPanels/BroadcastPublishMultiStepForm';
import ReplaceAssetEditorModal from '../helpers/editor/modal/ReplaceAssetEditorModal';
import SaveDraftModal from '../pages/commonpages/contentPanels/SaveDraftModal';
//editors related modals
import AddOrChangeSlide from '../helpers/editor/modal/AddOrChangeSlide';
import PrintBridgeHeading from '../helpers/editor/modal/PrintBridgeHeading';
import PrintPreview from '../helpers/editor/modal/PrintPreview';
import ChangeCoverImage from '../helpers/editor/modal/ChangeCoverImage';
import AddContentPanelModal from '../helpers/editor/modal/AddContentPanelModal';
import PrintReportElements from '../helpers/editor/modal/PrintReportElements';
import CloudDetailsUpdateMultiStepForm from '../pages/platform/platformDetails/CloudDetailsUpdateMultiStepForm';
import ReplaceScriptCodeModal from '../helpers/editor/modal/ReplaceScriptCodeModal';
import EmbedVideoModal from '../helpers/editor/modal/EmbedVideoModal';
import ApproveMultiStepModalForm from '../pages/commonpages/reviewAndApproval/ApproveMultiStepModalForm';

const modal = {
    platformAdmin: {
        privacySecurity: (props) => [
            {
                modalTitle: 'How We Protect Your Security',
                modalContent: <PrivacySecurity hideModal={props.modalAction} />,
                modalSize: 'small Privacy-Security',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        accountLocked: (props) => [
            {
                modalTitle: 'Account Locked',
                modalSize: 'small',
                modalContent: <AccountLockedModal data={props.data} />,
                modalMessageWarning: 'Note: ',
                modalMessageText:
                    'Once this time has elapsed, and if necessary, click "Forgot Password?" to reset your password.',
            },
        ],
        status: (props) => [
            {
                modalTitle: 'Disable',
                modalContent:
                    'Are you sure you want to proceed with disabling this system? All admin and user accounts will lose access to platform.',
                modalButton: 'Proceed',
                crossIcon: true,
            },
        ],
        enableDisableMultiStepModal: (props) => [
            {
                modalTitle: props?.data?.orgName ? props.data.status + ' > ' + props.data.orgName : props.data.status,
                modalContent: <EnableDisableMultiStepForm hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    props?.data?.status === 'Disable'
                        ? 'Disabling this system will prevent all admin and users accounts from accessing the platform.'
                        : 'Enabling this system will provide all admin and users accounts access to the platform.',

                modalSize: 'lg',
            },
        ],
        cloudUpdateMultiStepModal: (props) => [
            {
                modalTitle: 'Cloud Details',
                modalContent: <CloudDetailsUpdateMultiStepForm hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalSize: 'lg',
            },
        ],
        clearDeleteMultiStepModal: (props) => [
            {
                modalTitle: props.data.title,
                modalContent: <ClearDeleteMultiStepForm hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalSize: 'lg',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    props.data.item === 'clear'
                        ? 'You are about to clear all system data permanently; this can not be undone.'
                        : 'You are about to delete all related users, digital assets, content, and data.',
            },
        ],
        login: (props) => [
            {
                modalTitle: 'Admin Sign In',
                modalContent:
                    'You are about to sign in as a System Admin for a tenant system. To return to the Platform Admin account, you will have to sign out and sign back in. Proceed?',
                modalButton: 'Proceed',
            },
        ],
        restore: (props) => [
            {
                modalTitle: 'Restore/Purge System',
                modalContent: 'The following system will restore all the data.',
                modalButton: 'Proceed',
            },
        ],
        restoreDelete: (props) => [
            {
                modalTitle: 'Delete System',
                modalContent:
                    'The following system were deleted by the platform admin. This function allows you to restore these systems or permanently delete them from the database.',
                modalButton: 'Process',
            },
        ],
        communicationDelete: (props) => [
            {
                modalTitle: 'Delete Email Template',
                modalContent: 'Are you sure you want to delete this email template permanently?',
                modalButton: 'Proceed',
            },
        ],
        deleteConfirmation: (props) => [
            {
                modalTitle:
                    props.data.item === 'clear' ? 'Clear > ' + props.data.orgName : 'Delete > ' + props.data.orgName,
                modalContent:
                    props.data.item === 'clear'
                        ? 'Are you sure you want to clear ' +
                          props.data.orgName +
                          '? Doing so will discard all related users, groups, content, and data.'
                        : 'Are you sure you want to delete ' +
                          props.data.orgName +
                          ' permanently? Doing so will discard all related users, groups, content, and data.',
                modalButton: props.data.item === 'clear' ? 'Clear' : 'Delete',
                modalCancelButton: 'Cancel',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'If ' +
                    (props.data.item === 'clear' ? 'clear' : 'delete') +
                    ' the platform administrator can either restore the system or can permanently purge this system from the ovrture platform.',
                modalSize: 'small custom-warning',
            },
        ],

        suspendedUser: (props) => [
            {
                modalTitle: 'Suspend User',
                modalContent: 'Are you sure you want to suspend this user?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
            },
        ],
        unSuspendedUser: (props) => [
            {
                modalTitle: 'Unsuspend User',
                modalContent: 'Are you sure you want to unsuspend this user?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
            },
        ],
        deleteUser: (props) => [
            {
                modalTitle: 'Delete User',
                modalContent: 'Are you sure you want to delete this user permanently?',
                modalButton: 'Proceed',
            },
        ],
        deleteDevice: (props) => [
            {
                modalTitle: 'Delete Device',
                modalContent: 'Are you sure you want to delete this device permanently?',
                modalButton: 'Delete',
            },
        ],
        resetPasswordPlatform: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <ResetPasswordPlatform hideModal={props?.modalAction} data={props?.data} />,
            },
        ],
        fundDescription: (props) => [
            {
                modalTitle: props?.data?.title + ' > Report Data > Fund Description',
                modalContent: <FundDescriptionModal hideModal={props?.modalAction} />,
                modalSize: 'lg',
            },
        ],
        fundDonor: (props) => [
            {
                modalTitle: props?.data?.title + ' > Report Data > Fund Donors',
                modalContent: <FundDonor hideModal={props?.modalAction} />,
                modalSize: 'lg',
            },
        ],
        fundImpact: (props) => [
            {
                modalTitle: props?.data?.title + ' > Report Data > Fund Impact',
                modalContent: <FundImpactModal hideModal={props?.modalAction} />,
                modalSize: 'lg',
            },
        ],
        fundPerformance: (props) => [
            {
                modalTitle: props?.data?.title + ' > Report Data > Fund Performance',
                modalContent: <FundPerformance hideModal={props?.modalAction} />,
                modalSize: 'lg',
            },
        ],
        fundType: (props) => [
            {
                modalTitle: props?.data?.title + ' > Report Data > Fund Type',
                modalContent: <FundTypeModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
            },
        ],
        newFundType: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <NewFundModal hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'lg',
            },
        ],
        impactType: (props) => [
            {
                modalTitle: props?.data?.title + ' > Report Data > Impact Type',
                modalContent: <ImpactTypeModal hideModal={props?.modalAction} />,
                modalSize: 'lg',
            },
        ],
        newImpactType: (props) => [
            {
                modalTitle: props?.data?.title + ' > Add New',
                modalContent: <NewImpactModal hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'lg',
            },
        ],
        reportPanelMap: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <ReportPanelMapModal hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'lg',
            },
        ],
        reportPanelHtml: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <ReportPanelHtmlModal hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'lg',
            },
        ],
        reportPanelHtmlDelete: (props) => [
            {
                modalTitle: 'Delete Rgcp Html',
                modalContent: 'Are you sure you want to delete this rgcp html permanently?',
                modalButton: 'Proceed',
            },
        ],
        addressDefault: (props) => [
            {
                modalTitle: props?.data?.title + ' > Address Defaults',
                modalContent: <AddressDefaultModal hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'lg',
            },
        ],
        vimeo: (props) => [
            {
                modalTitle: props?.data?.title + ' > Vimeo Account',
                modalContent: <VimeoAccountModal hideModal={props?.modalAction} data={props.data} />,
            },
        ],
        broadcast: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <BroadcastModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg ',
            },
        ],
        broadcastDelete: (props) => [
            {
                modalTitle: 'Delete Broadcast',
                modalContent: 'Are you sure you want to delete this broadcast permanently?',
                modalButton: 'Proceed',
                modalSize: 'small',
            },
        ],
        newPalette: (props) => [
            {
                modalTitle: props?.data?.title + ' > Palette > Add New',
                modalContent: 'You are about to create a new palette.',
                modalButton: 'Proceed',
                modalSize: 'small',
            },
        ],
        brandDelete: (props) => [
            {
                modalTitle: 'Delete Brand',
                modalContent: 'Are you sure you want to delete this brand permanently?',
                modalButton: 'Proceed',
                modalSize: 'small',
            },
        ],
        editBrandDetails: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <EditBrandDetails hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'lg',
            },
        ],
        brandName: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <BrandName hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
            },
        ],
        editPanel: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <EditContentPanel hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'lg',
            },
        ],
        deletePanel: (props) => [
            {
                modalTitle: 'Delete Content Panel',
                modalContent: 'Are you sure you want to delete this content panel permanently?',
                modalButton: 'Proceed',
                modalSize: 'small',
            },
        ],
        editStdPb: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <SitePrintBridgeModal hideModal={props?.modalAction} data={props.data} />,
                modalButton: 'Proceed',
                modalSize: 'lg',
            },
        ],
        editReportPb: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <ReportprintBridgeModal hideModal={props?.modalAction} data={props.data} />,
                modalButton: 'Proceed',
                modalSize: 'lg',
            },
        ],
        authorizeDevice: (props) => [
            {
                modalTitle: 'Authorize This Device ',
                modalContent:
                    'For your security, we would like to authorize this device to access your Ovrture account. Please hit proceed to have an authentication code sent to your account email address',
                modalButton: 'Proceed',
                modalSize: 'small',
            },
        ],
    },
    systemAdmin: {
        approve: (props) => [
            {
                modalTitle: 'Approve ' + (props?.data?.name === 'Site' ? 'Site' : 'Report'),
                modalContent:
                    'Are you sure you want to approve this ' + (props?.data?.name === 'Site' ? 'site' : 'report') + '.',
                modalButton: 'Approve',
                modalSize: 'small',
            },
        ],
        approvalNotification: (props) => [
            {
                modalTitle: 'Approve ' + (props?.data?.name === 'Site' ? 'Site' : 'Report') + ' Confirmation',
                modalContent: <ApprovalNotificationModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        approveMultiStepModal: (props) => [
            {
                modalTitle: 'Approve ' + (props?.data?.name === 'Site' ? 'Site' : 'Report'),
                modalContent: <ApproveMultiStepModalForm hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalSize: 'small',
            },
        ],
        login: (props) => [
            {
                modalTitle: 'Sign In',
                modalContent:
                    'You are about to login as another User/Admin. To return to the System Admin account you will have to logout and log back in. Proceed?',
                modalButton: 'Proceed',
                modalSize: 'small',
            },
        ],
        suspendUnsuspendUser: (props) => [
            {
                modalTitle:
                    (props.data.name === 'bulksuspended' || props.data.name === 'suspendUser'
                        ? 'Suspend'
                        : 'Unsuspend') +
                    (props.data.name === 'bulksuspended' || props.data.name === 'bulkUnsuspended' ? ' Users' : ' User'),
                modalContent: <BulkSuspendUnSuspend hideModal={props?.modalAction} data={props.data} />,
                modalMessageNote: 'Note: ',
                modalMessageText:
                    'If you elect to ' +
                    (props.data.name === 'bulksuspended' || props.data.name === 'suspendUser'
                        ? ' suspend ' +
                          (props.data.name === 'bulksuspended' ? 'these users' : 'this user') +
                          ', all sites, records and reports built by ' +
                          (props.data.name === 'bulksuspended' ? 'these users' : 'this user') +
                          ' will remain active. However, ' +
                          (props.data.name === 'bulksuspended' ? 'these users' : 'this user') +
                          ' will not be able to log back in to the platform until their ' +
                          (props.data.name === 'bulksuspended' ? 'accounts are' : 'account is') +
                          ' reinstated.'
                        : ' unsuspend ' +
                          (props.data.name === 'bulkUnsuspended' ? 'these users' : 'this user') +
                          ', they will be granted access to the platform and have their full permissions restored.'),
                modalBodyClass: 'custom-modal-border',
            },
        ],
        deleteUser: (props) => [
            {
                modalTitle: 'Delete User',
                modalContent: 'Are you sure you want to delete this/these user(s) permanently?',
                modalButton: 'Delete',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'If you delete this/these user(s), you will be able to transfer all of their related sites, reports, content and data to another user prior to permanently removing this user from the system.',
                modalSize: 'small custom-warning',
            },
        ],
        deleteMultiStepModal: (props) => [
            {
                modalTitle: 'Delete User',
                modalContent: <DeleteMultiStepForm hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'If you delete this/these user(s), you will be able to transfer all of their related sites, reports, content and data to another user prior to permanently removing this user from the system.',
                modalSize: 'lg',
            },
        ],
        canNotProceed: (props) => [
            {
                modalTitle: 'Delete User',
                modalContent:
                    'Cannot proceed with delete function as there are no users available to transfer the records.',
                modalCancelButton: 'Close',
                modalSize: 'small all-user-delete-modal',
            },
        ],
        deleteTransfer: (props) => [
            {
                modalTitle: 'Delete Transfer Confirmation',
                modalContent:
                    "Ok. Do you want to transfer ownership of this user's records and corresponding sites/reports first? ",
                modalMessageNote: 'Note:',
                modalMessageText:
                    ' If you neglect to transfer ownership of all related records and corresponding sites/reports prior to deleting this user, this content will be permanently deleted from the system.',
                modalSize: 'small custom-warning',
                modalButton: 'Transfer',
            },
        ],
        deleteActions: (props) => [
            {
                modalTitle:
                    props?.data?.name === 'softDeleteUserandGroups'
                        ? 'Delete Confirmation'
                        : props?.data?.name === 'bulkDeleteUserandGroups' ||
                          props?.data?.name === 'deleteRecordsRestore' ||
                          props?.data?.name === 'hardDeleteRecord'
                        ? 'Delete Confirmation'
                        : props?.data?.name === 'bulkRecordsDelete'
                        ? 'Test'
                        : '',
                modalContent: <DeleteActions hideModal={props?.modalAction} data={props?.data} />,
                modalMessageWarning: props?.data?.name === 'deleteRecordsRestore' ? 'WARNING: ' : '',
                modalMessageText:
                    props?.data?.name === 'deleteRecordsRestore'
                        ? 'If you elect to delete, you will not be able to retrieve any information related to this record.'
                        : '',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        transferProceed: (props) => [
            {
                modalTitle: 'Transfer Record',
                modalContent: <TransferProceed hideModal={props?.modalAction} data={props.data} />,
            },
        ],

        deleteRecord: (props) => [
            {
                modalTitle: 'Delete Confirmation',
                modalContent: <DeleteActions hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    props?.data?.name === 'bulkSitesDelete'
                        ? 'If deleted, this/these site(s) will be permanently purged from the system.'
                        : props?.data?.name === 'bulkReportssDelete'
                        ? 'If deleted, this/these report(s) will be permanently purged from the system.'
                        : 'If deleted, the system administrator can either restore the record or permanently purge this record from the system.',
                modalSize: 'small',
            },
        ],
        userSettingResetPassword: (props) => [
            {
                modalTitle: 'Reset Password',
                modalContent: (
                    <UserSettingResetPassword
                        hideModal={props?.modalAction}
                        orgId={props?.data?.orgId}
                        id={props?.data?.id}
                        role={props?.data?.role}
                    />
                ),
                modalSize: 'lg',
            },
        ],

        portfolioResetPasscode: (props) => [
            {
                modalTitle: 'Reset Passcode',
                modalContent: <PortfolioResetPasscode id={props?.data?.id} hideModal={props?.modalAction} />,
                modalSize: 'lg',
            },
        ],

        deleteSite: (props) => [
            {
                modalTitle: 'Delete Site',
                modalContent: <DeleteActions hideModal={props?.modalAction} data={props.data} />,
                modalButton: 'Delete',
                modalSize: 'small',
                modalMessageWarning: 'Warning: ',
                modalMessageText: 'If deleted, this site will be permanently purged from the system.',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        deleteReport: (props) => [
            {
                modalTitle: 'Delete Report',
                modalContent: <DeleteActions hideModal={props?.modalAction} data={props.data} />,
                modalButton: 'Delete',
                modalMessageWarning: 'Warning: ',
                modalMessageText: 'If deleted, this report will be permanently purged from the system.',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        cancelUserSetting: (props) => [
            {
                modalTitle: 'Cancel User Setting',
                modalContent: 'Are you sure you want to cancel?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
                modalSize: 'small',
            },
        ],
        editMembership: (props) => [
            {
                modalTitle: 'Edit Members of ' + props?.data?.title,
                modalContent: <EditMembership hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
            },
        ],
        CreateEditConfirmation: (props) => [
            {
                modalTitle:
                    props?.data?.title === 'CreateGroup'
                        ? 'Create Group Confirmation'
                        : props?.data?.title === 'EditGroup'
                        ? 'Edit Membership Confirmation'
                        : '',
                modalContent: <CreateEditConfirmation hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],

        newGroup: (props) => [
            {
                modalTitle: 'Create New Group',
                modalContent: <NewGroup hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
            },
        ],
        selectMembers: (props) => [
            {
                modalTitle: 'Select Members of  ' + props?.data.name.name,
                modalContent: <AddMember hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
            },
        ],

        renameGroup: (props) => [
            {
                modalTitle: 'Rename Group',
                modalContent: <RenameGroup hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
            },
        ],
        deleteGroup: (props) => [
            {
                modalTitle: 'Delete Group',
                modalContent: <DeleteGroup hideModal={props?.modalAction} data={props.data.selectedItem} />,
                modalButton: 'Delete Group',
                modalBodyClass: 'custom-modal-border',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'If you elect to delete this group it will be permanently removed from the system and unrecoverable.',
            },
        ],

        exportUsers: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportUsersDataModal hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Download',
                modalBodyClass: 'custom-modal-border',
                modalSize: 'small',
            },
        ],
        exportRecords: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportRecords hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportUserActivity: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportUserActivity hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportFundDetails: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportFundDetailsModal hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Download',
                modalBodyClass: 'custom-modal-border',
                modalSize: 'small',
            },
        ],
        exportFyPerformance: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportFyPerformance hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportFyDonors: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportFyDonors hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportFyImpacts: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportFyImpacts hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportFyDistributionList: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportFyDistributionList hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportFyReportsPrintBridge: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportFyReportsPrintBridge hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportRecordBasedDataAndSnapshots: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportRecordBasedDataAndSnapshots hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportSystemWideDashboardData: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportSystemWideDashboardData hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
                modalMessageNote: 'Note: ',
                modalMessageText:
                    'Depending on the number of records, the gallery could take a long time to render. Accordingly, you will receive an email notification when the file is compiled and ready for download.',
            },
        ],
        exportArchiveData: (props) => [
            {
                modalTitle: 'Exports Archive (Past 30 Days)',
                modalContent: <ExportArchiveData hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
            },
        ],
        deleteExportArchiveData: (props) => [
            {
                modalTitle: 'Delete Archived Export',
                modalContent: 'Are you sure you want to delete archived export permanently?',
                modalButton: 'Delete',
                modalCancelButton: 'Cancel',
                modalSize: 'small',
                modalActionOnClickCancelButton: props?.data?.modalActionOnClickCancelButton,
            },
        ],
        sessionRecording: (props) => [
            {
                modalTitle: 'Session Recording',
                modalContent: <SessionRecording url={props?.data?.url ? props?.data?.url : ''} />,
                modalSize: 'lg',
            },
        ],
        deleteRegisteredDevice: (props) => [
            {
                modalTitle: 'Delete Device',
                modalContent: 'Are you sure you want to delete this device permanently?',
                modalButton: 'Delete',
                modalSize: 'small',
            },
        ],
        manageDownloads: (props) => [
            {
                modalTitle: 'Manage Record-Based FY Downloads',
                modalContent: <ManageDownloads hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg Manage-downloads-modal',
            },
        ],
        deleteDownloads: (props) => [
            {
                modalTitle: 'Delete Download',
                modalContent: 'Are you sure you want to delete record asset permanently?',
                modalButton: 'Delete',
                modalSize: 'small',
            },
        ],
        addReplaceDownloads: (props) => [
            {
                modalTitle: 'Replace Asset',
                modalContent: <ReplaceAssetModal />,
                modalSize: 'small',
            },
        ],
        currentEditors: (props) => [
            {
                modalTitle: 'Change Log',
                modalContent: <CurrentEditors data={props?.data} />,
                modalSize: 'lg',
                modalResize: 'modal-resize',
            },
        ],
        createAccessKey: (props) => [
            {
                modalTitle: 'Access Key Created',
                modalContent: <CreateAccessKey hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
                modalBodyClass: 'custom-modal-border',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'Never post your secret access keys on any public platforms, such as Github. This can compromise your account security. This is the only time that the secret access key can be viewed. You cannot recover them later. However, you can recreate new access keys at any time.',
            },
        ],
        deleteAccessKey: (props) => [
            {
                modalTitle: 'Delete Access Key',
                modalContent: <DeleteAccessKey hideModal={props?.modalAction} data={props?.data} />,
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    "If you delete, any Ovrture API call made using this key will fail. Before you delete an access key, make sure that it's no longer in use. You cannot recover an access key after you delete it.",
                modalSize: 'small',
            },
        ],
        cancelBroadcast: (props) => [
            {
                modalTitle: 'Cancel Confirmation',
                modalContent: 'Are you sure you want to cancel creating this broadcast/alert?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
                modalSize: 'small',
            },
        ],
        filterByBrand: (props) => [
            {
                modalTitle: 'Filter Alert To Specific Brands',
                modalContent: <FilterByBrand hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
            },
        ],
        removeAlert: (props) => [
            {
                modalTitle: 'Remove Confirmation',
                modalContent: 'Are you sure you want to remove this alert from the system?',
                modalButton: 'Remove',
                modalSize: 'small',
            },
        ],
        updateTimer: (props) => [
            {
                modalTitle: 'Update Timer',
                modalContent: <UpdateTimer hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
            },
        ],
        broadcastConfirmation: (props) => [
            {
                modalTitle: 'Broadcast',
                modalContent: 'Are you sure you want to broadcast this revised alert across the entire system?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
                modalSize: 'small',
            },
        ],
        broadcastPublishMultiStepModal: (props) => [
            {
                modalTitle: 'Broadcast Publish',
                modalContent: <BroadcastPublishMultiStepForm hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalSize: 'lg',
            },
        ],
        broadcastSetTimerConfirmation: (props) => [
            {
                modalTitle: 'Set a Timer To Remove Alert',
                modalContent: 'Got it. Would you like to determine when the alert should be removed?',
                modalButton: 'Yes; Set A Timer To Remove This Alert.',
                modalSize: 'small',
            },
        ],
        cropImage: (props) => [
            {
                modalTitle: 'Edit Image',
                modalInfoIcon: true,
                modalInfoMsg:
                    'This tool allows you to fit images into the aspect ratio required by the site design. Click and drag to move the window. Use either the slider or pinch in/out to zoom.',
                modalContent: (
                    <ImageCropper
                        hideModal={props?.modalAction}
                        image={props?.data?.image}
                        setCropData={props.data.setCropData}
                        filename={props.data.filename}
                        name={props?.data?.name}
                        otherProps={props?.data}
                    />
                ),
                modalSize: 'small',
            },
        ],
        setBroadcastTimer: (props) => [
            {
                modalTitle: 'Set Broadcast Timer',
                modalContent: <SetBroadcastTimer data={props?.data} />,
            },
        ],
        sendBroadcastNotification: (props) => [
            {
                modalTitle: 'Send Broadcast Notification',
                modalContent: 'Would you like to send a notification to all users that this alert has been broadcast?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
                modalSize: 'small',
                modalCancelButtonAction: true,
            },
        ],
        restoreRecords: (props) => [
            {
                modalTitle: ' Restore Records',
                modalContent: 'Are you sure you want to restore this record?',
                modalButton: 'Restore',
                modalCancelButton: 'Cancel',
                modalSize: 'small',
            },
        ],
        restoreActions: (props) => [
            {
                modalTitle: 'Owner Declaration ',
                modalContent: <RestoreActions hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        restoreOriginalOwner: (props) => [
            {
                modalTitle: 'Owner Selection',
                modalContent: <RestoreOriginalOwner hideModal={props?.modalAction} data={props.data.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        restoreDelete: (props) => [
            {
                modalTitle: 'Delete System',
                modalContent:
                    'The following system were deleted by the platform admin.This function allows you to restore these systems or permanently delete them from the database',
                modalButton: 'Process',
                modalSize: 'small',
            },
        ],
        brandAdminSettingConfirmation: (props) => [
            {
                modalTitle: props.data.name === 'barndAdminConfirmation' ? 'Brand Administration' : 'Brand Access',
                modalContent: <BrandAdminSetting hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    props.data.name === 'brandAllUserConfirmation'
                        ? "Clicking 'yes' will overwrite all previous access settings for this brand."
                        : props.data.name === 'barndAdminConfirmation'
                        ? 'Clicking "yes" will overwrite all previous access administrator settings for this brand.'
                        : props.data.name === 'brandSelectGroup' || props.data.name === 'brandSelectUser'
                        ? " Clicking 'yes' will overwrite all previous access settings for this brand."
                        : ' ',
                modalBodyClass: 'custom-modal-border',
            },
        ],
    },
    reportData: {
        fundPreview: (props) => [
            {
                modalTitle:
                    props?.data?.type === 'fund-preview'
                        ? 'Fund Preview'
                        : props?.data?.type === 'impact-preview'
                        ? 'Impact Preview'
                        : 'Fund Performance Preview',
                modalContent: <FundPreviewModal data={props?.data} hideModal={props?.modalAction} />,
                modalButton: 'Back',
                modalSize: 'lg',
            },
        ],
        viewLinkedRecords: (props) => [
            {
                modalTitle: 'Linked Records',
                modalContent: <LinkedRecords hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Close',
                modalSize: 'lg',
                modalResize: 'modal-resize',
            },
        ],
        impactSort: (props) => [
            {
                modalTitle: 'Impact Sort',
                modalContent: <ImpactSortModal hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Sort',
                modalSize: 'lg',
            },
        ],
        deleteFunds: (props) => [
            {
                modalTitle: props?.data?.type === 'fund-details' ? 'Delete Fund' : 'Delete Fund Performance Details',
                modalContent: 'Are you sure you want to proceed?',
                modalButton: 'Delete',
                modalSize: 'small custom-warning',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    props?.data?.type === 'fund-details'
                        ? `Deleting this fund will purge fund data (details, performance, and impact) from the system for all fiscal years. Furthermore, deleting this fund will retroactively remove all fund panels within all reports that reference this fund.`
                        : 'Deleting this fund performance will retroactively remove all fund performance panels within all reports that reference this fund for the fiscal year.',
            },
        ],
        addDonor: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: (
                    <AddDonor
                        titile="Please enter an existing record reference number to add new donor in list:"
                        hideModal={props?.modalAction}
                        data={props?.data}
                    />
                ),
                modalButton: 'Add',
                modalSize: 'lg',
            },
        ],
        deleteDonor: (props) => [
            {
                modalTitle: 'Delete FY Donor(s)',
                modalContent: 'Are you sure you want to delete data for this FY Donor(s)?',
                modalButton: 'Delete',
                modalSize: 'small',
            },
        ],
        deleteImpact: (props) => [
            {
                modalTitle: 'Delete Fund Impact',
                modalContent: 'Are you sure you want to proceed?',
                modalButton: 'Delete',
                modalSize: 'small custom-warning',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'Deleting this fund impact will retroactively remove all fund impact panels within all reports that reference this fund for the fiscal year.',
            },
        ],
        addNewFyData: (props) => [
            {
                modalTitle: 'Add Fiscal Year',
                modalContent: <AddNewFyData />,
                modalButton: 'Add',
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
    },
    common: {
        infoModal: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: props.data.content,
                modalButton: 'Ok',
            },
        ],
        resetPasswordCommon: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <ResetPasswordCommon hideModal={props?.modalAction} data={props?.data} />,
            },
        ],
        linkFunds: (props) => [
            {
                modalTitle:
                    props?.data?.type === 'report-update' ? props?.data?.title : `${props?.data?.username}'s - Funds`,
                modalInfoIcon: props?.data?.type === 'report-update' ? false : true,
                modalInfoMsg:
                    'Linking this record to specific funds will dictate what funds are pulled when/if a Report is built for this record.',
                modalContent: <LinkFunds hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg link-fund-modal',
            },
        ],
        multiStepResetPassword: (props) => [
            {
                modalTitle: 'Reset Passcode',
                modalContent: <MultiStepResetPassword hideModal={props?.modalAction} data={props?.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalSize: 'small',
            },
        ],
        selectContentPanel: (props) => [
            {
                modalTitle: 'Select Content Panel',
                modalContent: <SelectContentPanelModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'select-content-panel',
            },
        ],
        replaceAsset: (props) => [
            {
                modalTitle: 'Replace Asset',
                modalContent: <ManageDownloadReplaceAssetModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'replace-asset',
            },
        ],
        replaceAssetCreateFund: (props) => [
            {
                modalTitle: 'Replace Asset',
                modalContent: <CreateFundReplaceAssetModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'replace-asset',
            },
        ],
        btdGreetingMessage: (props) => [
            {
                modalTitle:
                    (props?.data?.siteOrReport === 'site' ? 'Sites - ' : 'Reports - ') +
                    props?.data?.brandName +
                    ' - Default Greeting Message',
                modalContent: <BtdGreetingMessage hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg default-greeting-message',
            },
        ],
        btdGreetingImage: (props) => [
            {
                modalTitle: 'Select Default Header Image',
                modalContent: <BtdGreetingImage hideModal={props?.modalAction} data={props?.data} />,
                modalSize: ' default-header-image-modal',
            },
        ],
        deleteFundDetails: (props) => [
            {
                modalTitle:
                    props?.data?.type === 'fund-details'
                        ? 'Delete Fund Details'
                        : props?.data?.type === 'fund-performance'
                        ? 'Delete FY Performance'
                        : props?.data?.type === 'fund-donors'
                        ? 'Delete FY Donors'
                        : 'Delete FY Impact',
                modalContent: 'Are you sure you want to delete?',
                modalButton: 'Delete',
                modalSize: 'small custom-warning',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'This action could affect active reports and will be irrevocable as all data will be permanently removed from the database.',
            },
        ],
        preflightData: (props) => [
            {
                modalTitle: props.data.isNotification ? 'Data Import Results' : 'Data Import Pre-Flight Results',
                modalContent: <PreflightData hideModal={props?.modalAction} data={props?.data} />,
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'If you elect to import/cancel without first downloading the annotated spreadsheet, you can access it from the System Alerts at a later time.',
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        importData: (props) => [
            {
                modalTitle: 'Data Import Results',
                modalContent: <ImportData hideModal={props?.modalAction} data={props?.data} />,
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'If you elect to close this window without first downloading the failed rows, you can access the failed rows from the System Alerts at a later time.',
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        selectBrand: (props) => [
            {
                modalTitle: 'Select Brand',
                modalContent: <SelectBrand hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
            },
        ],
        buildSites: (props) => [
            {
                modalTitle: 'Create Sites Confirmation',
                modalContent:
                    'Are you sure you are ready to build these sites? Once built they will be written to each record.',
                modalButton: 'Build',
                modalSize: 'small',
            },
        ],
        buildReports: (props) => [
            {
                modalTitle: 'Create Reports Confirmation',
                modalContent:
                    'Are you sure you are ready to build these reports? Once built they will be written to each client record.',
                modalButton: 'Build',
                modalSize: 'small',
            },
        ],
        buildReport: (props) => [
            {
                modalTitle: 'Build ' + props?.data?.year + ' Report For ' + props?.data?.username,
                modalContent: <BuildReport hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        progressBar: (props) => [
            {
                modalTitle: 'Step 1  of 2: Uploading...',
                modalContent: <ProgressBarStatus hideModal={props?.modalAction} />,
                modalSize: 'small',
            },
        ],
        newFolder: (props) => [
            {
                modalTitle: 'Add Folder',
                modalContent: <NewFolder hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
            },
        ],
        renameFolder: (props) => [
            {
                modalTitle: 'Rename Folder',
                modalContent: <RenameFolder hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
            },
        ],
        deleteFolder: (props) => [
            {
                modalTitle: props?.data?.name == 'folderDelete' ? 'Delete Folder' : ' Delete Content Panel',
                modalContent: <DeleteFolder hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    props?.data?.name == 'folderDelete'
                        ? 'If you elect to delete this folder, the folder and its contents will be permanently removed from the system and unrecoverable.'
                        : 'If you elect to delete this content panel, it will be permanently removed from the system and unrecoverable.',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        changeLocation: (props) => [
            {
                modalTitle: 'Select New Location',
                modalContent: <SelectNewLocationModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
            },
        ],
        changeLocationConfirmation: (props) => [
            {
                modalTitle: 'Move',
                modalContent: <ChangeLocationConfirmationModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        changeLocationAsset: (props) => [
            {
                modalTitle: 'Select New Location',
                modalContent: <ChangeLocationModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
            },
        ],
        renameContentPanel: (props) => [
            {
                modalTitle: 'Rename Content Panel',
                modalContent: <RenameContentPanel hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
            },
        ],
        duplicateContentPanel: (props) => [
            {
                modalTitle: 'Duplicate Content Panel',
                modalContent: 'Are you sure you want to create a duplicate of this Content Panel?',
                modalButton: 'Duplicate',
                modalMessageNote: 'Note: ',
                modalMessageText: 'Once duplicated, the new content panel will be placed in your drafts folder.',
                modalSize: 'small custom-warning',
            },
        ],
        contentPanelPreview: (props) => [
            {
                modalTitle: props?.data?.data?.text,
                modalContent: <ContentPanelPreview data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'xl preview',
            },
        ],

        assetPanelPreview: (props) => [
            {
                modalTitle: 'Asset Preview > ' + props?.data?.data?.text,
                modalContent: <AssetPanelPreview hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg asset-preview',
            },
        ],
        assetUpload: (props) => [
            {
                modalTitle: 'Upload',
                modalContent: <AssetUploadModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
                modalMessageNote: 'Note: ',
                modalMessageText:
                    'Compatible file types include the commonly used image, video, and document files. Images can not be uploaded that exceed 32 megabytes. Videos can not be uploaded that exceed 1 gigabyte. Learn more in the Compatible Asset File Types support article.',
            },
        ],
        renameAsset: (props) => [
            {
                modalTitle: 'Rename Asset Modal',
                modalContent: <RenameAsset hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
            },
        ],
        deleteAsset: (props) => [
            {
                modalTitle: 'Delete Asset',
                modalContent: 'Are you sure you want to delete this asset?',
                modalButton: 'Delete',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'If you elect to delete this asset, it will be permanently removed from the system and unrecoverable.',
                modalSize: 'small custom-warning',
            },
        ],
        selectTemplate: (props) => [
            {
                modalTitle: 'Select Template',
                modalContent: 'Content Body with JS Tree & preview// TBD',
                modalButton: 'Select',
                modalSize: 'small',
            },
        ],
        selectTemplateWithPreview: (props) => [
            {
                modalTitle: 'Select Template',
                modalContent: <SelectTemplateWithPreviewModal hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Select',
                modalSize: 'lg select-template-with-preview-modal',
            },
        ],
        cancelEditContentPanel: (props) => [
            {
                modalTitle: 'Cancel Edited Content Panel',
                modalContent: 'Are you sure you want to cancel editing this content panel?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
                modalSize: 'small',
            },
        ],
        saveDraftModal: (props) => [
            {
                modalTitle: 'Save Draft',
                modalContent: <SaveDraftModal hideModal={props?.modalAction} data={props?.data} />,
                modalMessageNote: 'Note: ',
                modalMessageText: 'Once saved as a draft, this content panel will be accessible via the Drafts folder.',
                modalSize: 'small',
            },
        ],
        publishEditedContentPanel: (props) => [
            {
                modalTitle: 'Publish Edited Content Panel',
                modalContent: 'Are you sure you want to publish this edited content panel?',
                modalButton: 'Publish',
                modalCancelButton: 'Cancel',
                modalSize: 'small',
            },
        ],
        publishEditedContentPanelMultiStepModal: (props) => [
            {
                modalTitle: 'Content Panel',
                modalContent: (
                    <PublishEditedContentPanelMultiStepForm hideModal={props?.modalAction} data={props.data} />
                ),
                modalBodyClass: 'custom-modal-border multi-step',
                modalSize: 'small',
            },
        ],
        contentPanelAccess: (props) => [
            {
                modalTitle: 'Content Panel Access',
                modalContent:
                    props?.data?.action === 'selectGroups' || props?.data?.active === 2
                        ? 'Are you sure you want to provide access to this content panel to these selected groups?'
                        : props?.data?.action === 'selectUsers' || props?.data?.active === 3
                        ? 'Are you sure you want to provide access to this content panel to these selected users?'
                        : 'Are you sure you want to provide access to this content panel to all users?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
                modalMessageNote: 'Note: ',
                modalMessageText: 'Clicking "yes" will overwrite all previous asset settings for this content panel.',
                modalSize: 'small custom-warning',
            },
        ],
        sendNotification: (props) => [
            {
                modalTitle: 'Send Notification',
                modalContent:
                    'Would you like to send a notification to all users that this content panel has been created?',
                modalButton: 'Send Notification',
                modalCancelButton: 'Do Not Send Notification',
                modalCancelButtonAction: 'true',
                modalSize: 'small',
            },
        ],
        saveDraft: (props) => [
            {
                modalTitle: 'Save Draft',
                modalContent: 'How do you want to proceed?',
                modalButton: 'Save Draft & Exit',
                modalCancelButton: 'Cancel',
                modalMessageNote: 'Note: ',
                modalMessageText: 'Once saved as a draft, this content panel will be accessible via the Drafts folder.',
                modalSize: 'small custom-warning',
            },
        ],
        assetControlAccess: (props) => [
            {
                modalTitle: 'Asset Control Access',
                modalContent:
                    props?.data?.action === 'selectGroups' || props?.data?.active === 2
                        ? 'Are you sure you want to provide access to this asset to these selected groups?'
                        : props?.data?.action === 'selectUsers' || props?.data?.active === 3
                        ? 'Are you sure you want to provide access to this asset to these selected users?'
                        : 'Are you sure you want to provide access to this asset to all users?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
                modalMessageNote: 'Warning: ',
                modalMessageText: 'Clicking "yes" will overwrite all previous access settings for this asset.',
                modalSize: 'small custom-warning',
            },
        ],
        copyUrl: (props) => [
            {
                modalTitle: 'Copy URL',
                modalContent: <CopyUrl data={props?.data} />,
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'The Donor-Facing URL (Analytics Enabled) will not work until an Admin has approved it. Please wait for approval before sharing the URL.',
            },
        ],
        allSnapshots: (props) => [
            {
                modalTitle:
                    props?.data?.type === 'analytics-page' ? `${props?.data?.recordName}'s History` : 'All Activities',
                modalInfoIcon: true,
                modalInfoMsg:
                    'This table represents all of the snapshots (i.e. key versions) of this site/report and all of the external logins that have been recorded.',
                modalContent: <Snapshots hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'xl',
                modalResize: 'modal-resize',
            },
        ],
        editorsList: (props) => [
            {
                modalTitle: 'Change Log',
                modalContent: <EditorsList hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
                modalResize: 'modal-resize',
            },
        ],
        deleteSnapshot: (props) => [
            {
                modalTitle: 'Delete Confirmation',
                modalContent:
                    'Are you sure you want to delete session permanently? Doing so will discard all record of this external login.',
                modalButton: 'Delete',
                modalActionOnClickCancelButton: props?.data?.modalActionOnClickCancelButton,
            },
        ],
        leavingRouteMsg: (props) => [
            {
                modalTitle: 'Unsaved Changes',
                modalContent: <PrompDialougeModal data={props?.data} />,
                modalSize: 'md',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        duplicateSite: (props) => [
            {
                modalTitle: 'Duplicate Site',
                modalContent: <DuplicateModal hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Close',
                modalSize: 'lg',
                modalResize: 'modal-resize',
            },
        ],
    },
    systemNotification: {
        networkError: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: props.data.content,
                modalButton: 'Delete',
                modalSize: 'small connection-error',
            },
        ],
        sessionExpired: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: props.data.content,
            },
        ],
    },
    editor: {
        addOrChangeSlide: (props) => [
            {
                modalTitle: 'Slide Layout',
                modalContent: <AddOrChangeSlide hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
            },
        ],
        printBridgeHeading: (props) => [
            {
                modalTitle: 'Document Heading',
                modalContent: <PrintBridgeHeading hideModal={props.modalAction} />,
                modalSize: 'small',
            },
        ],
        printWarning: (props) => [
            {
                modalTitle: 'Document/Video Exist',
                modalContent: 'Are you sure you want to print?',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'This content panel contains video(s) or downloadable document(s) that will render as icons. We recommend you replace these assets with photos before printing.',
                modalButton: 'Print',
                modalCancelButton: 'Cancel/Replace',
                modalSize: 'small',
            },
        ],
        printPreview: (props) => [
            {
                modalTitle: 'Print/Download Preview',
                modalContent: <PrintPreview hideModal={props.modalAction} />,
                modalSize: 'lg',
            },
        ],
        delete: (props) => [
            {
                modalTitle:
                    props?.data?.name === 'panel'
                        ? 'Panel Delete'
                        : props?.data?.name === 'slide'
                        ? 'Slide Delete'
                        : 'Report Update Delete',
                modalContent:
                    'Are you sure you want to delete this ' +
                    (props?.data?.name === 'panel'
                        ? 'panel'
                        : props?.data?.name === 'slide'
                        ? 'slide'
                        : 'report update') +
                    '?',
                modalButton: 'Delete',
                modalSize: 'small',
            },
        ],
        changeCoverImage: (props) => [
            {
                modalTitle: 'Select Cover Image',
                modalContent: <ChangeCoverImage hideModal={props.modalAction} />,
                modalSize: 'lg',
            },
        ],
        addContentPanel: (props) => [
            {
                modalTitle: 'Add Content Panel',
                modalContent: <AddContentPanelModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'select-content-panel',
            },
        ],
        brandChange: (props) => [
            {
                modalTitle: 'Brand Change',
                modalContent: 'Are you sure you want to change this brand?',
                modalButton: 'Apply',
                modalSize: 'small',
            },
        ],
        printReportElements: (props) => [
            {
                modalTitle: 'Print Report Elements',
                modalContent: <PrintReportElements hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        replaceAssetEditor: (props) => [
            {
                modalTitle: 'Replace Asset',
                modalContent: <ReplaceAssetEditorModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'replace-asset-editor',
                modalMessageNote: 'Note: ',
                modalMessageText:
                    'Compatible file types include the commonly used image, video, and document files. Images can not be uploaded that exceed 32 megabytes. Videos can not be uploaded that exceed 1 gigabyte. Learn more in the Compatible Asset File Types support article.',
            },
        ],
        replaceScriptCode: (props) => [
            {
                modalTitle: 'Insert Embed Code',
                modalContent: <ReplaceScriptCodeModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'lg',
                modalBodyClass: 'replace-script-code',
            },
        ],
        replaceEmbedVideo: (props) => [
            {
                modalTitle: 'Linked Asset',
                modalContent: <EmbedVideoModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'replace-embed-video',
            },
        ],
    },
};

export { modal };
