// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

//Components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { userUnpagedList } from '@ovrture/react-redux';
import ListItemSelection from '../../../components/listItem/ListItem';
import { FormInput } from '../../../components/form/input';

const AddMemberModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const [list, setList] = useState([]);
    const [listInit, setListInit] = useState([]);
    const [checkedItem, setCheckedItem] = useState([]);
    const [isSelectAll, setIsSelectAll] = useState();
    const [isSelectNone, setIsSelectNone] = useState();
    const [searchVal, setSearchVal] = useState('');

    useEffect(() => {
        dispatch(
            userUnpagedList({
                isAnd: true,
                isUnpaged: true,
            })
        );
    }, []);

    const { listArr } = useSelector((state) => ({
        listArr: state?.user?.userUnpagedList,
    }));

    useEffect(() => {
        if (listArr?.content) {
            let arrList = [];
            listArr?.content?.map((item) => {
                arrList.push({ title: item.colFullName, id: item.id });
            });
            setList(arrList);
            setListInit(arrList);
        }
    }, [listArr]);

    const SelectAll = () => {
        if (searchVal) {
            const filterList = list.filter((el) => {
                return (
                    el?.title?.toLocaleLowerCase()?.includes(searchVal) ||
                    el?.name?.toLocaleLowerCase()?.includes(searchVal) ||
                    el?.colFullName?.toLocaleLowerCase()?.includes(searchVal)
                );
            });
            filterList.map((item) => (item.checked = true));
            setCheckedItem([...checkedItem, ...filterList]);
        } else setList(list.map((item) => ({ ...item, checked: true })));
        setIsSelectAll(true);
        setIsSelectNone(false);
    };
    const DeselectAll = () => {
        if (searchVal) {
            const filterList = list.filter((el) => {
                return (
                    el?.title?.toLocaleLowerCase()?.includes(searchVal) ||
                    el?.name?.toLocaleLowerCase()?.includes(searchVal) ||
                    el?.colFullName?.toLocaleLowerCase()?.includes(searchVal)
                );
            });
            filterList.map((item) => (item.checked = false));
            setCheckedItem([...checkedItem, ...filterList]);
        } else setList(list.map((item) => ({ ...item, checked: false })));
        setIsSelectAll(false);
        setIsSelectNone(true);
    };

    const unSelectedItem = () => {
        if (isSelectAll & (searchVal === '')) return [];
        let uncheckedIds = [];
        let uncheckedItems = [];
        let checkedItems = isSelectNone & (searchVal === '') ? [] : checkedItem.filter((f) => f.checked === true);
        listInit.map((item) => {
            let itemMatched = checkedItems.find((f) => f.id === item.id);
            if (!itemMatched) uncheckedItems.push(item);
        });
        uncheckedItems.map((item) => uncheckedIds.push(item.id));
        return uncheckedIds;
    };

    const getSearchValue = (val) => {
        setSearchVal(val);
    };

    useEffect(() => {
        if (checkedItem?.length > 0) {
            let isAllChecked = list.every((val) => val.checked === true);
            let isAllUnChecked = list.every((val) => val.checked === false);
            if (isAllChecked) {
                document.getElementById('selectAccess').indeterminate = false;
                document.getElementById('selectAccess').checked = true;
            } else if (isAllUnChecked) {
                document.getElementById('selectAccess').indeterminate = false;
                document.getElementById('selectAccess').checked = false;
            } else {
                document.getElementById('selectAccess').checked = false;
                document.getElementById('selectAccess').indeterminate = true;
            }
        } else if (checkedItem?.length == 0) {
            document.getElementById('selectAccess').checked = false;
            document.getElementById('selectAccess').indeterminate = false;
        }
    }, [checkedItem]);

    return (
        <>
            <ListGroup className="listheader custom-list-header-color">
                <ListGroup.Item action className="active">
                    <Row>
                        <Col md={12}>
                            <FormInput
                                type="checkbox"
                                id="selectAccess"
                                label="Access To:"
                                className="mb-0"
                                onChange={(e) => (e.target.checked ? SelectAll() : DeselectAll())}
                            />
                        </Col>
                    </Row>
                </ListGroup.Item>
            </ListGroup>
            <ListGroup className="custom-hand-pointer-none">
                <ListItemSelection
                    type="checkbox"
                    list={list}
                    setList={() => {}}
                    isSearchFilter={true}
                    onClick={(item) => {
                        setCheckedItem([...checkedItem, item]);
                    }}
                    getSearchValue={getSearchValue}
                />
            </ListGroup>

            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Proceed"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        modalDispatch({
                            type: modalActions.OPEN_MODAL,
                            payload: {
                                modalAction: (e) => props.hideModal(),
                                modalVariant: 'primary',
                                modalDataKey: ['systemAdmin', 'CreateEditConfirmation'],
                                data: {
                                    name: props.data.name,
                                    unCheckedUserIdList: unSelectedItem(),
                                    title: 'CreateGroup',
                                },
                            },
                        });
                    }}
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={(e) => props.hideModal()} />
            </div>
        </>
    );
};

export default AddMemberModal;
