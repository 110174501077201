// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
// components
import { FormInput } from '../../../../components/form/input';
import Table from '../../../../components/table/Table';
import ButtonElement from '../../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../../constants/table';
import { useDispatch, useSelector } from 'react-redux';
import {
    paletteList,
    fundTypeList,
    accessCriteriaForReportDataReset,
    accessCriteriaForReportDataFetch,
    accessCriteriaForReportDataAdd,
    accessCriteriaForReportDataUpdate,
} from '@ovrture/react-redux';
import { ObjToArrayConvert } from '../../../../utility';
import { dateTimeFormat } from '../../../../utility';

const FundDescriptionModal = (props) => {
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        setError,
        reset,
        formState: { errors },
    } = useForm({});
    const [data, setData] = useState([]);
    const [updatedAt, setUpdatedAt] = useState();
    const dispatch = useDispatch();
    const [fundTypeId, setFundTypeId] = useState(null);
    const [paletteId, setPaletteId] = useState(null);

    const { list, fundList, add, update, orgFetch, loading, paletteDataList, accessCriteriaLoading } = useSelector(
        (state) => ({
            paletteDataList: state?.palette?.list?.data,
            fundList: state?.fundType?.list?.data,
            list: state?.accessCriteriaForReportData?.fetch,
            accessCriteriaLoading: state?.accessCriteriaForReportData?.loading,
            add: state?.accessCriteriaForReportData?.add,
            update: state?.accessCriteriaForReportData?.update,
            loading: state?.accessCriteriaForReportData?.loading,
            orgFetch: state?.organization?.fetch?.data,
        })
    );
    useEffect(() => {
        dispatch(fundTypeList({ orgId: orgFetch?.id }));
    }, []);
    useEffect(() => {
        if (list) {
            console.log(list);
            setUpdatedAt(dateTimeFormat(list?.colUpdatedAt));
        }
    }, [list]);
    useEffect(() => {
        if (fundTypeId) dispatch(paletteList({ orgId: orgFetch.id }));
    }, [fundTypeId]);

    useEffect(() => {
        if (paletteId && fundTypeId) {
            setValue('paletteId', paletteId);
            dispatch(
                accessCriteriaForReportDataFetch({ orgId: orgFetch.id, paletteId: paletteId, fundTypeId: fundTypeId })
            );
        }
    }, [paletteId, fundTypeId]);
    useEffect(() => {
        if (list) {
            reset();
            setValue('fundTypeId', list?.fkFundTypeId);
            setValue('paletteId', list?.fkPaletteId);
            setData(ObjToArrayConvert(list, 4));
        }
        if (add) {
            dispatch(accessCriteriaForReportDataReset());
            props?.hideModal();
        }
        if (update) {
            dispatch(accessCriteriaForReportDataReset());
            props?.hideModal();
        }
    }, [list, add, update, orgFetch]);

    const onSubmit = (data) => {
        if (data.fundTypeId) data.fundTypeId = parseInt(data.fundTypeId);
        if (data?.paletteId) data.paletteId = parseInt(data.paletteId);
        if (data?.colImageHeightSize) data.colImageHeightSize = parseInt(data.colImageHeightSize);
        if (data?.colImageWidthSize) data.colImageWidthSize = parseInt(data.colImageWidthSize);
        list?.pkAccessCriteriaForFundId
            ? dispatch(
                  accessCriteriaForReportDataUpdate({
                      orgId: orgFetch.id,
                      pkAccessCriteriaForFundId: list?.pkAccessCriteriaForFundId,
                      ...data,
                  })
              )
            : dispatch(accessCriteriaForReportDataAdd({ orgId: orgFetch.id, ...data }));
    };

    const toggle = () => {
        dispatch(accessCriteriaForReportDataReset());
        props?.hideModal();
    };

    const FieldName = ({ row }) => {
        return <ButtonElement as="text" name={row.original.fieldName} tooltip={row.original.replacer}></ButtonElement>;
    };

    const handleMandatory = (name, row) => {
        const check = !data[row.index][name];
        data[row.index][name] = check;
        setData(data);
        setError(name, row.original[name]);
        setValue(name, check);
    };

    const MandatoryColumn = ({ row }) => {
        return (
            <FormInput
                type="switch"
                className="mb-0 col-md-0"
                id={row.original.key + 'IsMandatory'}
                register={register}
                onChange={(e) => handleMandatory(row.original.key + 'IsMandatory', row)}
                checked={row.original[row.original.key + 'IsMandatory']}
            />
        );
    };
    const RenderColumn = ({ row }) => {
        return (
            <FormInput
                type="switch"
                className="mb-0 col-md-0"
                id={row.original.key}
                register={register}
                checked={row.original[row.original.key]}
                onChange={(e) => handleMandatory(row.original.key, row)}
            />
        );
    };

    const DefaultValue = ({ row }) => {
        return (
            <Controller
                name={row.original.key + 'DefaultValue'}
                control={control}
                rules={{ required: row.original[row.original.key + 'IsMandatory'] === true ? true : false }}
                defaultValue={row.original[row.original.key + 'DefaultValue']}
                render={({ field }) => (
                    <>
                        <FormInput
                            type="text"
                            id={row.original.key + 'DefaultValue'}
                            className="mb-0"
                            register={register}
                            errors={errors}
                            feedback="hide"
                            floatinglabel="false"
                        />
                    </>
                )}
            />
        );
    };

    const PreprocessingValue = ({ row }) => {
        return (
            <FormInput
                type="text"
                id={row.original.key + 'PreProcessingValue'}
                className="mb-0"
                register={register}
                defaultValue={row.original[row.original.key + 'PreProcessingValue']}
                feedback="hide"
                floatinglabel="false"
            />
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {list?.colUpdatedAt && <p className="text-end custom-font mb-1">Last Edited: {updatedAt}</p>}
            <Card>
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <FormInput
                                type="select"
                                id="fundTypeId"
                                className="mb-md-0"
                                label="Select Fund"
                                register={register}
                                onChange={(e) => setFundTypeId(e.target.value)}>
                                <option value="">Select Fund *</option>
                                {fundList?.map((item, index) => {
                                    return (
                                        <option key={index} value={item?.pkFundTypeId}>
                                            {item?.colFundTypeName}
                                        </option>
                                    );
                                })}
                            </FormInput>
                        </Col>

                        <Col md={6} className="custom-spacing">
                            <FormInput
                                type="select"
                                id="paletteId"
                                className={fundTypeId ? 'mb-0' : 'custom-disabled'}
                                label="Select Palette"
                                register={register}
                                onChange={(e) => setPaletteId(e.target.value)}>
                                <option value="">Select Palette *</option>
                                {paletteDataList?.map((item, index) => {
                                    return (
                                        <option key={index} value={item?.pkPaletteId}>
                                            {item?.colPaletteName}
                                        </option>
                                    );
                                })}
                            </FormInput>
                        </Col>
                    </Row>
                </Card.Body>
                {paletteId && (
                    <>
                        <Table
                            columns={TableColumn.editElent.reportData.fundDescription([
                                FieldName,
                                RenderColumn,
                                MandatoryColumn,
                                DefaultValue,
                                PreprocessingValue,
                            ])}
                            data={data}
                            pageSizes={list?.size}
                            pageNumber={list?.number}
                            totalElements={list?.totalElements}
                            totalPages={list?.totalPages}
                            paginationDispatch={accessCriteriaForReportDataFetch}
                            paginationDispatchParam={{ orgId: orgFetch?.id }}
                            sizePerPageList={TableColumn.sizePerPageList(data)}
                            isSortable={false}
                            pagination={false}
                            isSearchable={false}
                            isFilter={false}
                            isSelectable={false}
                            loading={accessCriteriaLoading}
                            theadClass={TableColumn.table_light}
                            tableClass="report-data-fund-description"
                            tableParentClass="height-fixed"
                        />
                        <Row className="px-3 pb-0">
                            <Col md={6}>
                                <FormInput
                                    type="text"
                                    id="colImageWidthSize"
                                    label="Image Width"
                                    register={register}
                                    defaultValue={list?.colImageWidthSize ? list?.colImageWidthSize : ''}
                                />
                            </Col>
                            <Col md={6}>
                                <FormInput
                                    type="text"
                                    id="colImageHeightSize"
                                    label="Image Height"
                                    register={register}
                                    defaultValue={list?.colImageHeightSize ? list?.colImageHeightSize : ''}
                                />
                            </Col>
                            <Col md={12}>
                                <FormInput
                                    type="text"
                                    id="colDefaultImageUrl"
                                    label=" Default Image Url"
                                    register={register}
                                    defaultValue={list?.colDefaultImageUrl ? list?.colDefaultImageUrl : ''}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </Card>
            <div className="button-list d-flex justify-content-center mt-3">
                {fundTypeId && paletteId && <ButtonElement name="Update" variant="primary" type="submit" />}
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default FundDescriptionModal;
