import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActivities } from '@ovrture/react-redux';

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => ({
        user: state?.auth?.user,
    }));

    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            if (
                window.location.pathname !== '/content-panel-edit' &&
                window.location.pathname !== '/content-broadcast-create'
            ) {
                dispatch(
                    userActivities({
                        colStatusCode: 200,
                        colRequestCompleteUrl: window.location.href,
                        colHttpVariables: null,
                    })
                );
            }
        }, 1000);
    }, []);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!user) {
                    return <Redirect to={{ pathname: '/public/login', state: { from: props.location } }} />;
                }
                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRoute;
