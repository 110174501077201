// @flow
import { notificationActions } from '../../reducers/actions';

const NoficationOpen = (data) => {
    return data.notificationDispatch({
        type: notificationActions.OPEN_NOTIFICATION,
        payload: {
            role: data?.role,
            title: data?.title,
            iconClass: (data?.iconClass || 'mdi mdi-check-circle') + ' h2',
            className: 'icon',
            message: data?.message,
        },
    });
};

export default NoficationOpen;
