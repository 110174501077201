export const StatusFilterIntialState = {
    newData: [],
    status: [],
    filterVale: [
        { label: 'Active', value: true },
        { label: 'Disabled', value: false },
    ],
    setDaleListFilter: '',
    showHide: false,
};

export const StateFilterReducer = (state = StatusFilterIntialState, action) => {
    switch (action.type) {
        case 'FETCH_FILTER':
            return {
                ...state,
                newData: action.payload.data,
            };
        case 'OPEN_FILTER':
            return {
                ...state,
                showHide: !state.showHide,
            };
        case 'ADD_FILTER':
            try {
                // let stArray = action.payload?.status?.status?.map((item) => {
                //     return item.value;
                // });

                action.payload.filterDispatch(action.payload.data);
            } catch (error) {
                console.log(error);
            }
            return {
                ...state,
                showHide: true,
            };

        case 'CLEAR_FILTER':
            action.payload.filterDispatch();
            return {
                ...state,
                showHide: false,
            };

        default:
            return {
                ...state,
            };
    }
};
export default StateFilterReducer;
