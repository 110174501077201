import { useNotificationContex } from '../contexts/NotificationContext';
import { notificationActions } from '../reducers/actions';

const Notifications = (props) => {
    const { notification, notificationDispatch } = useNotificationContex();
    if (notification.isVisible) {
        setTimeout(() => {
            notificationDispatch({
                type: notificationActions.CLOSE_NOTIFICATION,
            });
            if (notification.link) window.location.href = notification.link;
        }, 3000);
    }

    return (
        notification.isVisible && (
            <>
                <div className={`notification-box modal-filled p-2 pt-3 text-center bg-${notification?.role}`}>
                    <i className={notification?.iconClass}></i>
                    <h4 className="mt-1">{notification?.title}</h4>
                    <p className="mt-3">{notification?.message}</p>
                </div>
                <div className="fade modal-backdrop show"></div>
            </>
        )
    );
};

export default Notifications;
