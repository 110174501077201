
import { treeViewActions } from "./actions"

export const TreeViewIntialState = {
    treeData: [],
    selectedNode: [],
    relocateNode: [],
    selectedContentPanel: []
}
export const flatArray = (array) => {
    var result = [];
    array?.forEach(function (a) {
        result?.push(a);
        if (Array?.isArray(a?.children)) {
            result = result.concat(flatArray(a?.children));
        }
    });
    return result;
}
const updateNode = function (obj, parent, value, i, type, text) {
    if (type === 'deleteNode') {
        if (obj.text === value) {
            parent.splice(i, 1)
        }
    }
    if (type === 'renameNode') {
        if (obj.text === value) {
            parent[i].text = text
        }
    }
    if (type === 'replaceNode') {
        if (text === obj) {
            return
        }
        if (obj.id === value) {
            text.children.push(obj)
            parent.splice(i, 1)
        }
    }
    if (obj && obj.children && obj.children.length > 0) {
        for (let j = 0; j < obj.children.length; j++) {
            updateNode(obj.children[j], obj.children, value, j, type, text);
        }
    }
}
export const TreeViewReducer = (state = TreeViewIntialState, action) => {
    switch (action.type) {
        case treeViewActions.GET_TREE_DATA:
            return {
                ...state,
                treeData: action?.payload?.treeData,
            }
        case treeViewActions.GET_SELECTED_NODE:
            state.treeData.selected = action.payload.selected;
            state.selectedNode = action.payload.selectedNode;
            state.relocateNode = action.payload.relocateNode
            return {
                ...state,
                state: state
            }
        case treeViewActions.ADD_FOLDER:
            let data = state?.treeData?.data?.core?.data
            let result = flatArray(data);
            let childrenFind = result.filter(item => item?.text === state.selectedNode.text)
            if (childrenFind[0]?.children) {
                childrenFind[0]?.children.push(action.payload.treeData)
            }
            return {
                ...state,
                treeData: state
            }
        case treeViewActions.DELETE_SELECTED_NODE:
            for (let i = 0; i < state?.treeData?.data?.core?.data[0].children.length; i++) {
                updateNode(state.treeData.data.core.data[0].children[i], state.treeData.data.core.data[0].children, state.selectedNode.text, i, 'deleteNode', '');
            }
            return {
                ...state,
                treeData: state
            }
        case treeViewActions.RENAME_SELECTED_NODE:
            for (let i = 0; i < state?.treeData?.data?.core?.data[0].children.length; i++) {
                updateNode(state.treeData.data.core.data[0].children[i], state.treeData.data.core.data[0].children, state.selectedNode.text, i, 'renameNode', action.payload.treeData.text);
            }
            return {
                ...state,
                treeData: state
            }
        case treeViewActions.GET_LCATION_SELECTED_NODE:
            state.relocateNode = action.payload.relocateNode;
            return {
                ...state
            }
        case treeViewActions.RELOCATE_SELECTED_NODE:
            let relocateData = state?.treeData?.data?.core?.data
            let result2 = flatArray(relocateData)
            let childrenFind3 = result2.filter(item => item?.text === state.relocateNode.text)

            for (let i = 0; i < state?.treeData?.data?.core?.data[0].children.length; i++) {
                updateNode(state.treeData.data.core.data[0].children[i], state.treeData.data.core.data[0].children, state.treeData.selected[0], i, 'replaceNode', childrenFind3[0]);
            }
            return {
                ...state,
                treeData: state
            }
        // content panel 
        case treeViewActions.ADD_CONTENT_PANEL:
            let data1 = state?.treeData?.data?.core?.data
            let result1 = flatArray(data1);
            let childrenFind1 = result1.filter(item => item?.text === state.selectedNode.text)
            if (childrenFind1[0]?.children) {
                childrenFind1[0]?.children.push(state.selectedContentPanel)
            }
            return {
                ...state,
                treeData: state
            }
        case treeViewActions.DUPLICATE_CONTENT_PANEL:

            let childrenFind2 = flatArray(state?.treeData?.data?.core?.data).filter(item => item?.text === state.selectedNode.text)
            if (childrenFind2[0]?.children) {
                childrenFind1[0]?.children.push(state.selectedNode)
            }
            return {
                ...state,
                treeData: state
            }
        case treeViewActions.GET_CONTENT_PANEL:
            return {
                ...state,
                selectedContentPanel: action.payload.treeData
            }
        default: return state
    }
}

