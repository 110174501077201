// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Moment from 'moment';
// components
import { AddressDefaultValidation } from '../../../../components/form/validation';
import { FormInput } from '../../../../components/form/input';
import HyperDatepicker from '../../../../components/datepicker/Datepicker';
import ButtonElement from '../../../../components/button/ButtonElement';
import { useSelector, useDispatch } from 'react-redux';
import { organizationInfoFetch, organizationInfoUpdate, organizationInfoReset } from '@ovrture/react-redux';

const AddressDefaultModal = (props) => {
    const [selectedDate, setSelectedDate] = useState();
    const dispatch = useDispatch();
    const { addressInfo, update } = useSelector((state) => ({
        addressInfo: state?.organizationInfo?.fetch?.data,
        update: state?.organizationInfo?.update,
    }));

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm({ resolver: AddressDefaultValidation, mode: 'onBlur' });
    const watchAll = watch();

    useEffect(() => {
        dispatch(organizationInfoFetch({ organaizationInfoId: props.data.orgId }));
    }, []);

    useEffect(() => {
        if (addressInfo) {
            if (addressInfo?.colStartOfFiscalYear) {
                let newDate =
                    addressInfo?.colStartOfFiscalYear.split('/')[1] +
                    '/' +
                    addressInfo?.colStartOfFiscalYear.split('/')[0] +
                    '/' +
                    new Date().getFullYear();
                setSelectedDate(new Date(newDate));
            }
            setValue('colCity', addressInfo?.colCity);
            setValue('colStateProvince', addressInfo?.colStateProvince);
            setValue('colZipPostalCode', addressInfo?.colZipPostalCode);
            setValue('colCountry', addressInfo?.colCountry);
            setValue('colStartOfFiscalYear', addressInfo?.colStartOfFiscalYear);
            setValue('colWebAddress', addressInfo?.colWebAddress);
            setValue('colContactNo', addressInfo?.colContactNo);
            setValue('colFacebookUrl', addressInfo?.colFacebookUrl);
            setValue('colTwitterUrl', addressInfo?.colTwitterUrl);
            setValue('colLinkedinUrl', addressInfo?.colLinkedinUrl);
        }
        if (update) {
            dispatch(organizationInfoReset());
            dispatch(organizationInfoFetch({ organaizationInfoId: props.data.orgId }));
            reset();
            props.hideModal();
        }
    }, [addressInfo, update]);

    const onDateChange = (date) => {
        setSelectedDate(date);
    };

    const onSubmit = (data) => {
        data.colStartOfFiscalYear = Moment(selectedDate).format('DD/MM');
        addressInfo.colCity = data.colCity;
        addressInfo.colStateProvince = data.colStateProvince;
        addressInfo.colZipPostalCode = data.colZipPostalCode;
        addressInfo.colCountry = data.colCountry;
        addressInfo.colStartOfFiscalYear = data.colStartOfFiscalYear;
        addressInfo.colWebAddress = data.colWebAddress;
        addressInfo.colContactNo = data.colContactNo;
        addressInfo.colFacebookUrl = data.colFacebookUrl;
        addressInfo.colTwitterUrl = data.colTwitterUrl;
        addressInfo.colLinkedinUrl = data.colLinkedinUrl;
        dispatch(organizationInfoUpdate(addressInfo));
    };

    const toggle = () => props.hideModal();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={4}>
                    <FormInput
                        type="text"
                        id="colCity"
                        label="City"
                        maxLength="40"
                        errors={errors}
                        register={register}
                    />
                </Col>
                <Col md={4}>
                    <FormInput
                        type="text"
                        id="colStateProvince"
                        label="State/Province"
                        maxLength="15"
                        errors={errors}
                        register={register}
                    />
                </Col>
                <Col md={4}>
                    <FormInput
                        type="text"
                        id="colZipPostalCode"
                        label="Zip Code"
                        maxLength="20"
                        errors={errors}
                        register={register}
                    />
                </Col>
            </Row>
            <FormInput type="text" id="colCountry" label="Country" maxLength="45" errors={errors} register={register} />
            <Row className="mb-3">
                <b>Start of Fiscal Year (MM/DD):</b>
                <Col md={6}>
                    <HyperDatepicker
                        id="colStartOfFiscalYear"
                        value={selectedDate}
                        inputClass="form-control-light"
                        dateFormat="MM/dd"
                        onChange={(date) => {
                            onDateChange(date);
                        }}
                        register={register}
                    />
                </Col>
            </Row>
            <FormInput
                type="text"
                id="colWebAddress"
                label="Website"
                maxLength="250"
                errors={errors}
                register={register}
            />
            <FormInput type="text" id="colContactNo" label="Phone" errors={errors} register={register} />
            <FormInput
                type="text"
                id="colFacebookUrl"
                label="Facebook"
                maxLength="250"
                errors={errors}
                register={register}
            />
            <FormInput
                type="text"
                id="colTwitterUrl"
                maxLength="250"
                label="Twitter"
                errors={errors}
                register={register}
            />
            <FormInput
                type="text"
                id="colLinkedinUrl"
                maxLength="250"
                label="LinkedIn"
                errors={errors}
                register={register}
            />
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Update" variant="primary" type="submit" />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default AddressDefaultModal;
