// @flow
import React from 'react';
import { Row, Card } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions, notificationActions } from '../../../reducers/actions';
import { useNotificationContex } from '../../../contexts/NotificationContext';
import TreeView from '../../../components/treeview';

const ReplaceAssetModal = (props) => {
    const { modalDispatch } = useModalContext();
    const { notificationDispatch } = useNotificationContex();
    const treeData = {
        data: {
            core: {
                data: [
                    {
                        text: 'SYSTEM CLOUD',
                        icon: 'jstree-icon jstree-themeicon mdi mdi-cloud jstree-themeicon-custom',

                        children: [
                            {
                                text: 'PRIMARY',
                                icon: 'jstree-icon jstree-themeicon jstree-themeicon-custom fa-fa-folder-brand-closed',
                                children: [
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 10542,
                                        children: false,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: 'CAPTIONEDD',
                                        can_be_deleted: true,
                                        id: 14431,
                                        text: 'CAPTIONEDD',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 10543,
                                        children: false,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: 'CAPTIONEDD 1',
                                        can_be_deleted: true,
                                        id: 14432,
                                        text: 'CAPTIONEDD 1',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 10564,
                                        children: false,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: 'CAPTIONEDD 2',
                                        can_be_deleted: true,
                                        id: 14455,
                                        text: 'CAPTIONEDD 2',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 5,
                                        user_id: 2433,
                                        cp_content_id: 10599,
                                        children: false,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: 'CAPTIONEDD 3',
                                        can_be_deleted: true,
                                        id: 14497,
                                        text: 'CAPTIONEDD 3',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 10600,
                                        children: false,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: 'CAPTIONEDD 4',
                                        can_be_deleted: true,
                                        id: 14498,
                                        text: 'CAPTIONEDD 4',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 10606,
                                        children: false,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: 'CAPTIONEDD 5',
                                        can_be_deleted: true,
                                        id: 14505,
                                        text: 'CAPTIONEDD 5',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 10609,
                                        children: false,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: 'CAPTIONEDD 6',
                                        can_be_deleted: true,
                                        id: 14508,
                                        text: 'CAPTIONEDD 6',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 10610,
                                        children: false,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: 'CAPTIONEDD 7',
                                        can_be_deleted: true,
                                        id: 14509,
                                        text: 'CAPTIONEDD 7',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 8926,
                                        children: false,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: 'CONTACT',
                                        can_be_deleted: true,
                                        id: 12514,
                                        text: 'CONTACT',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 5,
                                        user_id: 2125,
                                        cp_content_id: 8927,
                                        children: false,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: 'CONTACT 1',
                                        can_be_deleted: true,
                                        id: 12515,
                                        text: 'CONTACT 1',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 5,
                                        user_id: 2125,
                                        cp_content_id: 6593,
                                        children: false,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: 'DOWNLOAD',
                                        can_be_deleted: true,
                                        id: 9346,
                                        text: 'DOWNLOAD',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 6605,
                                        children: false,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: "NEHA'S TEST",
                                        can_be_deleted: true,
                                        id: 9358,
                                        text: "NEHA'S TEST",
                                    },
                                    {
                                        fk_palette_id: 62,
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: null,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: 'PRIMARY - 2021',
                                        can_be_deleted: false,
                                        id: 8638,
                                        text: 'PRIMARY - 2021',
                                        icon: 'jstree-icon jstree-themeicon fa-fa-folder-palette-closed jstree-themeicon-custom',
                                        children: [
                                            {
                                                fk_palette_id: null,
                                                icon: 'glyphicon glyphicon-text-background-cp',
                                                dateModified: {
                                                    nanos: 0,
                                                },
                                                brand_id: null,
                                                permissionby_id: 1,
                                                dateCreated: {
                                                    nanos: 0,
                                                },
                                                cp_root_node_id: 1,
                                                role_id: 1,
                                                user_id: 2124,
                                                cp_content_id: 6649,
                                                children: false,
                                                parent_id: 8638,
                                                org_id: 55,
                                                name: '2021',
                                                can_be_deleted: true,
                                                id: 9406,
                                                text: '2021',
                                            },
                                            {
                                                fk_palette_id: null,
                                                icon: 'mdi mdi-folder-lock',
                                                dateModified: {
                                                    nanos: 0,
                                                },
                                                brand_id: null,
                                                permissionby_id: 1,
                                                dateCreated: {
                                                    nanos: 0,
                                                },
                                                cp_root_node_id: 1,
                                                role_id: 1,
                                                user_id: 2124,
                                                cp_content_id: null,
                                                children: false,
                                                parent_id: 8638,
                                                org_id: 55,
                                                name: 'CUSTOMS',
                                                can_be_deleted: false,
                                                id: 8649,
                                                text: 'CUSTOMS',
                                            },
                                            {
                                                fk_palette_id: null,
                                                icon: 'glyphicon glyphicon-text-background-cp ',
                                                dateModified: {
                                                    nanos: 0,
                                                },
                                                brand_id: null,
                                                permissionby_id: 1,
                                                dateCreated: {
                                                    nanos: 0,
                                                },
                                                cp_root_node_id: 1,
                                                role_id: 1,
                                                user_id: 2124,
                                                cp_content_id: 6592,
                                                children: false,
                                                parent_id: 8638,
                                                org_id: 55,
                                                name: 'DOWNLOAD',
                                                can_be_deleted: true,
                                                id: 9345,
                                                text: 'DOWNLOAD',
                                            },
                                            {
                                                fk_palette_id: null,
                                                icon: 'glyphicon glyphicon-text-background-cp',
                                                dateModified: {
                                                    nanos: 0,
                                                },
                                                brand_id: null,
                                                permissionby_id: 1,
                                                dateCreated: {
                                                    nanos: 0,
                                                },
                                                cp_root_node_id: 1,
                                                role_id: 1,
                                                user_id: 2124,
                                                cp_content_id: 6677,
                                                children: false,
                                                parent_id: 8638,
                                                org_id: 55,
                                                name: 'SINGLE',
                                                can_be_deleted: true,
                                                id: 9488,
                                                text: 'SINGLE',
                                            },
                                            {
                                                fk_palette_id: null,
                                                icon: 'fa-fa-folder-system-template',
                                                dateModified: {
                                                    nanos: 0,
                                                },
                                                brand_id: null,
                                                permissionby_id: 1,
                                                dateCreated: {
                                                    nanos: 0,
                                                },
                                                cp_root_node_id: 1,
                                                role_id: 1,
                                                user_id: 2124,
                                                cp_content_id: null,
                                                children: true,
                                                parent_id: 8638,
                                                org_id: 55,
                                                name: 'TEMPLATES',
                                                can_be_deleted: false,
                                                id: 8639,
                                                text: 'TEMPLATES',
                                            },
                                        ],
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 9895,
                                        children: false,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: 'STANDARD - PURPLE',
                                        can_be_deleted: true,
                                        id: 13680,
                                        text: 'STANDARD - PURPLE',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: {
                                            nanos: 0,
                                        },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: {
                                            nanos: 0,
                                        },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 9855,
                                        children: false,
                                        parent_id: 8637,
                                        org_id: 55,
                                        name: 'STG',
                                        can_be_deleted: true,
                                        id: 13638,
                                        text: 'STG',
                                    },
                                ],
                            },
                            {
                                text: 'AUG 20 2021 18:4:8 TESTIM',
                                icon: 'jstree-icon jstree-themeicon mdi mdi-folder jstree-themeicon-custom',
                                children: [
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: { nanos: 0 },
                                        brand_id: null,
                                        permissionby_id: 2,
                                        dateCreated: { nanos: 0 },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 9275,
                                        children: false,
                                        parent_id: 12910,
                                        org_id: 55,
                                        name: 'QUOTE - WHITE',
                                        can_be_deleted: true,
                                        id: 12911,
                                        text: 'QUOTE - WHITE',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: { nanos: 0 },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: { nanos: 0 },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 9276,
                                        children: false,
                                        parent_id: 12910,
                                        org_id: 55,
                                        name: 'STANDARD - BLUE',
                                        can_be_deleted: true,
                                        id: 12912,
                                        text: 'STANDARD - BLUE',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: { nanos: 0 },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: { nanos: 0 },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 9277,
                                        children: false,
                                        parent_id: 12910,
                                        org_id: 55,
                                        name: 'STANDARD - GREEN',
                                        can_be_deleted: true,
                                        id: 12913,
                                        text: 'STANDARD - GREEN',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: { nanos: 0 },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: { nanos: 0 },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 9278,
                                        children: false,
                                        parent_id: 12910,
                                        org_id: 55,
                                        name: 'STANDARD - ORANGE',
                                        can_be_deleted: true,
                                        id: 12914,
                                        text: 'STANDARD - ORANGE',
                                    },
                                    {
                                        fk_palette_id: null,
                                        icon: 'glyphicon glyphicon-text-background-cp',
                                        dateModified: { nanos: 0 },
                                        brand_id: null,
                                        permissionby_id: 1,
                                        dateCreated: { nanos: 0 },
                                        cp_root_node_id: 1,
                                        role_id: 1,
                                        user_id: 2124,
                                        cp_content_id: 9279,
                                        children: false,
                                        parent_id: 12910,
                                        org_id: 55,
                                        name: 'STANDARD - PURPLE',
                                        can_be_deleted: true,
                                        id: 12915,
                                        text: 'STANDARD - PURPLE',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
        selected: [],
    };
    return (
        <Row>
            <Card>
                <Card.Body>
                    <TreeView treeData={treeData} />
                </Card.Body>
            </Card>
            <div className="button-list">
                <ButtonElement
                    name="Replace"
                    variant="outline-primary"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                            payload: {
                                modalAction: notificationDispatch({
                                    type: notificationActions.OPEN_NOTIFICATION,
                                    payload: {
                                        role: 'success',
                                        title: 'Well Done!',
                                        iconClass: 'dripicons-checkmark h1',
                                        className: 'icon',
                                        message:
                                            'Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam.',
                                    },
                                }),
                            },
                        })
                    }
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </Row>
    );
};

export default ReplaceAssetModal;
