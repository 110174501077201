// @flow
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const AddOrChangeSlide = (props) => {
    const { modalDispatch } = useModalContext();
    const [sliderList, setSliderList] = useState([]);

    const { details } = useSelector((state) => ({
        details: state?.auth?.me,
    }));

    useEffect(() => sliderViewList(), []);

    const sliderViewList = () => {
        let items = parseDomFromString();
        let list = [];
        for (let index = 0; index < items?.length; index++)
            list.push({
                index: index,
                url: `${process?.env?.REACT_APP_S3_URL}/Org${details?.organaisationId}/assets/slider-view/SliderView${
                    index + 1
                }.png`,
            });
        setSliderList(list);
    };

    const slideAddOrReplace = (item) => {
        let items = parseDomFromString();
        if (props?.data?.isReplace) {
            items[item?.index].classList.add('carousel-item');
            items[item?.index].classList.add('active');
            let activeElement = document?.querySelector('.standard-template .carousel-inner .item.active');
            document.querySelector('.standard-template .carousel-inner').append(activeElement, items[item?.index]);
            activeElement?.remove();
        } else {
            document.querySelector('.standard-template .carousel-inner .item.active').classList.remove('active');
            if (!items[item?.index].classList.contains('carousel-item'))
                items[item?.index].classList.add('carousel-item');
            items[item?.index].classList.add('active');
            document.querySelector('.standard-template .carousel-inner').appendChild(items[item?.index]);
        }
        props?.data?.slideModified();
        modalDispatch({ type: modalActions.CLOSE_MODAL });
    };

    const parseDomFromString = () => {
        let doc = new DOMParser().parseFromString(props?.data?.original, 'text/html');
        return doc.querySelectorAll('.item:not(.active)');
    };

    return (
        <>
            <div className="add-change-slide">
                {sliderList?.map((item) => (
                    <img
                        key={item?.index}
                        className="m-2"
                        alt="image"
                        src={item?.url}
                        onClick={() => slideAddOrReplace(item)}
                    />
                ))}
            </div>

            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() => modalDispatch({ type: modalActions.CLOSE_MODAL })}
                />
            </div>
        </>
    );
};

export default AddOrChangeSlide;
