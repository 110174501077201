// @flow
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';

const ImpactList = (props, row) => {
    return (
        <>
            <ListGroup.Item as="li" className="custom-border custom-list-group list-group-item-action m-0 ">
                <Col md={12} xs={12}>
                    <Row className="p-1">
                        <Col xs={2} md={2} className="m-auto">
                            <i className="mdi mdi-drag-vertical"></i>
                        </Col>
                        <Col xs={10} md={10} className="m-auto">
                            <span>
                                {props?.index?.colImpactFirstName
                                    ? props?.index?.colImpactFirstName +
                                      ' ' +
                                      (props.index?.colImpactLastName ? props.index?.colImpactLastName : '')
                                    : props?.index?.colImpactTitleProgramName
                                    ? props?.index?.colImpactTitleProgramName
                                    : `Un-named Impact ${props.index?.pkFundImpactId}`}
                            </span>
                        </Col>
                    </Row>
                </Col>
            </ListGroup.Item>
        </>
    );
};

export default ImpactList;
