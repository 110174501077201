// @flow
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// components
import { FormInput } from '../../../../components/form/input';
import Table from '../../../../components/table/Table';
import ButtonElement from '../../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../../constants/table';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';
import service from '../../../../constants/service.constant';
import { impactTypeGetList, impactTypeDelete, impactTypeUpdate, impactTypeReset } from '@ovrture/react-redux';
import { dateTimeFormat } from '../../../../utility';

const ImpactTypeModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [data, setData] = useState([]);
    const [updatedAt, setUpdatedAt] = useState();
    const dispatch = useDispatch();
    const { list, deleted, update, orgFetch, loading } = useSelector((state) => ({
        list: state?.impactType?.list,
        deleted: state?.impactType?.deleted,
        update: state?.impactType?.update,
        orgFetch: state?.organization?.fetch,
        loading: state?.impactType?.loading,
    }));
    useEffect(() => {
        if (list?.data) {
            list?.data?.map((item) => {
                setUpdatedAt(dateTimeFormat(item?.colUpdatedAt));
            });
        }
    }, [list]);
    useEffect(() => {
        if (list?.data) setData(list.data);
        if (deleted) {
            deleted.code === service.accepted && dispatch(impactTypeGetList({ orgId: orgFetch?.data?.id }));
        }
        if (update) {
            dispatch(impactTypeReset());
        }
    }, [list, deleted, update]);

    const { register } = useForm({});
    const toggle = () => props?.hideModal();
    const ImpactType = ({ row }) => {
        return (
            <FormInput
                type="text"
                label="Type"
                className="mb-0"
                id={'impactType' + row.id}
                register={register}
                defaultValue={row.original.colImpactName}
                onKeyUp={(e) => (row.original.colImpactName = e.target.value)}
                feedback="hide"
                floatinglabel="false"
            />
        );
    };
    const UploaderImpactCode = ({ row }) => {
        return (
            <FormInput
                type="text"
                label="Code"
                className="mb-0"
                id={'uploaderImpactCode' + row.id}
                register={register}
                defaultValue={row.original.colImpactKey}
                onKeyUp={(e) => (row.original.colImpactKey = e.target.value)}
                feedback="hide"
                floatinglabel="false"
            />
        );
    };
    const Action = ({ row }) => {
        return (
            <ButtonElement
                icon={<i className="mdi mdi-delete"></i>}
                className="action-icon hover-danger"
                tooltip="Delete"
                onClick={() =>
                    dispatch(
                        impactTypeDelete({
                            orgId: row.original.fkOrgId,
                            pkImpactTypeId: row.original.pkImpactTypeId,
                        })
                    )
                }
            />
        );
    };

    return (
        <>
            {list?.data?.length > 0 && <p className="text-end custom-font mb-1">Last Edited: {updatedAt}</p>}
            <Card>
                <Table
                    columns={TableColumn.editElent.reportData.impactType([ImpactType, UploaderImpactCode, Action])}
                    data={data}
                    pageSize={list?.data?.size}
                    pageNumber={list?.data?.number}
                    totalElements={list?.data?.totalElements}
                    totalPages={list?.data?.totalPages}
                    paginationDispatch={impactTypeGetList}
                    sizePerPageList={TableColumn.sizePerPageList(data)}
                    isSortable={true}
                    pagination={false}
                    isSearchable={false}
                    isFilter={false}
                    isSelectable={false}
                    loading={loading}
                    theadClass={TableColumn.table_light}
                    tableClass="impact-type-modal"
                    tableParentClass="height-fixed"
                    customPaginationProps={{
                        orgId: orgFetch?.data?.id,
                        sort: ['colImpactName'],
                    }}
                />
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Add New"
                    variant="outline-primary"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.OPEN_MODAL,
                            payload: {
                                modalAction: (e) =>
                                    modalDispatch({
                                        type: modalActions.CLOSE_MODAL,
                                    }),
                                modalVariant: 'primary',
                                modalDataKey: ['platformAdmin', 'newImpactType'],
                                data: {
                                    title: orgFetch?.data?.name + ' > Report Data > Impact Type ',
                                    fkOrgId: orgFetch?.data?.id,
                                },
                            },
                        })
                    }
                />
                <ButtonElement
                    name="Update"
                    variant="outline-primary"
                    disabled={data?.length <= 0 ? true : false}
                    onClick={() => {
                        data.map((item) => {
                            item.orgId = item.fkOrgId;
                        });
                        console.log(data);
                        dispatch(impactTypeUpdate(data));
                    }}
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </>
    );
};

export default ImpactTypeModal;
