import React, { createContext, useReducer, useContext } from 'react';
import { ModalReducer, modalIntialState } from '../reducers/ModalReducer';
import { createPortal } from 'react-dom';
import Modals from '../components/modal/Modals';
import { modalActions } from './../reducers/actions';
export const ModalContext = createContext();

const ModalProvider = (props) => {
    const [modal, modalDispatch] = useReducer(ModalReducer, modalIntialState);
    const handleModal = (props) => {
        let propsData = {
            ...props,
            type: props.type === 'open' ? modalActions.OPEN_MODAL : !props.type ? null : 'close',
        };
        modalDispatch({
            ...propsData,
        });
    };
    let modalData = { modal, modalDispatch, handleModal };
    return (
        <ModalContext.Provider value={modalData}>
            {props.children}
            {createPortal(<Modals />, document.body)}
        </ModalContext.Provider>
    );
};

export default ModalProvider;

export function useModalContext() {
    const context = useContext(ModalContext);
    if (context === undefined) {
        throw new Error('useModalContext must be used within a ModalProvider');
    }
    return context;
}
