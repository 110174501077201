// @flow
import React, { useRef, useEffect, forwardRef, useState } from 'react';
import {
    useTable,
    useSortBy,
    usePagination,
    useRowSelect,
    useGlobalFilter,
    useFilters,
    useAsyncDebounce,
    useExpanded,
    useColumnOrder,
} from 'react-table';
import classNames from 'classnames';
// components
import Pagination from './Pagination';
import { Col, Row, Card, InputGroup, Form } from 'react-bootstrap';
import TableFooterAction from './TableFooterAction';
import ButtonElement from '../../components/button/ButtonElement';
import { useDispatch } from 'react-redux';
import { getHistoryData, getRetainHistory, setHistoryData } from '../../helpers/retainHistory';

let searchText = '';
// Define a default UI for filtering
const GlobalFilter = ({
    globalFilter,
    setGlobalFilter,
    searchData,
    loading,
    getSearchValue,
    setTempCheckedList,
    customPaginationProps,
    searchKey,
    tableId,
}) => {
    const [value, setValue] = React.useState(globalFilter);
    const [flag, setFlag] = React.useState(0);

    useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    useEffect(() => {
        if (loading) setFlag(0);
    }, [loading]);

    useEffect(() => {
        try {
            let historyData = getRetainHistory(tableId);
            if (historyData && searchKey) {
                Object.keys(historyData).map((key) => {
                    if (key === searchKey && historyData[key]) {
                        setValue(historyData[key]);
                    }
                });
            }
        } catch (e) {}
    }, []);

    return (
        <InputGroup className="input-group-merge input-group-flush input-group">
            <InputGroup.Text>
                <i className="mdi mdi-magnify"></i>
            </InputGroup.Text>
            <Form.Control
                value={value || ''}
                // placeholder={`Search for ${searchData && searchData}`}
                placeholder={`Search...`}
                onChange={(e) => {
                    searchText = e.target.value;
                    setValue(e.target.value);
                    if (flag === 0) {
                        setFlag(1);
                        setTimeout(() => {
                            getSearchValue(e.target.value);
                            setTempCheckedList && setTempCheckedList([]);
                            setFlag(0);
                            let existingObj = getHistoryData() || {};
                            existingObj[tableId] = customPaginationProps;
                            setHistoryData(JSON.stringify(existingObj));
                            let historyDataObj = getHistoryData();
                            let historyData = historyDataObj[tableId];
                            historyData.page = 0;
                            Object.keys(historyData).map((key) => {
                                if (key === searchKey) {
                                    historyData[key] = e.target.value;
                                }
                            });
                            setHistoryData(JSON.stringify(historyDataObj));
                        }, 1500);
                    }
                }}
            />
        </InputGroup>
    );
};

let oldCheckboxItem = null;
let lowerIndex = null;
let upperIndex = null;
let targetSelection = null;
let tempPageCheckedList = [];
let tempPageList = null;
let tempNumberOfElements = 0;
let isTempCleared = false;

const IndeterminateCheckbox = forwardRef(
    (
        {
            shiftSingleItem,
            historyOff,
            setTempCheckedList,
            tempCheckedList,
            indeterminate,
            pageNumber,
            listData,
            setListData = false,
            updateSelectedValue = false,
            data,
            isHeader,
            enable,
            row,
            tableId,
            isDisable,
            ...rest
        },
        ref
    ) => {
        // Gmail feature
        tempPageList = { pageNumber: pageNumber, data: data };
        if (tempCheckedList?.length > 0) tempPageCheckedList = tempCheckedList;
        tempNumberOfElements = data.length;
        const handleTempList = (isChecked, original, pageNumber) => {
            if (original) {
                let isExist =
                    tempCheckedList?.length > 0
                        ? tempCheckedList.find((f) => f.id === original.id && f.pageNumber === pageNumber)
                        : null;
                if (isChecked && !isExist) {
                    let array = tempCheckedList;
                    array.push({
                        id: original?.id,
                        pageNumber: pageNumber,
                        numberOfElements: tempNumberOfElements,
                        orgId: original?.id,
                        active: original?.isActive,
                    });
                    setTempCheckedList && setTempCheckedList([...array]);
                } else if (!isChecked && isExist) {
                    tempCheckedList.splice(
                        tempCheckedList.findIndex((f) => f.id === original.id && f.pageNumber === pageNumber),
                        1
                    );
                    setTempCheckedList([...tempCheckedList]);
                }
            }
        };

        const handleTempPageList = (checked, tempPageList) => {
            let array = tempPageCheckedList;
            tempPageList?.data?.map((item) => {
                if (checked && !array.find((f) => f.id === item.id && f.pageNumber === tempPageList.pageNumber))
                    array.push({
                        id: item.id,
                        pageNumber: tempPageList.pageNumber,
                        numberOfElements: tempNumberOfElements,
                        orgId: item?.id,
                        active: item?.isActive,
                    });
                else if (!checked) {
                    let index = array.findIndex((f) => f.id === item.id && f.pageNumber === tempPageList.pageNumber);
                    array.splice(index, 1);
                }
            });
            setTempCheckedList && setTempCheckedList([...array]);
        };

        var shift = !!window?.event?.shiftKey;
        if (shift && row?.index >= lowerIndex && row?.index <= upperIndex && !isDisable) {
            let array = [];
            for (let index = lowerIndex; index <= upperIndex; index++) {
                array.push(data[index]);
                if (row?.index === index && !row?.isSelected && targetSelection) {
                    row?.toggleRowSelected(targetSelection);
                } else if (row?.index === index && row?.isSelected && !targetSelection) {
                    row?.toggleRowSelected(targetSelection);
                }
            }
        } else {
            // Gmail feature
            if (!historyOff) {
                if (
                    row &&
                    tempCheckedList?.length > 0 &&
                    tempCheckedList.find((f) => f.id === row?.original?.id && f?.pageNumber === pageNumber)
                )
                    row?.toggleRowSelected(true);
            }
        }

        if (historyOff) {
            if (row?.isSelected) {
                if (shiftSingleItem && row?.original?.shiftSelected === false) {
                    row?.toggleRowSelected(false);
                } else {
                    let index = tempCheckedList?.findIndex((item) => item.id === row?.original?.id);
                    if (index === -1) {
                        tempCheckedList.push({
                            id: row?.original?.id,
                            pageNumber: pageNumber,
                            numberOfElements: tempNumberOfElements,
                            orgId: row?.original?.id,
                            active: row?.original?.isActive,
                        });
                    }
                }
            } else {
                if (shiftSingleItem && row?.original?.shiftSelected === true) {
                    row?.toggleRowSelected(true);
                }
                tempCheckedList?.forEach((item, index) => {
                    if (item.id === row?.original?.id) {
                        tempCheckedList.splice(index, 1);
                    }
                });
            }
        }

        // if (row?.isSelected) {
        //     let index = listArray?.findIndex((item) => item.orgId === row?.original?.id);

        //     if (index === -1) {
        //         listArray.push({ orgId: row?.original?.id, active: row?.original?.isActive });
        //     }
        // } else {
        //     listArray?.forEach((item, index) => {
        //         if (item.orgId === row?.original?.id) {
        //             listArray.splice(index, 1);
        //         }
        //     });
        // }

        const defaultRef = useRef();
        const resolvedRef = ref || defaultRef;
        useEffect(() => {
            // enable !== null && row?.toggleRowSelected(false);
            // resolvedRef.current.indeterminate = indeterminate;
            // setListData && setListData(listArray);
            //setTempCheckedList && setTempCheckedList(tempCheckedList);
            updateSelectedValue && updateSelectedValue(tempCheckedList);
        }, [resolvedRef, indeterminate, row?.isSelected]);

        const handleChange = (event, original) => {
            // Gmail feature
            if (isHeader) handleTempPageList(event.target.checked, tempPageList);
            else handleTempList(event.target.checked, original, pageNumber);
            lowerIndex = null;
            upperIndex = null;
            targetSelection = null;
            var shift = !!window?.event?.shiftKey;
            if (shift && oldCheckboxItem?.orgId) {
                var index1 = data.indexOf(data.filter((f) => f.id === oldCheckboxItem.orgId)[0]);
                var index2 = data.indexOf(data.filter((f) => f.id === original?.id)[0]);
                lowerIndex = index1 < index2 ? index1 : index2;
                upperIndex = index2 > index1 ? index2 : index1;
                targetSelection = event.target.checked;
                oldCheckboxItem = { orgId: original?.id, active: event.target.checked };
                let array = [];
                for (let index = lowerIndex + 1; index <= upperIndex; index++) array.push(data[index]);
                handleTempPageList(event.target.checked, { pageNumber: pageNumber, data: array });
            } else oldCheckboxItem = { orgId: original?.id, active: event.target.checked };
        };

        return (
            <>
                <div className="form-check">
                    <input
                        onClick={(event) => handleChange(event, row?.original)}
                        type="checkbox"
                        className="form-check-input border-secondary "
                        data-selected={row?.isSelected}
                        data-selected-id={row?.original?.id}
                        data-selected-tableid={tableId}
                        ref={resolvedRef}
                        {...rest}
                    />
                    <label htmlFor="form-check-input" className="form-check-label"></label>
                </div>
            </>
        );
    }
);

const Table = (props) => {
    const dispatch = useDispatch();
    const [colspan, setcolspan] = useState();
    const tableId = props['tableId'] || '';
    const onPaginationPageChangeEvent = props['onPaginationPageChangeEvent'] || null;
    const paginationDisplayDrowpdownHide = props['paginationDisplayDrowpdownHide'] || false;
    const footerPaginationOff = props['footerPaginationOff'] || false;
    const searchKey = props['searchKey'] || '';
    const tempPageList_ = props['tempPageList'] || null;
    const historyDisable = props['historyDisable'] || false;
    const shiftSingleItem = props['shiftSingleItem'] || false;
    const isSearchable = props['isSearchable'] || false;
    const isSortable = props['isSortable'] || false;
    const setSortObj = props['setSortObj'] || null;
    const pagination = props['pagination'] || false;
    const isSelectable = props['isSelectable'] || false;
    const isExpandable = props['isExpandable'] || false;
    const isFilter = props['isFilter'] || false;
    const TableFilter = props['tableFilter'] || false;
    const listData = props['listData'] || false;
    const setListData = props['setListData'] || null;
    const totalElements = props['totalElements'] || null;
    const pageNumber = props['pageNumber'];
    const totalPages = props['totalPages'] || null;
    const pageSizes = props['pageSizes'] || null;
    const paginationDispatch = props['paginationDispatch'] || null;
    const updateSelectedValue = props['updateSelectedValue'] || null;
    const loading = props['loading'] || null;
    const tempCheckedList = props['resetSelection'] || null;
    const setTempCheckedList = props['setResetSelection'] || null;
    const trClass = props['trClass'] || null;
    const tableFooterActionProps = props['tableFooterActionProps'] || null;
    const getSearchValue = props['getSearchValue'] || null;
    let customPaginationProps = props['customPaginationProps'] || null;
    const enable = props['enable'] || null;
    const handleSelectAll = props['handleSelectAll'] || null;
    const isSelectAll = props['isSelectAll'] || null;
    const haveNoDataErrorMsg = props['haveNoDataErrorMsg'] || false;
    const [selectedValue, setSelectedValue] = useState();
    if (tempCheckedList?.length === 0) tempPageCheckedList = [];
    if (tempPageList_) tempPageList = tempPageList_;

    const handleChecks = (d) => {
        if (d) {
            setSelectedValue([...d]);
            updateSelectedValue(d);
        }
    };

    // Gmail feature
    const groupArrayOfObjects = (list, key) => {
        return list.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    const isAllCheckboxSelectedOfAnyPage = () => {
        let isCheckboxAllSelect = false;
        if (tempCheckedList?.length > 0) {
            let checkedPagesList = groupArrayOfObjects(tempCheckedList, 'pageNumber');
            Object.keys(checkedPagesList).map(function (key) {
                if (
                    !isCheckboxAllSelect &&
                    tempCheckedList.filter((f) => f.pageNumber === checkedPagesList[key][0].pageNumber).length ===
                        checkedPagesList[key][0]?.numberOfElements
                ) {
                    isCheckboxAllSelect = true;
                }
            });
        }
        return isCheckboxAllSelect;
    };

    const {
        page,
        rows,
        globalFilter,
        setGlobalFilter,
        getTableProps,
        headerGroups: headerGroupList,
        getTableBodyProps,
        prepareRow,
        state: {},
    } = useTable(
        {
            columns: props['columns'],
            data: props['data'],
            initialState: { pageSize: props['pageSize'] || 100 },
        },
        useColumnOrder,
        isSearchable && useGlobalFilter,
        useFilters,
        useSortBy,
        isSortable && useSortBy,
        isExpandable && useExpanded,
        pagination && usePagination,
        isSelectable && useRowSelect,
        usePagination,
        (hooks) => {
            isSelectable &&
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllPageRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox
                                    shiftSingleItem={shiftSingleItem}
                                    historyOff={historyDisable}
                                    setTempCheckedList={setTempCheckedList}
                                    enable={enable}
                                    pageNumber={pageNumber}
                                    data={props['data']}
                                    isHeader={true}
                                    {...getToggleAllPageRowsSelectedProps()}
                                />
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox
                                    shiftSingleItem={shiftSingleItem}
                                    historyOff={historyDisable}
                                    setTempCheckedList={setTempCheckedList}
                                    tempCheckedList={tempCheckedList}
                                    enable={enable}
                                    pageNumber={pageNumber}
                                    listData={listData}
                                    setListData={setListData}
                                    updateSelectedValue={handleChecks}
                                    data={props['data']}
                                    row={row}
                                    tableId={tableId}
                                    {...row.getToggleRowSelectedProps()}
                                    isDisable={
                                        trClass?.rightArry?.filter((item) => item?.id === row?.original?.id).length > 0
                                    }
                                />
                            </div>
                        ),
                    },
                    ...columns,
                ]);

            isExpandable &&
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        // Build our expander column
                        id: 'expander', // Make sure it has an ID
                        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                            <span {...getToggleAllRowsExpandedProps()}>{isAllRowsExpanded ? '-' : '+'}</span>
                        ),
                        Cell: ({ row }) =>
                            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                            // to build the toggle for expanding a row
                            row.canExpand ? (
                                <span
                                    {...row.getToggleRowExpandedProps({
                                        style: {
                                            // We can even use the row.depth property
                                            // and paddingLeft to indicate the depth
                                            // of the row
                                            paddingLeft: `${row.depth * 2}rem`,
                                        },
                                    })}>
                                    {row.isExpanded ? '-' : '+'}
                                </span>
                            ) : null,
                    },
                    ...columns,
                ]);
        }
    );

    const [headerGroups, setHeaderGrpups] = useState(headerGroupList);

    let TableRow = pagination ? page : rows;

    useEffect(() => {
        setcolspan(headerGroups[0].headers.length);
        handleSort();
    }, [colspan]);

    const handleSort = (val) => {
        try {
            let historyData = getRetainHistory(tableId);
            if (historyData) {
                historyData?.sortObj?.map((so) => {
                    if (so?.sort?.includes(',')) {
                        let sortArray = so?.sort?.split(',');
                        let sort = sortArray[0];
                        let order = sortArray[1];
                        headerGroups[0]?.headers?.forEach((header) => {
                            if (header.id === sort && order === 'desc') {
                                header.isSortedDesc = true;
                            } else if (header.id === sort && order === 'asc') {
                                header.isSortedDesc = false;
                            }
                        });
                    }
                });
            }
        } catch (e) {}
        if (isSortable && setSortObj && val) {
            headerGroups[0]?.headers?.forEach((header) => {
                if (header.Header === val?.Header) {
                    header.isSortedDesc = !header.isSortedDesc;
                }
            });
            setHeaderGrpups([...headerGroups]);
            let sortObj = [];
            const sortedItems = headerGroups[0].headers?.filter((el) => el.sort);
            sortedItems?.forEach((item) => {
                if (item.isSortedDesc === false || item.isSortedDesc) {
                    sortObj.push({
                        sort: `${item?.customAccessor ? item?.customAccessor : item.id},${
                            item.isSortedDesc ? 'desc' : 'asc'
                        }`,
                    });
                }
            });
            if (sortObj?.length > 0) setSortObj(sortObj);
            customPaginationProps.sortObj = sortObj;
            if (sortObj.length > 0) {
                setTempCheckedList && setTempCheckedList([]);
                dispatch(
                    paginationDispatch({
                        isAnd: true,
                        page: pageNumber,
                        size: pageSizes,
                        ...customPaginationProps,
                    })
                );
                let existingObj = getHistoryData() || {};
                existingObj[tableId] = {
                    isAnd: true,
                    page: pageNumber,
                    size: pageSizes,
                    ...customPaginationProps,
                };
                setHistoryData(JSON.stringify(existingObj));
            }
        }
    };

    let headerList = headerGroupList;
    if (setSortObj) headerList = headerGroups;

    return (
        <>
            {(isSearchable || isFilter) && (
                <Card.Header>
                    <Row className="align-items-center justify-content-between">
                        {isSearchable && (
                            <Col className="col-sm col-8 mb-sm-0 mb-2">
                                <GlobalFilter
                                    globalFilter={globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                    searchData={props['searchData']}
                                    loading={props.loading}
                                    getSearchValue={getSearchValue}
                                    setTempCheckedList={setTempCheckedList}
                                    customPaginationProps={{
                                        isAnd: true,
                                        page: pageNumber,
                                        size: pageSizes,
                                        ...customPaginationProps,
                                    }}
                                    searchKey={searchKey}
                                    tableId={tableId}
                                />
                            </Col>
                        )}
                        {isFilter && (
                            <Col xs="auto" className="col-12 col-sm-auto d-sm-block d-flex justify-content-end">
                                {TableFilter}
                            </Col>
                        )}
                    </Row>
                </Card.Header>
            )}
            <Row>
                <Col md={12}>
                    {/* Gmail feature */}
                    {!historyDisable && isAllCheckboxSelectedOfAnyPage() && totalPages > 1 && (
                        <div className="text-center mt-3">
                            {isSelectAll ? (
                                <>
                                    <span className="me-2">{`All ${totalElements} rows are selected.`} </span>
                                    <ButtonElement
                                        as="button"
                                        name={`Clear selection`}
                                        variant="outline-primary"
                                        onClick={() => {
                                            setTempCheckedList([]);
                                            handleSelectAll(false);
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    {' '}
                                    <span className="me-2">
                                        {`All ${tempCheckedList?.length} rows on this page are selected.`}{' '}
                                    </span>
                                    <ButtonElement
                                        as="button"
                                        name={`Select all ${totalElements - tempCheckedList?.length} remaining rows`}
                                        variant="outline-primary"
                                        onClick={() => handleSelectAll(true)}
                                    />
                                </>
                            )}
                        </div>
                    )}
                </Col>
            </Row>
            <Card.Body>
                <div
                    {...getTableProps()}
                    className={classNames(
                        'table-responsive',
                        props['tableParentClass'],
                        TableRow?.length === 0 && 'table-auto-height'
                    )}>
                    <table
                        {...getTableProps()}
                        className={classNames('table table-centered react-table', props['tableClass'])}>
                        <thead className={props['theadClass']}>
                            {headerList.map((headerGroup, i) => (
                                <tr key={i}>
                                    {headerGroup.headers.map((column, j) => {
                                        return (
                                            <th
                                                key={j}
                                                {...column.getHeaderProps(column.sort && column.getSortByToggleProps())}
                                                icon={column.tooltip}
                                                className={classNames({
                                                    sorting_desc: column.isSortedDesc === true,
                                                    sorting_asc: column.isSortedDesc === false,
                                                    sortable: column.sort === true,
                                                    disabled: TableRow?.length === 0,
                                                })}
                                                {...(column?.sort &&
                                                    setSortObj && { onClick: () => handleSort(column) })}>
                                                {column.render('Header')}
                                                {column.descriptor === true ? (
                                                    <span className="d-inline-block">
                                                        <ButtonElement
                                                            className="p-0"
                                                            icon={<i className="dripicons-information"></i>}
                                                            tooltip={column.descriptorMsg}
                                                        />
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        {loading === true ? (
                            <tbody></tbody>
                        ) : (
                            <tbody {...getTableBodyProps()}>
                                {props?.isAccessible === false ? (
                                    <tr>
                                        <td colSpan={colspan} className="text-center">
                                            <h5>Permission denied</h5>
                                        </td>
                                    </tr>
                                ) : TableRow?.length === 0 ? (
                                    <tr>
                                        <td colSpan={colspan} className="text-center">
                                            <h5>{haveNoDataErrorMsg || 'No Data Available.'}</h5>
                                        </td>
                                    </tr>
                                ) : (
                                    (TableRow || []).map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr
                                                key={i}
                                                {...row.getRowProps({
                                                    disabled:
                                                        trClass?.rightArry?.filter(
                                                            (item) => item?.id === row?.original?.id
                                                        ).length > 0
                                                            ? true
                                                            : false,
                                                })}>
                                                {row.cells.map((cell) => {
                                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                                })}
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        )}
                    </table>
                </div>
                {!footerPaginationOff && pagination && totalPages > 0 && totalElements >= 10 && (
                    <Pagination
                        pageSize={pageSizes}
                        totalElements={totalElements}
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        paginationDispatch={paginationDispatch}
                        customPaginationProps={customPaginationProps}
                        setTempCheckedList={setTempCheckedList}
                        tableId={tableId}
                        paginationDisplayDrowpdownHide={paginationDisplayDrowpdownHide}
                        onPaginationPageChangeEvent={onPaginationPageChangeEvent}
                    />
                )}
            </Card.Body>
            {tableFooterActionProps && (
                <TableFooterAction
                    {...tableFooterActionProps}
                    isSelectAll={isSelectAll}
                    totalElements={totalElements}
                    tempCheckedList={tempCheckedList}
                    isAllCheckboxSelectedOfAnyPage={isAllCheckboxSelectedOfAnyPage()}
                />
            )}
        </>
    );
};

export default Table;
