// @flow
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { FormInput } from '../../../components/form/input';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const PrintBridgeHeading = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(false);
    const {
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = useForm({});
    const values = watch();
    const onSubmit = (data) => {
        console.log(data);
    };
    const textChangeHandle = (e) => {
        if (values?.renameContentPanel === e.target.value) {
            setToggle(false);
        } else {
            setToggle(true);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <p>
                This function will let you print this Content Panel. Before printing, please provide the heading you
                would like to use.
            </p>
            <Row>
                <Col lg={12}>
                    <FormInput
                        type="text"
                        id="heading"
                        label="Heading"
                        maxLength="30"
                        errors={errors}
                        register={register}
                        onChange={(e) => textChangeHandle(e)}
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Print" variant="primary" type="submit" disabled={!toggle ? true : false} />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </form>
    );
};

export default PrintBridgeHeading;
