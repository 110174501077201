// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { getHistoryData, setHistoryData } from '../../helpers/retainHistory';

const sizePerPageList = [
    { text: 10, value: 10 },
    { text: 25, value: 25 },
    { text: 50, value: 50 },
    { text: 75, value: 75 },
    { text: 100, value: 100 },
];

const Pagination = ({
    pageSize,
    pageNumber,
    totalPages,
    paginationDispatch,
    customPaginationProps,
    customPagination,
    setTempCheckedList,
    tableId,
    paginationDisplayDrowpdownHide,
    onPaginationPageChangeEvent,
}) => {
    /**
     * pagination count , index
     */
    const dispatch = useDispatch();
    const [pageCount, setPageCount] = useState(totalPages);
    const [activePage, setActivePage] = useState(pageNumber + 1);

    useEffect(() => {
        setPageCount(totalPages);
        setActivePage(pageNumber + 1);
    }, [totalPages, pageNumber]);

    /**
     * get filter pages
     */
    const filterPages = useCallback(
        (visiblePages, totalPages) => {
            return visiblePages.filter((page) => page <= pageCount);
        },
        [pageCount]
    );

    /**
     * handle visible pages
     */
    const getVisiblePages = useCallback(
        (page, total) => {
            if (total < 7) {
                return filterPages([1, 2, 3, 4, 5, 6], total);
            } else {
                if (page % 5 >= 0 && page > 4 && page + 2 < total) {
                    return [1, page - 1, page, page + 1, total];
                } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
                    return [1, total - 3, total - 2, total - 1, total];
                } else {
                    return [1, 2, 3, 4, 5, total];
                }
            }
        },
        [filterPages]
    );

    /**
     * handle page change
     * @param page - current page
     * @returns
     */
    const changePage = (page, size, isScrollUpDisable, skiponPageinationEvent) => {
        !skiponPageinationEvent && onPaginationPageChangeEvent && onPaginationPageChangeEvent();
        const visiblePages = getVisiblePages(page, pageCount);
        setVisiblePages(filterPages(visiblePages, pageCount));
        setActivePage(page);
        if (customPagination) {
            paginationDispatch({
                ...customPaginationProps,
                isAnd: true,
                page: page - 1,
                size: size || pageSize,
            });
        } else {
            dispatch(
                paginationDispatch({
                    ...customPaginationProps,
                    isAnd: true,
                    page: page - 1,
                    size: size || pageSize,
                })
            );
        }
        let existingObj = getHistoryData() || {};
        existingObj[tableId] = {
            ...customPaginationProps,
            isAnd: true,
            page: page - 1,
            size: size || pageSize,
        };
        setHistoryData(JSON.stringify(existingObj));
        const ele = document.getElementsByClassName('modal-content');
        const tableElement = document.getElementsByClassName('table-scroll-top');
        if (!isScrollUpDisable)
            ele.length > 0
                ? ele[0].scrollIntoView({ block: 'start', behavior: 'smooth' })
                : tableElement.length > 0
                ? tableElement[0].scrollIntoView({ block: 'start', behavior: 'smooth' })
                : window.scrollTo(0, 0);
    };

    const handler = useCallback(debounce(changePage, 400), [pageCount]);

    useEffect(() => {
        const visiblePages = getVisiblePages(0, pageCount);
        setVisiblePages(visiblePages);
    }, [pageCount, getVisiblePages]);

    const [visiblePages, setVisiblePages] = useState(getVisiblePages(0, pageCount));

    const handleSetInput = (e) => {
        onPaginationPageChangeEvent && onPaginationPageChangeEvent();
        const val = e.target.value;
        const page = val && Number(val);
        if (page > totalPages) {
            return null;
        } else {
            setActivePage(val);
            if (page > 0 && page <= totalPages) {
                handler(page, pageSize);
            } else {
                handler(pageNumber + 1, pageSize);
            }
        }
    };

    return (
        <div className="d-lg-flex align-items-center text-center pagination-wrapper">
            {process.env.REACT_APP_TABLE_DISPLAY !== 'true' &&
                !paginationDisplayDrowpdownHide &&
                sizePerPageList.length > 0 && (
                    <div className="d-inline-block me-3">
                        <label className="me-1">Display:</label>
                        <select
                            value={pageSize}
                            onChange={(e) => {
                                changePage(1, Number(e.target.value), true, true);
                                setTempCheckedList && setTempCheckedList([]);
                            }}
                            className="form-select d-inline-block w-auto">
                            {(sizePerPageList || []).map((pageSize, index) => {
                                return (
                                    <option key={index} value={pageSize.value}>
                                        {pageSize.text}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                )}
            <span className="me-3 left">
                Page&nbsp;
                <strong>
                    {activePage} of {totalPages}
                </strong>
            </span>
            <span className="d-inline-block align-items-center text-sm-start text-center my-sm-0 my-2 center">
                <label>Go to page: </label>
                <input
                    type="number"
                    value={activePage}
                    min="1"
                    max={totalPages}
                    onChange={handleSetInput}
                    className="form-control goto-page-width ms-1 d-inline-block"
                />
            </span>

            <ul className="pagination pagination-rounded d-inline-flex ms-auto align-item-center mb-0 right">
                <li
                    key="prevpage"
                    className={classNames('page-item', 'paginate_button', 'previous', {
                        disabled: activePage === 1,
                    })}
                    onClick={() => {
                        if (activePage === 1) return;
                        changePage(activePage - 1);
                    }}>
                    <Link to="#" className="page-link">
                        <i className="mdi mdi-chevron-left"></i>
                    </Link>
                </li>
                {(visiblePages || []).map((page, index, array) => {
                    return array[index - 1] + 1 < page ? (
                        <React.Fragment key={page}>
                            <li className="page-item disabled d-none d-xl-inline-block">
                                <Link to="#" className="page-link">
                                    ...
                                </Link>
                            </li>
                            <li
                                className={classNames('page-item', 'd-none', 'd-xl-inline-block', {
                                    active: activePage === page,
                                })}
                                onClick={(e) => {
                                    if (activePage === page) return null;
                                    changePage(page);
                                }}>
                                <Link to="#" className="page-link">
                                    {page}
                                </Link>
                            </li>
                        </React.Fragment>
                    ) : (
                        <li
                            key={page}
                            className={classNames('page-item', 'd-none', 'd-xl-inline-block', {
                                active: activePage === page,
                            })}
                            onClick={(e) => {
                                if (activePage === page) return null;
                                changePage(page);
                            }}>
                            <Link to="#" className="page-link">
                                {page}
                            </Link>
                        </li>
                    );
                })}
                <li
                    key="nextpage"
                    className={classNames('page-item', 'paginate_button', 'next', {
                        disabled: activePage === pageCount,
                    })}
                    onClick={() => {
                        if (activePage === pageCount) return null;
                        changePage(activePage + 1);
                    }}>
                    <Link to="#" className="page-link">
                        <i className="mdi mdi-chevron-right"></i>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Pagination;
