// @flow
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useSelector, useDispatch } from 'react-redux';
import { cpFolderCreate, assetFolderViewAdd } from '@ovrture/react-redux';
import { folderNameValidation } from '../../../components/form/validation';

const NewFolderModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({ resolver: folderNameValidation, mode: 'onBlur' });
    const values = watch();
    const dispatch = useDispatch();
    const { create, authInfo } = useSelector((state) => ({
        authInfo: state?.auth?.me,
        create: state?.cpFolder?.create,
    }));
    const textChangeHandle = (e) => {
        if (values?.name === e.target.value) {
            setToggle(false);
        } else {
            setValue('name', e.target.value);
            setToggle(true);
        }
    };
    const onSubmit = (data) => {
        setToggle(false);
        const formData = {
            fkPaletteId: props?.data?.data?.fkPaletteId,
            fkRoleId: props?.data?.data?.fkRoleId || authInfo?.role?.id,
            icon: 'mdi mdi-folder',
            name: data.name,
            permissionbyId: props?.data?.data?.permissionbyId,
            userId: props?.data?.data?.userId || authInfo?.id,
            brandId: props?.data?.data?.brandId,
            canBeDeleted: props?.data?.data?.canBeDeleted,
        };
        if (props?.data?.page === 'digital-asset') {
            formData.fkOrgId = authInfo?.organaisationId;
            formData.parentId = props?.data?.data?.id;
            formData.adminId = authInfo?.adminId;
            formData.assetId = null;
            formData.assetRootNodeId = props?.data?.data?.assetRootNodeId;
            dispatch(assetFolderViewAdd(formData));
        } else if (props?.data?.page === 'content-panel') {
            formData.orgId = authInfo?.organaisationId;
            formData.parentId = props?.data?.data?.id;
            formData.fkCpRootNodeId = props?.data?.data?.fkCpRootNodeId || props?.data?.data?.id;
            formData.cpContentId = props?.data?.data?.cpContentId;
            dispatch(cpFolderCreate(formData));
        }
        props?.hideModal();
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col lg={12}>
                    <FormInput
                        type="text"
                        id="name"
                        label="Folder Name *"
                        maxLength="30"
                        errors={errors}
                        register={register}
                        onChange={(e) => textChangeHandle(e)}
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Add" variant="primary" type="submit" disabled={!toggle ? true : false} />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </form>
    );
};
export default NewFolderModal;
