// @flow
import React, { useState, useReducer, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
// // components
import DragnDrop from '../../../components/form/DragnDrop';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { DragDropReducer, DragDropInitialState } from '../../../reducers/DragReducers';
import { impactListSort, impactPanelSequence, impactResetAction } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { getTempData, setRetainHistory } from '../../../helpers/retainHistory';
import { CustomLoader } from '../../../helpers/customLoader';
import ImpactList from '../contentPanels/reportData/ImpactList';

const ImpactSortModal = (props) => {
    const { modalDispatch, modal } = useModalContext();
    const [toggle, setToggle] = useState(false);
    const [list, setList] = useState([]);
    const [ids, setIds] = useState([]);
    const selectedItem = getTempData();
    const [dragItems, dispatch] = useReducer(DragDropReducer, DragDropInitialState);
    const [loader, setLoader] = useState(true);

    const dispatchAction = useDispatch();
    const { impactItems, sequence } = useSelector((state) => ({
        impactItems: state?.impact?.listSort,
        sequence: state?.impact?.sequence,
    }));

    const getList = () => {
        dispatchAction(
            impactListSort({
                isAnd: true,
                fkFundId: props?.data?.id,
                colImpactReportYear: selectedItem?.reportYear,
                sort: 'colImpactPanelSequenceNo,ASC',
                isUnpaged: true,
            })
        );
    };

    useEffect(() => {
        sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'SKIP_LOADER');
        setLoader(true);
        getList();
    }, []);

    useEffect(() => {
        if (sequence) {
            dispatchAction(impactResetAction());
            props?.hideModal();
        }
    }, [sequence]);

    useEffect(() => {
        if (impactItems?.data?.content) {
            setLoader(false);
            setToggle(false);
            impactItems?.data?.content.map((ii) => {
                ii['id'] = ii.pkFundImpactId;
                ii['name'] = ii.pkFundImpactId;
            });
            setList(impactItems?.data?.content);
            dispatchAction(impactResetAction());
        }
    }, [impactItems]);

    useEffect(() => {
        if (list.length > 0) {
            const newIds = list.map((l) => l.id);
            setIds([...new Set(newIds)]);
        }
    }, [list]);

    const handleUpdate = () => {
        dispatchAction(
            impactPanelSequence({
                fundImpactIdList: ids,
                reportYear: selectedItem?.reportYear,
            })
        );
    };

    return (
        <>
            <Card>
                <CustomLoader loader={loader} />
                <Row className="p-2 custom-border m-0">
                    <Col md={2} xs={2}>
                        <b>Selector</b>
                    </Col>
                    <Col md={10} xs={10}>
                        <b>Name</b>
                    </Col>
                </Row>
                <DragnDrop
                    DragDropItem={ImpactList}
                    setToggle={setToggle}
                    dragItems={list}
                    setdragItems={setList}
                    loading={loader}
                    className="impact-sort-modal"
                />
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Update"
                    variant="primary"
                    type="button"
                    disabled={toggle ? false : true}
                    onClick={() => handleUpdate()}
                />
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) => {
                        dispatchAction(impactResetAction());
                        modalDispatch({ type: modalActions.CLOSE_MODAL });
                    }}
                />
            </div>
        </>
    );
};

export default ImpactSortModal;
