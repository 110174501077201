// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import { rgcpHtmlMapAdd, rgcpHtmlMapUpdate, rgcpHtmlMapList, rgcpHtmlDefaultHtmlList } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../../config';

const ReportPanelMapModal = (props) => {
    const [toggle, setToggle] = useState(false);
    const dispatch = useDispatch();
    const [isValidated, setIsValidated] = useState(false);
    const { list, brandlist, paletteList, add, update, typesList, fundTypeList, impactTypeList, defaultHtmlList } =
        useSelector((state) => ({
            list: state?.rgcpHtmlMap?.list,
            brandlist: state?.brand?.allList?.data?.content,
            paletteList: state?.palette?.list?.data,
            add: state?.rgcpHtmlMap?.add,
            update: state?.rgcpHtmlMap?.update,
            typesList: state?.rgcpHtml?.types?.data,
            fundTypeList: state?.fundType?.list?.data,
            impactTypeList: state?.impactType?.list?.data,
            rgcpHtmlList: state?.rgcpHtml?.list?.data?.content,
            defaultHtmlList: state?.rgcpHtml?.defaultHtmlList?.data,
        }));
    useEffect(() => {
        if (props?.data?.orgId) dispatch(rgcpHtmlDefaultHtmlList({ orgId: props?.data?.orgId }));
        else dispatch(rgcpHtmlDefaultHtmlList({ orgId: props?.data?.original?.fkOrgId }));
    }, []);
    useEffect(() => {
        if (add) {
            reset();
            props.hideModal();
            window.scrollTo(0, 0);
            dispatch(
                rgcpHtmlMapList({
                    orgId: props?.data?.orgId,
                    isAnd: true,
                    page: list?.data?.number,
                    size: config.PAGE_SIZE,
                })
            );
        }
        if (update) {
            reset();
            props.hideModal();
            window.scrollTo(0, 0);
            dispatch(
                rgcpHtmlMapList({
                    orgId: props?.data?.original?.fkOrgId,
                    isAnd: true,
                    page: list?.data?.number,
                    size: config.PAGE_SIZE,
                })
            );
        }
        if (props?.data?.original) {
            if (props?.data?.original?.tblRgcpHtmlByColDefaultHtmlId) {
                setValue('defaultHtmlEnabler', 'true');
                setValue('colHtmlTypeName', props?.data?.original?.tblRgcpHtmlByColDefaultHtmlId);
                setToggle(true);
            } else {
                setValue('defaultHtmlEnabler', 'false');
                setValue('colHtmlTypeName', props?.data?.original?.tblRgcpHtmlByColHtmlId);
                setToggle(false);
            }
            setValue('fkRgcpTypeId', props.data.original.fkRgcpTypeId);
            setValue('fkPaletteId', props.data.original.fkPaletteId);
            setValue('fkTemplateId', props.data.original.fkTemplateId);
            setValue('tblRgcpHtmlByColHtmlId', props.data.original.tblRgcpHtmlByColHtmlId);
            setValue('fkFundTypeId', props.data.original.fkFundTypeId);
            setValue('fkImpactTypeId', props.data.original.fkImpactTypeId);
        }
        if (props?.data?.orgId) setValue('orgId', props.data.orgId);
    }, [props?.data, add, update]);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {},
    } = useForm({});
    const watchAll = watch();
    useEffect(() => {
        if (
            watchAll &&
            watchAll.fkTemplateId !== 'Select Template Name' &&
            watchAll.fkPaletteId !== 'Select Palette' &&
            watchAll.fkRgcpTypeId !== 'Select RGCP Type'
        ) {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);
    const onSubmit = (data) => {
        data.tblRgcpHtmlByColHtmlId = null;
        data.tblRgcpHtmlByColDefaultHtmlId = null;
        if (toggle) {
            data.tblRgcpHtmlByColDefaultHtmlId = data?.colHtmlTypeName || 1;
        } else {
            data.tblRgcpHtmlByColHtmlId = data?.colHtmlTypeName || 1;
        }
        if (props?.data?.orgId) {
            data.fkOrgId = props?.data?.orgId;
            dispatch(rgcpHtmlMapAdd(data));
        } else {
            data.fkOrgId = props.data.original.fkOrgId;
            data.pkRgcpId = props.data.original.pkRgcpId;
            dispatch(rgcpHtmlMapUpdate(data));
        }
    };

    const toggleCancel = () => props.hideModal();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={4}>
                    <FormInput type="select" id="fkTemplateId" label="Template Name" register={register}>
                        <option value="Select Template Name">Select Template Name *</option>
                        {brandlist?.map((item, index) => {
                            return (
                                <option key={index} value={item?.id}>
                                    {item?.name}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
                <Col md={4}>
                    <FormInput type="select" id="fkPaletteId" label="Palette" register={register}>
                        <option value="Select Palette">Select Palette *</option>
                        {paletteList?.map((item, index) => {
                            return (
                                <option key={index} value={item?.pkPaletteId}>
                                    {item?.colPaletteName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
                <Col md={4}>
                    <FormInput type="select" id="fkRgcpTypeId" label="RGCP Type" register={register}>
                        <option value="Select RGCP Type">Select RGCP Type *</option>
                        {typesList?.map((item, index) => {
                            return (
                                <option key={index} value={item?.pkRgcpTypeId}>
                                    {item?.colRgcpTypeName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
            </Row>
            <Row className="pb-1">
                <Col md={3}>
                    <FormInput
                        type="radio"
                        id="defaultHtmlEnabler"
                        label="Default"
                        register={register}
                        value={false}
                        onChange={(e) => setToggle(!toggle)}
                        checked={toggle === false}
                    />
                </Col>
                <Col md={9}>
                    <FormInput
                        type="radio"
                        id="defaultHtmlEnabler"
                        register={register}
                        value={true}
                        onChange={(e) => setToggle(!toggle)}
                        label="According to type"
                        checked={toggle === true}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <FormInput
                        type="select"
                        label="Fund Type"
                        placeholder="Fund Type"
                        id="fkFundTypeId"
                        register={register}
                        disabled={toggle ? false : true}>
                        <option value="">Fund Type</option>
                        {fundTypeList?.map((item, index) => {
                            return (
                                <option key={index} value={item?.pkFundTypeId}>
                                    {item?.colFundTypeName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
                <Col md={4}>
                    <FormInput
                        type="select"
                        label="Impact Type"
                        placeholder="Impact Type"
                        id="fkImpactTypeId"
                        register={register}
                        disabled={toggle ? false : true}>
                        <option value="">Impact Type</option>
                        {impactTypeList?.map((item, index) => {
                            return (
                                <option key={index} value={item?.pkImpactTypeId}>
                                    {item?.colImpactName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <FormInput
                        type="select"
                        label="Default Html"
                        placeholder="Default Html"
                        id="colHtmlTypeName"
                        register={register}
                        disabled={toggle ? false : true}>
                        <option value="">Default Html</option>
                        {defaultHtmlList?.map((item, index) => {
                            return (
                                <option key={index} value={item?.pkRgcpHtmlId}>
                                    {item?.colHtmlTypeName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name={props?.data?.orgId ? 'Save' : 'Update'}
                    variant="primary"
                    type="submit"
                    disabled={!isValidated}
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggleCancel} />
            </div>
        </form>
    );
};

export default ReportPanelMapModal;
