//@flow
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

//components
import Table from '../../../components/table/Table';
import ButtonElement from '../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../constants/table';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import {
    analyticActiveSnapshoptList,
    analyticSessionDelete,
    snapshotDownload,
    analyticSnapshotListReset,
    analyticSessionBulkDelete,
    snapshotReset,
} from '@ovrture/react-redux';
import { dateTimeFormat, convertMsToTime } from '../../../utility';
import config from '../../../config';
import { downloadFile } from '../../../helpers/downloadFile/downloadFile';

const SnapshotsModal = (props) => {
    const query = new URLSearchParams(window.location.search);
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const [analyticSnapshots, setAnalyticSnapshots] = useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [resetSelection, setResetSelection] = useState([]);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [allHistoryData, setAllHistoryData] = useState([]);
    let { analyticSnapshotList, loading, downloadedData } = useSelector((state) => ({
        analyticSnapshotList: state?.analytic?.snapshotList,
        loading: state?.analytic?.snapshotLoading,
        downloadedData: state?.snapshot?.download,
    }));
    useEffect(() => {
        if (analyticSnapshotList?.data?.content.length > 0) {
            const newArray = allHistoryData;
            analyticSnapshotList.data.content.map((analyticData) => {
                analyticData['dateTime'] = (
                    <ButtonElement
                        as="text"
                        name={dateTimeFormat(analyticData.colCreatedAt)}
                        title={dateTimeFormat(analyticData.colCreatedAt)}
                    />
                );
                analyticData['name'] = (
                    <ButtonElement
                        as="text"
                        name={analyticData.tblRecords.colRecordFullName}
                        title={analyticData.tblRecords.colRecordFullName}
                    />
                );
                analyticData['owner'] = (
                    <ButtonElement
                        as="text"
                        name={analyticData.user.colFullName}
                        title={analyticData.user.colFullName}
                    />
                );
                analyticData['score'] = analyticData.colScore;
                analyticData['activity'] = (
                    <ButtonElement
                        as="text"
                        name={analyticData.tblActivity.colActivityName}
                        title={analyticData.tblActivity.colActivityName}
                    />
                );
                analyticData['type'] = (
                    <ButtonElement
                        as="text"
                        name={analyticData.tblType.colTypeName}
                        title={analyticData.tblType.colTypeName}
                    />
                );
                analyticData['duration'] =
                    analyticData.tblRecording != null
                        ? convertMsToTime(analyticData.tblRecording.colRecordingTotalDuration)
                        : 'Blocked';
                analyticData['snapshot'] = (
                    <ButtonElement
                        as="text"
                        name={analyticData?.tblSnapshot?.colSnapshotName}
                        title={analyticData?.tblSnapshot?.colSnapshotName}
                    />
                );
                analyticData['id'] = analyticData?.pkSessionLogId;
                analyticData['location'] =
                    analyticData['tblRecording'] === null ? (
                        '-'
                    ) : (
                        <ButtonElement
                            as="text"
                            name={analyticData?.tblRecording?.recordingLocation}
                            title={analyticData?.tblRecording?.recordingLocation}
                        />
                    );
                if (newArray?.filter((f) => f.pkSessionLogId == analyticData?.pkSessionLogId)?.length == 0) {
                    newArray.push(analyticData);
                }
            });
            setPageInfo({
                size: analyticSnapshotList?.data?.size,
                number: analyticSnapshotList?.data?.number,
                totalElements: analyticSnapshotList?.data?.totalElements,
                totalPages: analyticSnapshotList?.data?.totalPages,
            });
            setAnalyticSnapshots(analyticSnapshotList.data.content);
            setAllHistoryData(newArray);
        }
    }, [analyticSnapshotList]);

    useEffect(() => {
        getList(props?.data?.page);
        return () => {
            dispatch(analyticSnapshotListReset());
        };
    }, []);

    useEffect(() => {
        if (downloadedData) {
            downloadFile(
                downloadedData?.data,
                'application/zip',
                downloadedData?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(snapshotReset());
        }
    }, [downloadedData]);

    const getList = (current = '') => {
        const typeId = props?.data?.paramTypeId
            ? props?.data?.paramTypeId
            : props?.data.type === 'analytics-page'
            ? props?.data?.isSite
                ? 1
                : 2
            : props?.data?.original?.tblType?.pkTypeId
            ? props?.data?.original?.tblType?.pkTypeId
            : props?.data?.original?.pkSiteId
            ? 1
            : props?.data?.original?.pkReportId
            ? 2
            : 1;
        const payload = {
            page: current || analyticSnapshotList?.data?.number || 0,
            size: config.PAGE_SIZE,
            sort: 'colReportYear,DESC',
            fkTypeId: typeId,
        };

        props?.data?.isSite == false && (payload.colReportYear = query.get('colFiscalYear'));
        const pageName = props?.data?.params?.page || props?.data?.paramPageName || '';
        const pageRecId = props?.data?.original?.tblRecords?.pkRecordsId || props?.data?.paramRecId;
        pageName === 'engagment-log'
            ? (payload.fkRecordId = pageRecId)
            : props?.data?.recordId && (payload.fkRecordId = props?.data?.recordId);

        dispatch(analyticActiveSnapshoptList(payload));
    };

    const handleDownload = (row) => {
        dispatch(snapshotDownload({ pkSnapshotId: [row?.original?.tblSnapshot?.pkSnapshotId] }));
    };

    const handleDelete = (row) => {
        dispatch(analyticSessionDelete({ pkSessionLogId: row?.original?.id }));
        handleModalClose();
    };
    const Actions = ({ row }) => {
        return (
            <>
                {(row?.original?.tblRecording || row?.original?.tblSnapshot) && (
                    <>
                        <ButtonElement
                            className="action-icon hover-primary"
                            icon={<i className="mdi mdi-download" />}
                            tooltip="Download"
                            as="href"
                            onClick={() => handleDownload(row)}
                        />
                    </>
                )}
                {row?.original?.activity?.props?.name === 'Access' && (
                    <ButtonElement
                        className="action-icon hover-danger"
                        icon={<i className="mdi mdi-delete" />}
                        tooltip="Delete"
                        disabled={props?.data?.params?.roleId === 'DATA_ADMIN' ? true : false}
                        onClick={() =>
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: (e) => handleDelete(row),
                                    data: {
                                        modalActionOnClickCancelButton: () => {
                                            setTimeout(() => {
                                                modalDispatch({
                                                    type: modalActions.OPEN_MODAL,
                                                    payload: {
                                                        modalVariant: 'primary',
                                                        modalDataKey: ['common', 'allSnapshots'],
                                                        data: {
                                                            page: analyticSnapshotList?.data?.number,
                                                        },
                                                    },
                                                });
                                            }, 200);
                                        },
                                    },
                                    modalVariant: 'danger',
                                    modalDataKey: ['common', 'deleteSnapshot'],
                                },
                            })
                        }
                    />
                )}
            </>
        );
    };

    const DetailsColumn = ({ row }) => {
        const url = row.original?.tblRecording?.colRecordingPlaybackUrl;
        return (
            <>
                {url && (
                    <ButtonElement
                        icon={<i className="mdi mdi-play-circle"></i>}
                        className="action-icon hover-primary"
                        tooltip="Play"
                        onClick={() =>
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: (e) => console.log(''),
                                    modalVariant: 'primary',
                                    modalDataKey: ['systemAdmin', 'sessionRecording'],
                                    data: { url: url },
                                },
                            })
                        }></ButtonElement>
                )}
                {!url && <>-</>}
            </>
        );
    };

    const updateSelectedValue = (d) => {
        setTimeout(() => {
            setSelectedValue(d);
        }, 50);
    };

    const handleSelectAll = (val) => {
        setIsSelectAll(val);
        if (!val) setAnalyticSnapshots([...analyticSnapshots]);
    };

    const handleModalClose = () => modalDispatch({ type: modalActions.CLOSE_MODAL });

    const handleBulkOperation = (action = '') => {
        const arr = selectedValue.map((logs) => logs.orgId);
        let openModal = false;
        const newArr = [];
        for (var i = 0; i < arr?.length; i++) {
            const filteredList = allHistoryData.filter(
                (f) => f.pkSessionLogId === arr[i] && f.tblActivity.colActivityName != 'Access'
            );

            filteredList.length == 0 && newArr.push(arr[i]);
            if (filteredList?.length > 0 && selectedValue.length === filteredList?.length) {
                // ----- open modal ---
                openModal = true;
                break;
            }
        }

        if (openModal) {
            modalDispatch({
                type: modalActions.OPEN_MODAL,
                payload: {
                    modalAction: (e) => handleModalClose(),
                    modalVariant: 'warning',
                    modalDataKey: ['common', 'infoModal'],
                    data: {
                        title: 'Unauthorized',
                        content: "No session recording selected. Snapshots can't be deleted",
                        cancelBtnDisabled: true,
                    },
                },
            });
        } else if (action === 'bulk-delete') {
            const payload = {
                sessionLogIdList: newArr,
                isSelectAll: isSelectAll,
                isAnd: true,
                filterCriteria: {},
            };

            modalDispatch({
                type: modalActions.OPEN_MODAL,
                payload: {
                    modalAction: (e) => handleBulkDelete(payload),
                    data: {
                        modalActionOnClickCancelButton: () => {
                            setTimeout(() => {
                                modalDispatch({
                                    type: modalActions.OPEN_MODAL,
                                    payload: {
                                        modalVariant: 'primary',
                                        modalDataKey: ['common', 'allSnapshots'],
                                        data: {
                                            page: analyticSnapshotList?.data?.number,
                                            paramPageName: props?.data?.params?.page || props?.data?.paramPageName,
                                            paramRecId:
                                                props?.data?.original?.tblRecords?.pkRecordsId ||
                                                props?.data?.paramRecId,
                                            paramTypeId: props?.data?.paramTypeId
                                                ? props?.data?.paramTypeId
                                                : props?.data.type === 'analytics-page'
                                                ? props?.data?.isSite
                                                    ? 1
                                                    : 2
                                                : props?.data?.original?.tblType?.pkTypeId
                                                ? props?.data?.original?.tblType?.pkTypeId
                                                : props?.data?.original?.pkSiteId
                                                ? 1
                                                : props?.data?.original?.pkReportId
                                                ? 2
                                                : 1,
                                        },
                                    },
                                });
                            }, 200);
                        },
                    },
                    modalVariant: 'danger',
                    modalDataKey: ['common', 'deleteSnapshot'],
                },
            });
        }
    };

    const handleBulkDelete = (payload) => {
        dispatch(analyticSessionBulkDelete(payload));
        handleModalClose();
    };
    // tableFooterAction props
    const tableFooterAction = {
        title: 'site(s) selected',
        selectedValue: selectedValue,
        action: [
            <ButtonElement
                size="sm"
                onClick={() => {
                    handleBulkOperation('bulk-delete');
                }}
                name="Delete"
                variant="outline-danger"
            />,
        ],
    };

    return (
        <>
            <Card>
                <Table
                    tableId="snapshot"
                    columns={TableColumn.systemAdmin.snapshots([DetailsColumn, Actions])}
                    data={analyticSnapshots}
                    pageSizes={analyticSnapshotList?.data?.size}
                    pageNumber={analyticSnapshotList?.data?.number}
                    totalElements={analyticSnapshotList?.data?.totalElements}
                    totalPages={analyticSnapshotList?.data?.totalPages}
                    paginationDispatch={analyticActiveSnapshoptList}
                    tableFooterActionProps={tableFooterAction}
                    sizePerPageList={TableColumn.sizePerPageList(analyticSnapshots)}
                    isSortable={true}
                    pagination={true}
                    isFilter={false}
                    isSearchable={false}
                    isSelectable={true}
                    customPaginationProps={{
                        fkTypeId: props?.data?.original?.tblType?.pkTypeId,
                    }}
                    loading={loading}
                    theadClass={TableColumn.table_light}
                    updateSelectedValue={updateSelectedValue}
                    resetSelection={resetSelection}
                    setResetSelection={setResetSelection}
                    tableClass="snapshot"
                    handleSelectAll={handleSelectAll}
                    isSelectAll={isSelectAll}
                    tableParentClass="table-snapshot table-resize"
                />
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default SnapshotsModal;
