import { useEffect } from 'react';

const HandleCache = (props) => {
    useEffect(() => {
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const shouldForceRefresh = checkVersionMismatch(latestVersion, localStorage.getItem('meta.version'));
                if (shouldForceRefresh) {
                    // Empty cache and hard reload the site
                    window.history.forward(1);
                    window.location.reload(true);
                }
                localStorage.setItem('meta.version', latestVersion);
            });
    }, []);

    const checkVersionMismatch = (lv, cv) => {
        if (cv == null) return true;
        const letestVersion = lv.split(/\./g);
        const currentVersion = cv.split(/\./g);
        while (letestVersion.length || currentVersion.length) {
            const a = Number(letestVersion.shift());
            const b = Number(currentVersion.shift());
            // eslint-disable-next-line no-continue
            if (a === b) continue;
            // eslint-disable-next-line no-restricted-globals
            return a > b || isNaN(b);
        }
        return false;
    };

    return props.children;
};

export default HandleCache;
