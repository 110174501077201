//@flow
import React from 'react';
//components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const DeleteUSerConfirmModal = (props) => {
    const { modalDispatch } = useModalContext();
    return (
        <>
            <p>Are you sure you want to delete this/these user(s)?</p>
            <div className="modal-footer">
                {props.active !== 0 && (
                    <ButtonElement
                        name="Previous"
                        variant="outline-primary"
                        className="btn-prev"
                        type="button"
                        onClick={() => props.setActive(props.active - 1)}
                    />
                )}
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
                <ButtonElement
                    name="Next"
                    variant="primary"
                    className="btn-next"
                    type="button"
                    onClick={() => props.setActive(props.active + 1)}
                />
            </div>
        </>
    );
};

export default DeleteUSerConfirmModal;
