//@flow
import React, { useState } from 'react';
import MultiStepForm from '../../../helpers/multiStep/Index';
//components
import TransferProceed from './TransferProceedModal';
import DeleteUSerConfirmModal from './DeleteUserConfirmModal';
import DeleteTransferFinalConfirmModal from './DeleteTransferFinalConfirmModal';

const DeleteMultiStepForm = (props) => {
    const [active, setActive] = useState(0);

    const steps = [
        {
            name: 'Confirmation',
            component: <DeleteUSerConfirmModal active={active} setActive={setActive} data={props.data} />,
        },
        {
            name: 'Transfer & Ownership',
            component: <TransferProceed active={active} setActive={setActive} data={props.data} />,
        },
        {
            name: 'Delete',
            component: <DeleteTransferFinalConfirmModal active={active} setActive={setActive} data={props.data} />,
        },
    ];

    return (
        <>
            <MultiStepForm activeStep={active} showNavigation={false} steps={steps} />
        </>
    );
};

export default DeleteMultiStepForm;
