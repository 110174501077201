//@flow
import React from 'react';
//components
import ButtonElement from '../../../components/button/ButtonElement';

const ApprovalSendNotification = (props) => {
    return (
        <>
            <p className="pb-2">
                Would you like to send an email notification to the following user that this{' '}
                <span>{props?.data?.data?.name === 'Site' ? 'site' : 'report'}</span> has been approved?
            </p>
            <p className="text-center pt-0">
                <u>{props?.data?.data?.row?.original?.tblRecords?.colRecordOwnerEmailId}</u>
            </p>
            <div className="modal-footer">
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() => props?.data?.hideModal()}
                />
                <ButtonElement
                    name="No"
                    variant="outline-primary"
                    className="btn-next"
                    type="button"
                    onClick={() => {
                        props?.data?.data.onAprrove(props?.data?.data?.actionFor, props?.data?.data?.row, false);
                    }}
                />
                <ButtonElement
                    name="Yes"
                    variant="primary"
                    className="btn-next"
                    type="button"
                    onClick={() => {
                        props?.data?.data.onAprrove(props?.data?.data?.actionFor, props?.data?.data?.row, true);
                    }}
                />
            </div>
        </>
    );
};

export default ApprovalSendNotification;
