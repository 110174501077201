// @flow
import React, { useEffect } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { useNotificationContex } from '../../../contexts/NotificationContext';
import { modalActions, notificationActions } from '../../../reducers/actions';
import { COMMON_PATH } from '../../../routes/role/path';
import { useNotification, contentSendNotification, contentReset } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { setRetainHistory } from '../../../helpers/retainHistory';

const SendNotificationModal = (props) => {
    const { modalDispatch } = useModalContext();
    const { notificationDispatch } = useNotificationContex();
    const { Toast } = useNotification();
    const dispatch = useDispatch();
    const { sentNotification } = useSelector((state) => ({
        sentNotification: state?.content?.sendNotification,
    }));

    const onSubmit = (isNotification = true) => {
        if (isNotification) {
            dispatch(contentSendNotification({ cpFolderViewId: props?.data?.cpId }));
        } else {
            Toast.success({
                description: `${
                    isNotification ? 'Content Panel published; Notification sent.' : 'Content Panel published.'
                }`,
            });
            setTimeout(() => {
                setRetainHistory(true);
                window.location.href = COMMON_PATH.CONTENT_PANELS;
            }, 2000);
        }
    };

    useEffect(() => {
        if (sentNotification) {
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
            dispatch(contentReset());
            setTimeout(() => {
                setRetainHistory(true);
                window.location.href = COMMON_PATH.CONTENT_PANELS;
            }, 500);
        }
    }, [sentNotification]);

    return (
        <>
            <p>Would you like to send a notification to all users that this content panel has been created?</p>
            <div className="modal-footer">
                <ButtonElement
                    name="No"
                    variant="outline-primary"
                    className="btn-next"
                    type="button"
                    onClick={() => {
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                        setTimeout(() => {
                            onSubmit(false);
                        }, 500);
                    }}
                />
                <ButtonElement
                    name="Yes"
                    variant="primary"
                    className="btn-next"
                    type="button"
                    onClick={() => onSubmit(true)}
                />
            </div>
        </>
    );
};
export default SendNotificationModal;
