import React, { forwardRef, useRef, useEffect } from 'react';

const CheckboxSelect = ({ list, setList, item }) => {
    const handleChange = (item, e) => {
        let findIndex = list.findIndex((items) => items.title === item.title && items.id === item.id);
        let Clone = [...list];
        Clone[findIndex].checked = e.target.checked;
        setList([...Clone]);
    };
    const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef();
        const resolvedRef = ref || defaultRef;

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" ref={resolvedRef} {...rest} />
                    <label htmlFor="form-check-input" className="form-check-label"></label>
                </div>
            </>
        );
    });
    return <IndeterminateCheckbox {...item} onChange={(e) => handleChange(item, e)} />;
};
export default CheckboxSelect;
