//@flow
import React, { useState } from 'react';
// import MultiStepForm from 'react-multistep';
import MultiStepForm from '../../../helpers/multiStep/Index';
//components
import EnableAndDisableStatusModal from './EnableAndDisableStatusModal';
import VerifyIdentityModalForEnableAndDisable from './VerifyIdentityModalForEnableAndDisable';

const EnableDisableMultiStepForm = (props) => {
    const [active, setActive] = useState(0);

    const steps = [
        {
            name: 'Confirmation',
            component: <EnableAndDisableStatusModal active={active} setActive={setActive} data={props.data} />,
        },
        {
            name: props.data.status === 'Disable' ? 'Disable' : 'Enable',
            component: (
                <VerifyIdentityModalForEnableAndDisable active={active} setActive={setActive} data={props.data} />
            ),
        },
    ];

    return (
        <>
            <MultiStepForm activeStep={active} showNavigation={false} steps={steps} />
        </>
    );
};

export default EnableDisableMultiStepForm;
