import React, { useState, useEffect } from 'react';
import ButtonElement from '../../components/button/ButtonElement';
import { useModalContext } from '../../contexts/ModalContext';
import { modalActions } from '../../reducers/actions';
import { countDown } from '../../utility';

const AccountLockedModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [timeLeft, setTimeLeft] = useState(countDown(props?.data?.time));
    useEffect(() => {
        if (timeLeft.hours !== '00' || timeLeft.minutes !== '00' || timeLeft.seconds !== '00') {
            const id = setTimeout(() => {
                setTimeLeft(countDown(props?.data?.time));
            }, 1000);

            return () => {
                clearTimeout(id);
            };
        }
    }, [timeLeft]);

    return (
        <>
            <p>
                You have attempted and failed to sign in five consecutive times. For the security of this account, it
                has been temporarily locked.
            </p>
            <b>
                <p className="text-center">
                    Time Remaining: <span>{`${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}</span> minutes
                </p>
            </b>

            <div className="d-flex justify-content-center">
                <ButtonElement
                    name="Ok"
                    variant="light"
                    type="button"
                    className="m-0"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default AccountLockedModal;
