import { modalActions } from './actions';

export const modalIntialState = {
    isOpen: false,
    modalDataKey: ['platformAdmin', 'status'],
    modalAction: '',
    modalCancelAction: '',
    data: {},
    modalVariant: 'none',

};

export const ModalReducer = (state = modalIntialState, action) => {
    switch (action.type) {
        case modalActions.OPEN_MODAL:
            return {
                ...state,
                isOpen: true,
                modalDataKey: action.payload.modalDataKey,
                modalAction: action.payload.modalAction,
                modalCancelAction: action.payload.modalCancelAction,
                isVisible: true,
                data: action.payload.data,
                modalColor: action.payload.modalVariant
            };
        case modalActions.CLOSE_MODAL:
            return {
                ...state,
                isOpen: false,
            };

        default:
            throw new Error();
    }
};
