// @flow
import React, { useState } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const ChangeLocationConfirmationModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(true);
    return (
        <>
            <p>
                {props?.data === 'folder'
                    ? 'Are you sure you want to move this folder?'
                    : props?.data === 'asset'
                    ? 'Are you sure you want to move this asset?'
                    : 'Are you sure you want to move this content panel?'}
            </p>
            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Yes"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        if (toggle) {
                            setToggle(false);
                            props?.hideModal();
                        }
                    }}
                />
                <ButtonElement
                    name="No"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};
export default ChangeLocationConfirmationModal;
