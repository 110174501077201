import PrivateRoute from '../PrivateRoute';
import { COMMON_PATH, SYSTEM_ADMIN_PATH } from '../role/path';
import {
    BroadcastTemplate,
    ContentPanel,
    ContentPanelAccess,
    EditContentPanel,
    EditSystemBroadcast,
    NewContentPanel,
    SystemBroadcast,
} from './generalRoutes';

export const contentPanelModule = {
    path: COMMON_PATH.CONTENT_PANELS,
    name: 'CONTENT PANELS',
    title: 'Content Panels',
    exact: true,
    component: ContentPanel,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'Content Panels', path: '/', active: true }],
    children: [
        {
            path: COMMON_PATH.CREATE_PANEL,
            name: 'CREATE PANEL',
            title: 'Create Panel',
            exact: true,
            component: NewContentPanel,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Content Panels', path: COMMON_PATH.CONTENT_PANELS, active: false },
                { label: 'Create Panel', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.EDIT_PANEL,
            name: 'EDIT PANEL',
            title: 'Edit Panel',
            exact: true,
            component: EditContentPanel,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Content Panels', path: COMMON_PATH.CONTENT_PANELS, active: false },
                { label: 'Edit Panel', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.CONTENT_CONTROL_ACCESS,
            name: 'CONTENT CONTROL ACCESS',
            title: 'Content Control Access',
            exact: true,
            component: ContentPanelAccess,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Content Panels', path: COMMON_PATH.CONTENT_PANELS, active: false },
                { label: 'Content Control Access', path: '/', active: true },
            ],
        },
        {
            path: SYSTEM_ADMIN_PATH.BROADCAST_ALERT,
            name: 'BROADCAST ALERT',
            title: 'Broadcast Alert',
            exact: true,
            component: SystemBroadcast,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Content Panels', path: COMMON_PATH.CONTENT_PANELS, active: false },
                { label: 'Broadcast Alert', path: '/', active: true },
            ],
        },
        {
            path: SYSTEM_ADMIN_PATH.EDIT_BROADCAST + '/:id',
            name: 'EDIT BROADCAST',
            title: 'Edit Broadcast',
            exact: true,
            component: EditSystemBroadcast,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Content Panels', path: COMMON_PATH.CONTENT_PANELS, active: false },
                { label: 'Broadcast Alert', path: SYSTEM_ADMIN_PATH.BROADCAST_ALERT, active: false },
                { label: 'Edit Broadcast', path: '/', active: true },
            ],
        },
        {
            path: SYSTEM_ADMIN_PATH.CREATE_BROADCAST,
            name: 'CREATE BROADCAST',
            title: 'Create Broadcast',
            exact: true,
            component: BroadcastTemplate,
            route: PrivateRoute,
            breadcrumbs: [
                { label: 'Content Panels', path: COMMON_PATH.CONTENT_PANELS, active: false },
                { label: 'Broadcast Alert', path: SYSTEM_ADMIN_PATH.BROADCAST_ALERT, active: false },
                { label: 'Create Broadcast', path: '/', active: true },
            ],
        },
    ],
};
