// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { EditContentPanelValidation } from '../../../../components/form/validation';
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import { useDispatch, useSelector } from 'react-redux';
import { standardTemplateAdd, standardTemplateUpdate, standardTemplateList, brandAllList } from '@ovrture/react-redux';
import config from '../../../../config';

const EditContentPanel = (props) => {
    const dispatch = useDispatch();
    const { brandlist, paletteList, add, update, list } = useSelector((state) => ({
        brandlist: state?.brand?.allList?.data?.content,
        list: state?.standardTemplate?.list,
        paletteList: state?.palette?.list?.data,
        add: state?.standardTemplate?.add,
        update: state?.standardTemplate?.update,
    }));

    useEffect(() => {
        if (add) {
            reset();
            props.hideModal();
            window.scrollTo(0, 0);
            dispatch(
                standardTemplateList({
                    orgId: props?.data?.orgId,
                    isAnd: true,
                    page: list.data.content.length > 1 ? list?.data?.number : list?.data?.number - 1,
                    size: config.PAGE_SIZE,
                    sort: ['name'],
                })
            );
        }
        if (update) {
            reset();
            window.scrollTo(0, 0);
            props.hideModal();
            setTimeout(function () {
                dispatch(
                    standardTemplateList({
                        orgId: props?.data?.original?.orgId,
                        isAnd: true,
                        page: list?.data?.number,
                        size: config.PAGE_SIZE,
                        sort: ['name'],
                    })
                );
            }, 3000);
        }
        if (props?.data?.original) {
            setValue('brandId', props?.data?.original?.brandId);
            setValue('fkPaletteId', props?.data?.original?.fkPaletteId);
            setValue('name', props?.data?.original?.name);
            setValue('isForSystem', props?.data?.original?.isForSystem?.toString());
            setValue('imageSrc', props?.data?.original?.imageSrc);
            setValue('htmlCode', props?.data?.original?.htmlCode);
        }
        if (props?.data?.orgId) setValue('orgId', props.data.orgId);
    }, [props?.data, add, update]);

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = useForm({ resolver: EditContentPanelValidation, mode: 'onBlur' });

    const watchAll = watch();
    const [isValidated, setIsValidated] = useState(false);

    const onSubmit = (data) => {
        if (props?.data?.orgId) {
            if (data.isForSystem === 'true') data.isForSystem = true;
            else data.isForSystem = false;
            dispatch(standardTemplateAdd(data));
        } else {
            props.data.original.brandId = data.brandId;
            props.data.original.fkPaletteId = data.fkPaletteId;
            props.data.original.name = data.name;
            if (data.isForSystem === 'true') props.data.original.isForSystem = true;
            else props.data.original.isForSystem = false;
            props.data.original.imageSrc = data.imageSrc;
            props.data.original.htmlCode = data.htmlCode;
            dispatch(standardTemplateUpdate(props.data.original));
        }
    };

    const toggle = () => props.hideModal();

    useEffect(() => {
        if (
            watchAll &&
            watchAll.brandId !== 'Select Brand' &&
            watchAll.htmlCode !== '' &&
            watchAll.imageSrc !== '' &&
            watchAll.name !== '' &&
            watchAll.messageMaxCount !== '' &&
            watchAll.fkPaletteId !== 'Select Palette'
        ) {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={6}>
                    <FormInput type="hidden" id="orgId" register={register} />
                    <FormInput
                        type="select"
                        id="brandId"
                        label="Select Brand"
                        register={register}
                        disabled={props?.data?.original?.brandId != null}>
                        <option value="Select Brand">Select Brand *</option>
                        {brandlist?.map((item, index) => {
                            return (
                                <option key={index} value={item?.id}>
                                    {item?.name}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
                <Col md={6}>
                    <FormInput
                        type="select"
                        id="fkPaletteId"
                        label="Select Palette"
                        register={register}
                        disabled={props?.data?.original?.fkPaletteId != null}>
                        <option value="Select Palette">Select Palette *</option>
                        {paletteList?.map((item, index) => {
                            return (
                                <option key={index} value={item?.pkPaletteId}>
                                    {item?.colPaletteName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
                <Col>
                    <FormInput type="text" id="name" label="Name *" errors={errors} register={register} />
                    <b className="d-block mb-1">Module:</b>
                    <FormInput
                        type="radio"
                        id="isForSystem"
                        label="Custom"
                        className="me-4 mb-3"
                        register={register}
                        defaultChecked
                        defaultValue="false"
                        disabled={props?.data?.original?.isForSystem != null}
                    />
                    <FormInput
                        type="radio"
                        id="isForSystem"
                        label="System"
                        register={register}
                        defaultValue="true"
                        disabled={props?.data?.original?.isForSystem != null}
                    />
                    <FormInput type="text" id="imageSrc" label="Image Source *" errors={errors} register={register} />
                    <FormInput
                        className="custom-textarea mb-3"
                        type="textarea"
                        as="textarea"
                        id="htmlCode"
                        label="Html *"
                        errors={errors}
                        register={register}
                        height="true"
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name={props?.data?.orgId ? 'Save' : 'Update'}
                    variant="primary"
                    type="submit"
                    disabled={!isValidated}
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default EditContentPanel;
