// @flow
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
// actions
import { changeSidebarType, changeSidebarTheme } from '@ovrture/react-redux';
import * as layoutConstants from '../constants/layout';
import { useAuth } from '../contexts/AuthContext';

const Topbar = React.lazy(() => import('./Topbar'));
const LeftSidebar = React.lazy(() => import('./LeftSidebar'));
const PageTitle = React.lazy(() => import('../components/PageTitle'));

const loading = () => <div></div>;

const VerticalLayout = ({ children }) => {
    const dispatch = useDispatch();
    const { defaultLayout } = useAuth();

    const { leftSideBarType } = useSelector((state) => ({
        leftSideBarType: state?.layout?.leftSideBarType,
    }));

    const [isMenuOpened, setIsMenuOpened] = useState(false);

    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        setIsMenuOpened((prevState) => {
            setIsMenuOpened(!prevState);
        });

        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.remove('sidebar-enable');
                document.body.setAttribute('data-leftbar-compact-mode', 'condensed');
            } else {
                document.body.classList.add('sidebar-enable');
                document.body.setAttribute('data-leftbar-compact-mode', 'fixed');
            }
        }
    };

    const updateDimensions = useCallback(() => {
        // activate the condensed sidebar if smaller devices like ipad or tablet
        if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
            dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED));
        } else if (window.innerWidth > 1028) {
            dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED));
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_DARK));

        // activate the condensed sidebar if smaller devices like ipad or tablet
        if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
            dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED));
        }

        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, [dispatch, updateDimensions]);

    const isCondensed = leftSideBarType === layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED;
    return !defaultLayout ? (
        <>
            <div className="wrapper">
                <Suspense fallback={loading()}>
                    <LeftSidebar isCondensed={isCondensed} hideUserProfile={true} />
                </Suspense>

                <div className="content-page">
                    <div className="content">
                        <Suspense fallback={loading()}>
                            <Topbar openLeftMenuCallBack={openMenu} hideLogo={true} />
                        </Suspense>
                        <Container fluid>
                            <Suspense fallback={loading()}>
                                <PageTitle />
                            </Suspense>
                            <Suspense fallback={loading()}>{children}</Suspense>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <Suspense fallback={loading()}>{children}</Suspense>
    );
};
export default VerticalLayout;
