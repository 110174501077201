const impactSort = [
    {
        Name: 'Name',
        recordReference: 'f001',
        actions: 'action',
    },
    {
        Name: 'Name',
        recordReference: 'f001',
        actions: 'action',
    },
];
// Platform Admin Data

const coreBasic = [
    {
        elementName: 'Address Default',
        lastEdited: '-',
    },
    {
        elementName: 'Vimeo Account',
        lastEdited: '-',
    },
];

const reportData = [
    {
        elementName: 'Fund Type',
        lastEdited: '--/--/----',
    },
    {
        elementName: 'Fund Description',
        lastEdited: '--/--/----',
    },
    {
        elementName: 'Fund Performance',
        lastEdited: '--/--/----',
    },
    {
        elementName: 'Fund Donors',
        lastEdited: '--/--/----',
    },
    {
        elementName: 'Impact Type',
        lastEdited: '--/--/----',
    },
    {
        elementName: 'Fund Impact',
        lastEdited: '--/--/----',
    },
];

const reportPanelsMap = [
    {
        palette: 'Palette-2021',
        templateName: 'Primary',
        createdAt: 'Jan 21, 2020',
        updatedAt: 'Jan 21, 2020',
    },
    {
        palette: 'Palette-2021',
        templateName: 'Primary',
        createdAt: 'Jan 21, 2020',
        updatedAt: 'Jan 21, 2020',
    },
    {
        palette: 'Palette-2021',
        templateName: 'Primary',
        createdAt: 'Jan 21, 2020',
        updatedAt: 'Jan 21, 2020',
    },
    {
        palette: 'Palette-2021',
        templateName: 'Primary',
        createdAt: 'Jan 21, 2020',
        updatedAt: 'Jan 21, 2020',
    },
    {
        palette: 'Palette-2021',
        templateName: 'Primary',
        createdAt: 'Jan 21, 2020',
        updatedAt: 'Jan 21, 2020',
    },
    {
        palette: 'Palette-2021',
        templateName: 'Primary',
        createdAt: 'Jan 21, 2020',
        updatedAt: 'Jan 21, 2020',
    },
    {
        palette: 'Palette-2021',
        templateName: 'Primary',
        createdAt: 'Jan 21, 2020',
        updatedAt: 'Jan 21, 2020',
    },
];

const reportPanelsHtml = [
    {
        palette: 'Palette-2021',
        templateName: 'Primary',
        type: 'fund_description',
        default: 'true',
        htmlType: 'default_html',
        createdAt: 'Jan 21, 2020',
        updatedAt: 'Jan 21, 2020',
    },
    {
        palette: 'Palette-2021',
        templateName: 'Primary',
        type: 'fund_soa',
        default: 'true',
        htmlType: 'default_html',
        createdAt: 'Jan 21, 2020',
        updatedAt: 'Jan 21, 2020',
    },
    {
        palette: 'Palette-2021',
        templateName: 'Primary',
        type: 'fund_impact',
        default: 'true',
        htmlType: 'default_html',
        createdAt: 'Jan 21, 2020',
        updatedAt: 'Jan 21, 2020',
    },
    {
        palette: 'Palette-2021',
        templateName: 'Primary',
        type: 'fund_performance',
        default: 'true',
        htmlType: 'default_html',
        createdAt: 'Jan 21, 2020',
        updatedAt: 'Jan 21, 2020',
    },
    {
        palette: 'Palette-2021',
        templateName: 'Primary',
        type: 'fund_performance_distribution',
        default: 'true',
        htmlType: 'default_html',
        createdAt: 'Jan 21, 2020',
        updatedAt: 'Jan 21, 2020',
    },
    {
        palette: 'Palette-2021',
        templateName: 'Primary',
        type: 'fund_performance_endowment',
        default: 'true',
        htmlType: 'default_html',
        createdAt: 'Jan 21, 2020',
        updatedAt: 'Jan 21, 2020',
    },
    {
        palette: 'Palette-2021',
        templateName: 'Primary',
        type: 'fund_impact',
        default: 'false',
        htmlType: 'RECIPIENT_LIST_V1_HTML',
        createdAt: 'Jan 21, 2020',
        updatedAt: 'Jan 21, 2020',
    },
];

const reportPb = [
    {
        elementName: 'Primary',
        lastEdited: 'Jan 21, 2020',
    },
    {
        elementName: 'Campaign',
        lastEdited: 'Jan 21, 2020',
    },
    {
        elementName: 'Health',
        lastEdited: 'Jan 21, 2020',
    },
];

const reportDataFundDescriptionModal = [
    {
        fieldName: 'col_life_time_giving',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_performance_time',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_campus',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_school_college',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_department',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_impact_area',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
];
const reportDataFundDonorsModal = [
    {
        fieldName: 'col_life_time_giving',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_performance_time',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_campus',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_school_college',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_department',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_impact_area',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
];
const reportDataFundImpactModal = [
    {
        fieldName: 'col_life_time_giving',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_performance_time',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_campus',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_school_college',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_department',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_impact_area',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
];
const reportDataFundPerformanceModal = [
    {
        fieldName: 'col_life_time_giving',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_performance_time',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_campus',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_school_college',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_department',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
    {
        fieldName: 'col_impact_area',
        render: '',
        manatory: '',
        defaultValue: '___________',
        preprocessingValue: '_________',
    },
];

const fundType = [
    {
        fundType: 'Endowed Fund',
        uploaderFundCode: 'E',
    },
    {
        fundType: 'Current Use',
        uploaderFundCode: 'C',
    },
];
const impactType = [
    {
        impactType: 'Scholarship',
        uploaderFundCode: 'S',
    },
    {
        impactType: 'Program - General',
        uploaderFundCode: 'X',
    },
    {
        impactType: 'Prize',
        uploaderFundCode: 'A',
    },
    {
        impactType: 'Fellowship',
        uploaderFundCode: 'C',
    },
    {
        impactType: 'Internship',
        uploaderFundCode: 'S',
    },
];

// System Admin Data
const sites = [
    {
        lastName: 'last 129last 129last 129last 129last 129last 129last 129last 129last 129',
        firstName: 'first 129first 129first 129first 129first 129first 129first 129first 129first 129',
        owner: 'saba anjum lastsaba anjum lastsaba anjum lastsaba anjum lastsaba anjum lastsaba anjum lastsaba ',
        submitted: '01/06/2022',
        review: 'review',
        status: 'unlocked',
        approval: 'approved',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'unedited',
        actions: 'actions',
    },
    {
        lastName: 'last 130',
        firstName: 'first 131',
        owner: 'saba anjum lastsaba anjum lastsaba anjum lastsaba anjum lastsaba anjum last',
        submitted: '01/07/2022',
        review: 'review',
        status: 'unlocked',
        approval: 'submit',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'pause',
        editAction: 'unedited',
        actions: 'actions',
    },
    {
        lastName: 'last 131 last 131last 131last 131last 131last 131last 131',
        firstName: 'first 131 last 131vlast 131last 131last 131last 131last 131',
        owner: 'dolly nirmal last',
        submitted: '01/08/2022',
        review: 'review',
        status: 'locked',
        approval: 'pending',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'edited',
        actions: 'actions',
    },
    {
        lastName: 'last 133 last 133last 133last 133last 133',
        firstName: 'first 133',
        owner: 'priti agrawal last priti agrawal lastpriti agrawal lastpriti agrawal lastpriti agrawal last',
        submitted: '01/09/2022',
        review: 'review',
        status: 'locked',
        approval: 'submit',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'unedited',
        actions: 'actions',
    },
    {
        lastName: 'last 135',
        firstName: 'first 135',
        owner: 'saurabh rai last',
        submitted: '01/06/2022',
        review: 'review',
        status: 'unlocked',
        approval: 'pending',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'pause',
        editAction: 'unedited',
        actions: 'actions',
    },
    {
        lastName: 'last 137',
        firstName: 'first 137',
        owner: 'shashi patel last',
        submitted: '01/06/2022',
        review: 'review',
        status: 'unlocked',
        approval: 'submit',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'edited',
        actions: 'actions',
    },
    {
        lastName: 'last 155',
        firstName: 'first 155',
        owner: 'komal vyas last',
        submitted: '01/09/2022',
        review: 'review',
        status: 'unlocked',
        approval: 'submit',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'edited',
        actions: 'actions',
    },
    {
        lastName: 'last 150',
        firstName: 'first 150',
        owner: 'saba anjum last',
        submitted: '01/06/2022',
        review: 'review',
        status: 'locked',
        approval: 'approved',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'edited',
        actions: 'actions',
    },
    {
        lastName: 'last 151',
        firstName: 'first 151',
        owner: 'saba anjum last',
        submitted: '01/06/2022',
        review: 'review',
        status: 'locked',
        approval: 'approved',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'edited',
        actions: 'actions',
    },
    {
        lastName: 'last 156',
        firstName: 'first 156',
        owner: 'komal vyas last',
        submitted: '01/09/2022',
        review: 'review',
        status: 'unlocked',
        approval: 'approved',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'unedited',
        actions: 'actions',
    },
    {
        lastName: 'last 157',
        firstName: 'first 157',
        owner: 'saba anjum last',
        submitted: '01/06/2022',
        review: 'review',
        status: 'unlocked',
        approval: 'approved',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'edited',
        actions: 'actions',
    },
    {
        lastName: 'last 158',
        firstName: 'first 158',
        owner: 'saba anjum last',
        submitted: '01/06/2022',
        review: 'review',
        status: 'locked',
        approval: 'approved',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'edited',
        actions: 'actions',
    },
];
const reports = [
    {
        lastName: 'last 129 last 129last 129 last 129 last 129last 129last 129last 129',
        firstName: 'first 129 first 129 first 129 first 129 first 129 first 129 first 129 first 129 ',
        owner: 'saba anjum last saba anjum last saba anjum last saba anjum last saba anjum last ',
        submitted: '01/06/2022',
        fiscalYear: 2021,
        review: 'review',
        status: 'unlocked',
        approval: 'approved',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'unedited',
        actions: 'actions',
    },
    {
        lastName: 'last 130 last 130 last 130 last 130',
        firstName: 'first 131 first 131 first 131 first 131 first 131 first 131 first 131 first 131',
        owner: 'saba anjum lastsaba anjum lastsaba anjum lastsaba anjum lastsaba anjum lastsaba anjum last',
        submitted: '01/07/2022',
        fiscalYear: 2021,
        review: 'review',
        status: 'unlocked',
        approval: 'submit',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'pause',
        editAction: 'unedited',
        actions: 'actions',
    },
    {
        lastName: 'last 131',
        firstName: 'first 131',
        owner: 'dolly nirmal last',
        submitted: '01/08/2022',
        fiscalYear: 2021,
        review: 'review',
        status: 'locked',
        approval: 'pending',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'edited',
        actions: 'actions',
    },
    {
        lastName: 'last 133 last 133 last 133 last 133 last 133 last 133 last 133',
        firstName: 'first 133',
        owner: 'priti agrawal last priti agrawal last priti agrawal last priti agrawal lastpriti agrawal last',
        submitted: '01/09/2022',
        fiscalYear: 2021,
        review: 'review',
        status: 'locked',
        approval: 'submit',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'unedited',
        actions: 'actions',
    },
    {
        lastName: 'last 135',
        firstName: 'first 135',
        owner: 'saurabh rai last',
        submitted: '01/06/2022',
        fiscalYear: 2020,
        review: 'review',
        status: 'unlocked',
        approval: 'pending',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'pause',
        editAction: 'unedited',
        actions: 'actions',
    },
    {
        lastName: 'last 137',
        firstName: 'first 137',
        owner: 'shashi patel last',
        submitted: '01/06/2022',
        fiscalYear: 2022,
        review: 'review',
        status: 'unlocked',
        approval: 'submit',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'edited',
        actions: 'actions',
    },
    {
        lastName: 'last 155',
        firstName: 'first 155',
        owner: 'komal vyas last',
        submitted: '01/09/2022',
        fiscalYear: 2022,
        review: 'review',
        status: 'unlocked',
        approval: 'submit',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'edited',
        actions: 'actions',
    },
    {
        lastName: 'last 150',
        firstName: 'first 150',
        owner: 'saba anjum last',
        submitted: '01/06/2022',
        fiscalYear: 2018,
        review: 'review',
        status: 'locked',
        approval: 'approved',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'edited',
        actions: 'actions',
    },
    {
        lastName: 'last 151',
        firstName: 'first 151',
        owner: 'saba anjum last',
        submitted: '01/06/2022',
        fiscalYear: 2019,
        review: 'review',
        status: 'locked',
        approval: 'approved',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'edited',
        actions: 'actions',
    },
    {
        lastName: 'last 156',
        firstName: 'first 156',
        owner: 'komal vyas last',
        submitted: '01/09/2022',
        fiscalYear: 2021,
        review: 'review',
        status: 'unlocked',
        approval: 'approved',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'unedited',
        actions: 'actions',
    },
    {
        lastName: 'last 157',
        firstName: 'first 157',
        owner: 'saba anjum last',
        submitted: '01/06/2022',
        fiscalYear: 2020,
        review: 'review',
        status: 'unlocked',
        approval: 'approved',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'edited',
        actions: 'actions',
    },
    {
        lastName: 'last 158',
        firstName: 'first 158',
        owner: 'saba anjum last',
        submitted: '01/06/2022',
        fiscalYear: 2022,
        review: 'review',
        status: 'locked',
        approval: 'approved',
        analytics: 'analytics',
        lastModified: '01/06/2022',
        siteStatus: 'play',
        editAction: 'edited',
        actions: 'actions',
    },
];
const records = [
    {
        id: 1,
        lastName: 'last 129last 129last 129last 129last 129last 129last 129',
        firstName: 'first 129first 129first 129first 129first 129first 129',
        role: 'User',
        owner: 'Saba Anjum LastSaba Anjum LastSaba Anjum LastSaba Anjum Last',
        status1: 'play',
        status2: 'locked',
        actions: 'actions',
        isSiteCreated: 'yes',
        isReportCreated: 'no',
        recordNumber: '1',
    },
    {
        id: 2,
        lastName: 'last 130',
        firstName: 'first 131',
        role: 'User',
        owner: 'Saba Anjum Last',
        status1: 'pause',
        status2: 'unlocked',
        actions: 'actions',
        isSiteCreated: 'yes',
        isReportCreated: 'yes',
        recordNumber: '2',
    },
    {
        id: 3,
        lastName: 'last 131',
        firstName: 'first 131',
        role: 'Report Admin',
        owner: 'Saba Anjum Last',
        status1: 'pause',
        status2: 'locked',
        actions: 'actions',
        isSiteCreated: 'no',
        isReportCreated: 'no',
        recordNumber: '3',
    },
    {
        id: 4,
        lastName: 'last 133',
        firstName:
            'first 133first 133first 133first 133first 133first 133Saba Anjum LastSaba Anjum LastSaba Anjum LastSaba Anjum Last',
        role: 'Content Admin',
        owner: 'Saba Anjum LastSaba Anjum LastSaba Anjum LastSaba Anjum Last',
        status1: 'pause',
        status2: 'unlocked',
        actions: 'actions',
        isSiteCreated: 'no',
        isReportCreated: 'yes',
        recordNumber: '4',
    },
    {
        id: 5,
        lastName: 'last 135',
        firstName: 'first 135',
        role: 'Brand Admin',
        owner: 'Saba Anjum Last',
        status1: 'play',
        status2: 'ulocked',
        actions: 'actions',
        isSiteCreated: 'yes',
        isReportCreated: 'yes',
        recordNumber: '5',
    },
    {
        id: 6,
        lastName: 'last 137',
        firstName: 'first 137',
        role: 'User',
        owner: 'Saba Anjum Last',
        status1: 'play',
        status2: 'locked',
        actions: 'actions',
        isSiteCreated: 'no',
        isReportCreated: 'no',
        recordNumber: '6',
    },
    {
        id: 7,
        lastName: 'last 155',
        firstName: 'first 155',
        role: 'Report Admin',
        owner: 'Saba Anjum Last',
        status1: 'play',
        status2: 'unlocked',
        actions: 'actions',
        isSiteCreated: 'yes',
        isReportCreated: 'yes',
        recordNumber: '7',
    },
    {
        id: 8,
        lastName: 'last 150',
        firstName: 'first 150',
        role: 'Content Admin',
        owner: 'Saba Anjum Last',
        status1: 'pause',
        status2: 'unlocked',
        actions: 'actions',
        isSiteCreated: 'yes',
        isReportCreated: 'no',
        recordNumber: '8',
    },
    {
        id: 9,
        lastName: 'last 151',
        firstName: 'first 151',
        role: 'Brand Admin',
        owner: 'Saba Anjum Last',
        status1: 'play',
        status2: 'locked',
        actions: 'actions',
        isSiteCreated: 'yes',
        isReportCreated: 'no',
        recordNumber: '9',
    },
    {
        id: 10,
        lastName: 'last 156',
        firstName: 'first 156',
        role: 'User',
        owner: 'Saba Anjum Last',
        status1: 'play',
        status2: 'unlocked',
        actions: 'actions',
        isSiteCreated: 'no',
        isReportCreated: 'no',
        recordNumber: '10',
    },
    {
        id: 11,
        lastName: 'last 157',
        firstName: 'first 157',
        role: 'User',
        owner: 'Saba Anjum Last',
        status1: 'pause',
        status2: 'locked',
        actions: 'actions',
        isSiteCreated: 'no',
        isReportCreated: 'no',
        recordNumber: '11',
    },
    {
        id: 12,
        lastName: 'last 158',
        firstName: 'first 158',
        role: 'User',
        owner: 'Saba Anjum Last',
        status1: 'play',
        status2: 'locked',
        actions: 'actions',
        isSiteCreated: 'no',
        isReportCreated: 'no',
        recordNumber: '12',
    },
];
const snapshots = [
    {
        dateTime: '01/10/2022, 12:25',
        activity: 'Snapshot',
        duration: '-',
        location: '-',
        details: '-',
        snapshot: 'DEC3-SITE-SS_1-1_10_2022',
        downloadUrl: '/user/download-snapshot?recordId=38812&snapshotId=15416',
        delete: '-',
    },
    {
        dateTime: '01/10/2022, 12:25',
        activity: 'Snapshot',
        duration: '-',
        location: '-',
        details: '-',
        snapshot: 'DEC4-SITE-SS_1-1_10_2022',
        downloadUrl: '/user/download-snapshot?recordId=38812&snapshotId=15416',
        delete: '-',
    },
];
const editorsList = [
    {
        userName: 'Admin Amherest',
        lastEdited: '01/10/2022, 12:25',
        action: 'User Detail Updated',
    },
    {
        userName: 'Admin Amherest',
        lastEdited: '01/10/2022, 12:25',
        action: 'Site Locked',
    },
    {
        userName: 'Admin Amherest',
        lastEdited: '01/10/2022, 12:25',
        action: 'Made Site Live',
    },
    {
        userName: 'Admin Amherest',
        lastEdited: '01/10/2022, 12:25',
        action: 'Paused Site',
    },
    {
        userName: 'Admin Amherest',
        lastEdited: '01/10/2022, 12:25',
        action: 'User Detail Updated',
    },
    {
        userName: 'Admin Amherest',
        lastEdited: '01/10/2022, 12:25',
        action: 'User Detail Updated',
    },
];
const contentPanelRD = [
    {
        fundName: 'Fund Name',
        referenceNumber: 'f001',
        type: 'Endowed fund',
        isPerformanceEdited: true,
        isImpactEdited: false,
        year: 2021,
    },
    {
        fundName: 'Fund Name1',
        referenceNumber: 'f0011',
        type: 'Scholar fund',
        isPerformanceEdited: false,
        isImpactEdited: true,
        year: 2022,
    },
    {
        fundName: 'Fund Name3',
        referenceNumber: 'f003',
        type: 'Endowed fund',
        isPerformanceEdited: true,
        isImpactEdited: false,
        year: 2023,
    },
];
const linkedRecords = [
    {
        Name: 'Name',
        recordReference: 'f001',
        actions: 'action',
    },
    {
        Name: 'Name',
        recordReference: 'f001',
        actions: 'action',
    },
];
const adminAnalyticsData = [
    {
        dateTime: '01/31/2022, 10:55',
        name: 'Last1 Last1v Last1Last1Last1Last1Last1Last1Last1Last1',
        owner: 'Saba Anjum Saba Anjum Saba Anjum Saba Anjum Saba Anjum Saba Anjum Saba Anjum',
        score: 0,
        activity: 'Access',
        type: 'Site',
        duration: '00:00:48',
        details: 'play',
        actions: 'Actions',
    },
    {
        dateTime: '01/31/2022, 11:55',
        name: 'Last1',
        owner: 'Saba Anjum',
        score: 1,
        activity: 'Snapshot',
        type: '2021 Report',
        duration: '00:00:48',
        details: '-',
        actions: 'Actions',
    },
    {
        dateTime: '01/29/2022, 10:55',
        name: 'Last3 Last3 Last3 Last3 Last3Last3 Last3Last3Last3Last3Last3',
        owner: 'Prti Agrawal',
        score: 2,
        activity: 'Access',
        type: 'Site',
        duration: '00:00:48',
        details: '-',
        actions: 'Actions',
    },
    {
        dateTime: '01/28/2022, 11:55',
        name: 'Last1',
        owner: 'Saba Anjum',
        score: 3,
        activity: 'Access',
        type: 'Site',
        duration: '00:00:48',
        details: 'play',
        actions: 'Actions',
    },
    {
        dateTime: '02/01/2022, 09:55',
        name: 'Last1',
        owner: 'Saba Anjum',
        score: 4,
        activity: 'Access',
        type: 'Site',
        duration: '00:00:48',
        details: 'play',
        actions: 'Actions',
    },
    {
        dateTime: '01/31/2022, 10:55',
        name: 'Last1',
        owner: 'Saba Anjum',
        score: 5,
        activity: 'Snapshot',
        type: 'Site',
        duration: '00:00:48',
        details: 'play',
        actions: 'Actions',
    },
    {
        dateTime: '01/31/2022, 10:55',
        name: 'Last1',
        owner: 'Saba Anjum',
        score: 2,
        activity: 'Access',
        type: 'Site',
        duration: '00:00:48',
        details: 'play',
        actions: 'Actions',
    },
    {
        dateTime: '01/31/2022, 10:55',
        name: 'Last1',
        owner: 'Saba Anjum',
        score: 0,
        activity: 'Access',
        type: 'Site',
        duration: '00:00:48',
        details: 'play',
        actions: 'Actions',
    },
    {
        dateTime: '01/31/2022, 10:55',
        name: 'Last1',
        owner: 'Saba Anjum',
        score: 3,
        activity: 'Access',
        type: 'Site',
        duration: '00:00:48',
        details: 'play',
        actions: 'Actions',
    },
    {
        dateTime: '01/31/2022, 10:55',
        name: 'Last1',
        owner: 'Saba Anjum',
        score: 4,
        activity: 'Access',
        type: 'Site',
        duration: '00:00:48',
        details: 'play',
        actions: 'Actions',
    },
    {
        dateTime: '01/31/2022, 10:55',
        name: 'Last1 Last1 Last1Last1Last1Last1Last1Last1Last1',
        owner: 'Saba Anjum Saba AnjumSaba AnjumSaba AnjumSaba AnjumSaba AnjumSaba Anjum',
        score: 5,
        activity: 'Access',
        type: 'Site',
        duration: '00:00:48',
        details: 'play',
        actions: 'Actions',
    },
    {
        dateTime: '01/31/2022, 10:55',
        name: 'Last1',
        owner: 'Saba Anjum',
        score: 0,
        activity: 'Access',
        type: 'Site',
        duration: '00:00:48',
        details: 'play',
        actions: 'Actions',
    },
    {
        dateTime: '01/31/2022, 10:55',
        name: 'Last1',
        owner: 'Saba Anjum',
        score: 0,
        activity: 'Access',
        type: 'Site',
        duration: '00:00:48',
        details: 'play',
        actions: 'Actions',
    },
    {
        dateTime: '01/31/2022, 10:55',
        name: 'Open1',
        owner: 'Saba Anjum',
        score: 2,
        activity: 'Access',
        type: '2021 Report',
        duration: '-',
        details: '-',
        actions: 'Actions',
    },
    {
        dateTime: '01/31/2022, 10:55',
        name: 'Last2',
        owner: 'Saba Anjum',
        score: 2,
        activity: 'Access',
        type: 'Site',
        duration: '-',
        details: 'play',
        actions: 'Actions',
    },
    {
        dateTime: '03/31/2022, 10:55',
        name: 'Last1',
        owner: 'Dolly Nirmal',
        score: 2,
        activity: 'Access',
        type: 'Site',
        duration: 'blocked',
        details: '-',
        actions: 'Actions',
    },
    {
        dateTime: '01/31/2022, 11:55',
        name: 'Last1',
        owner: 'Saba Anjum',
        score: 2,
        activity: 'Snapshot',
        type: '2021 Report',
        duration: '00:00:48',
        details: 'play',
        actions: 'Actions',
    },
];
const analyticsDashboardSiteData = [
    {
        systemTotal: 'Total Records:',
        value: 1530,
        percentageTotal: '-',
    },
    {
        systemTotal: 'Total Sites:',
        value: 201,
        percentageTotal: '32%',
    },
    {
        systemTotal: 'Total Unopened:',
        value: 301,
        percentageTotal: '68%',
    },
    {
        systemTotal: 'Total Opens:',
        value: 179,
        percentageTotal: '89%',
    },
    {
        systemTotal: 'Total Single Opens:',
        value: 22,
        percentageTotal: '11%',
    },
    {
        systemTotal: 'Total Multiple Opens:',
        value: 7,
        percentageTotal: '13%',
    },
    {
        systemTotal: 'Total Snapshots:',
        value: 37,
        percentageTotal: '-',
    },
];
const analyticsDashboardReportData = [
    {
        systemTotal: 'Total Records:',
        value: 1530,
        percentageTotal: '-',
    },
    {
        systemTotal: 'Total Reports:',
        value: 201,
        percentageTotal: '32%',
    },
    {
        systemTotal: 'Total Unopened:',
        value: 301,
        percentageTotal: '68%',
    },
    {
        systemTotal: 'Total Opens:',
        value: 179,
        percentageTotal: '89%',
    },
    {
        systemTotal: 'Total Single Opens:',
        value: 22,
        percentageTotal: '11%',
    },
    {
        systemTotal: 'Total Multiple Opens:',
        value: 7,
        percentageTotal: '13%',
    },
    {
        systemTotal: 'Total Snapshots:',
        value: 37,
        percentageTotal: '-',
    },
];
const analyticsDashboardSiteFilterData = [
    {
        filterResults: 'Records Created:',
        range: 1530,
        percentageTotalRange: '100%',
        running: 1530,
        percentageTotalRunning: '100%',
        allTimeTotal: 1530,
    },
    {
        filterResults: 'Sites Created:',
        range: 179,
        percentageTotalRange: '100%',
        running: 179,
        percentageTotalRunning: '100%',
        allTimeTotal: 179,
    },
    {
        filterResults: 'Sites Edited:',
        range: 1530,
        percentageTotalRange: '100%',
        running: 1530,
        percentageTotalRunning: '100%',
        allTimeTotal: 1530,
    },
    {
        filterResults: 'Snapshots Created:',
        range: 1530,
        percentageTotalRange: '-',
        running: 1530,
        percentageTotalRunning: '-',
        allTimeTotal: 1530,
    },
];
const analyticsDashboardReportFilterData = [
    {
        filterResults: 'Records Created:',
        range: 1530,
        percentageTotalRange: '100%',
        running: 1530,
        percentageTotalRunning: '100%',
        allTimeTotal: 1530,
    },
    {
        filterResults: 'Reports Created:',
        range: 179,
        percentageTotalRange: '100%',
        running: 179,
        percentageTotalRunning: '100%',
        allTimeTotal: 179,
    },
    {
        filterResults: 'Reports Edited:',
        range: 1530,
        percentageTotalRange: '100%',
        running: 1530,
        percentageTotalRunning: '100%',
        allTimeTotal: 1530,
    },
    {
        filterResults: 'FY Reports Created:',
        range: 1530,
        percentageTotalRange: '-',
        running: 1530,
        percentageTotalRunning: '-',
        allTimeTotal: 1530,
    },
];
const analyticsDashboardSiteOpensData = [
    {
        opensResults: 'Sites Opened:',
        range: 1530,
        percentageTotalRange: '100%',
        running: 1530,
        percentageTotalRunning: '100%',
        allTimeTotal: 1530,
    },
    {
        opensResults: 'Single Opens:',
        range: 179,
        percentageTotalRange: '100%',
        running: 179,
        percentageTotalRunning: '100%',
        allTimeTotal: 179,
    },
    {
        opensResults: 'Multiple Opens:',
        range: 1530,
        percentageTotalRange: '100%',
        running: 1530,
        percentageTotalRunning: '100%',
        allTimeTotal: 1530,
    },
    {
        opensResults: 'Untracked Opens:',
        range: 1530,
        percentageTotalRange: '-',
        running: 1530,
        percentageTotalRunning: '-',
        allTimeTotal: 1530,
    },
    {
        opensResults: 'Desktop Opens:',
        range: 1530,
        percentageTotalRange: '100%',
        running: 1530,
        percentageTotalRunning: '100%',
        allTimeTotal: 1530,
    },
    {
        opensResults: 'Mobile Opens:',
        range: 1530,
        percentageTotalRange: '-',
        running: 1530,
        percentageTotalRunning: '-',
        allTimeTotal: 1530,
    },
];
const analyticsDashboardReportOpensData = [
    {
        opensResults: 'Reports Opened:',
        range: 1530,
        percentageTotalRange: '100%',
        running: 1530,
        percentageTotalRunning: '100%',
        allTimeTotal: 1530,
    },
    {
        opensResults: 'Single Opens:',
        range: 179,
        percentageTotalRange: '100%',
        running: 179,
        percentageTotalRunning: '100%',
        allTimeTotal: 179,
    },
    {
        opensResults: 'Multiple Opens:',
        range: 1530,
        percentageTotalRange: '100%',
        running: 1530,
        percentageTotalRunning: '100%',
        allTimeTotal: 1530,
    },
    {
        opensResults: 'Untracked Opens:',
        range: 1530,
        percentageTotalRange: '-',
        running: 1530,
        percentageTotalRunning: '-',
        allTimeTotal: 1530,
    },
    {
        opensResults: 'Desktop Opens:',
        range: 1530,
        percentageTotalRange: '100%',
        running: 1530,
        percentageTotalRunning: '100%',
        allTimeTotal: 1530,
    },
    {
        opensResults: 'Mobile Opens:',
        range: 1530,
        percentageTotalRange: '-',
        running: 1530,
        percentageTotalRunning: '-',
        allTimeTotal: 1530,
    },
];
const analyticsDashboardSiteEngagementData = [
    {
        engagementResults: 'View Duration (Median):',
        range: '00:01:20',
        percentageTotalRange: '100%',
        running: '00:00:20',
        percentageTotalRunning: '100%',
        allTimeTotal: '00:00:20',
    },
    {
        engagementResults: 'View Duration (Mean):',
        range: '00:00:20',
        percentageTotalRange: '100%',
        running: '00:00:20',
        percentageTotalRunning: '100%',
        allTimeTotal: '00:00:20',
    },
    {
        engagementResults: 'View Duration (>1 minute):',
        range: 9,
        percentageTotalRange: '100%',
        running: 14,
        percentageTotalRunning: '100%',
        allTimeTotal: 14,
    },
    {
        engagementResults: 'View Duration (>5 minutes):',
        range: 9,
        percentageTotalRange: '100%',
        running: 9,
        percentageTotalRunning: '100%',
        allTimeTotal: 9,
    },
    {
        engagementResults: 'View Duration (>10 minutes):',
        range: 1530,
        percentageTotalRange: '100%',
        running: 1530,
        percentageTotalRunning: '100%',
        allTimeTotal: 1530,
    },
];
const analyticsDashboardReportEngagementData = [
    {
        engagementResults: 'View Duration (Median):',
        range: '00:00:20',
        percentageTotalRange: '100%',
        running: '00:00:20',
        percentageTotalRunning: '100%',
        allTimeTotal: '00:00:20',
    },
    {
        engagementResults: 'View Duration (Mean):',
        range: '00:00:20',
        percentageTotalRange: '100%',
        running: '00:00:20',
        percentageTotalRunning: '100%',
        allTimeTotal: '00:00:20',
    },
    {
        engagementResults: 'View Duration (>1 minute):',
        range: 0,
        percentageTotalRange: '0%',
        running: 0,
        percentageTotalRunning: '0%',
        allTimeTotal: 0,
    },
    {
        engagementResults: 'View Duration (>5 minutes):',
        range: 0,
        percentageTotalRange: '0%',
        running: 0,
        percentageTotalRunning: '0%',
        allTimeTotal: 0,
    },
    {
        engagementResults: 'View Duration (>10 minutes):',
        range: 1530,
        percentageTotalRange: '100%',
        running: 1530,
        percentageTotalRunning: '100%',
        allTimeTotal: 1530,
    },
];
const exportArchiveData = [
    {
        export: 'Users',
        user: 'ADMIN AUBURN',
        date: '02/01/2022, 15:34',
        fileSize: '13 KB',
        actions: 'action',
    },
    {
        export: 'Users',
        user: 'ADMIN AUBURN',
        date: '02/01/2022, 15:34',
        fileSize: '13 KB',
        actions: 'action',
    },
];
const linkedFundData = [
    {
        id: 1,
        fundName: 'Fund 1',
        status: 'green',
        referenceNumber: 'f001',
    },
    { id: 2, fundName: 'Fund 2', status: 'yellow', referenceNumber: 'f002' },
    {
        id: 3,
        fundName: 'Fund 3',
        status: 'red',
        referenceNumber: 'f003',
    },
    { id: 4, fundName: 'Fund 4', status: 'green', referenceNumber: 'f004' },
    { id: 5, fundName: 'Fund 5', status: 'yellow', referenceNumber: 'f005' },
    {
        id: 6,
        fundName: 'Fund 6',
        status: 'red',
        referenceNumber: 'f006',
    },
    { id: 7, fundName: 'Fund 7', status: 'green', referenceNumber: 'f001' },
    { id: 8, fundName: 'Fund 8', status: 'yellow', referenceNumber: 'f002' },
];
const manageDownloads = [
    {
        year: '2021',
        fileName: 'E087AF06-8082-4D63-A625-DE.PDF',
        actions: 'actions',
    },
    {
        year: '2020',
        fileName: 'F087AF06-8082-4D63-A625-DE.PDF',
        actions: 'actions',
    },
];
const currentEditors = [
    {
        userName: 'Sunil Pandey',
        lastEdited: '02/02/2022, 14:44',
        action: 'Made Site Live',
    },
    {
        userName: 'Sunil Pandey',
        lastEdited: '02/02/2022, 14:44',
        action: 'Site Paused',
    },
    {
        userName: 'ADMIN AMHERST',
        lastEdited: '02/02/2022, 14:44',
        action: 'Made Site Live',
    },
    {
        userName: 'Sunil Pandey',
        lastEdited: '02/02/2022, 14:44',
        action: 'Build Single Site',
    },
    {
        userName: 'Sunil Pandey',
        lastEdited: '02/02/2022, 14:44',
        action: 'Build Single Site',
    },
    {
        userName: 'Sunil Pandey',
        lastEdited: '02/02/2022, 14:44',
        action: 'Build Single Site',
    },
];
const accessKeyData = [
    {
        accessKeyId: 'FFNHQN8HMST2XDJKDK8A',
        created: '02/04/2022, 03:35',
        action: 'action',
    },
    {
        accessKeyId: 'FFNHQN8HMST2XDJKDK55',
        created: '02/04/2022, 03:35',
        action: 'action',
    },
    {
        accessKeyId: 'GGNHQN8HMST2XDJKDK8A',
        created: '02/04/2022, 03:35',
        action: 'action',
    },
    {
        accessKeyId: 'FFNHQN8HMST2XDJKDK8A',
        created: '02/04/2022, 03:35',
        action: 'action',
    },
    {
        accessKeyId: 'FFNHQN8HMST2XDJKDK55',
        created: '02/04/2022, 03:35',
        action: 'action',
    },
    {
        accessKeyId: 'GGNHQN8HMST2XDJKDK8A',
        created: '02/04/2022, 03:35',
        action: 'action',
    },
];
const createdAccessKey = [
    {
        accessKeyId: 'FFNHQN8HMST2XDJKDK8A',
        secretKey: 'hiddenPassword',
        action: 'action',
    },
];
const btdSitesList = [
    {
        lastName: 'last 129',
        slot1: 'giving history',
        slot2: 'gallery',
        slot3: '',
        slot4: '',
        slot5: '',
        slot6: '',
        slot7: '',
        slot8: '',
        slot9: '',
        slot10: '',
        slot11: '',
        slot12: '',
    },
];
const btdReportsList = [
    {
        lastName: 'last 129',
        slot1: 'Fund Description',
        slot2: 'SOA',
        slot3: 'Impact',
        slot4: 'Performance Graph',
        slot5: 'Distribution Graph',
        slot6: 'Percentage Graph',
        slot7: 'giving history',
        slot8: 'gallery',
        slot9: '',
        slot10: '',
        slot11: '',
        slot12: '',
        slot13: '',
        slot14: '',
        slot15: '',
        slot16: '',
        slot17: '',
        slot18: '',
    },
];

export {
    coreBasic,
    reportData,
    reportPanelsMap,
    reportPanelsHtml,
    reportPb,
    reportDataFundDescriptionModal,
    reportDataFundDonorsModal,
    reportDataFundImpactModal,
    reportDataFundPerformanceModal,
    fundType,
    impactType,
    sites,
    reports,
    records,
    snapshots,
    editorsList,
    contentPanelRD,
    adminAnalyticsData,
    linkedRecords,
    analyticsDashboardSiteData,
    analyticsDashboardReportData,
    analyticsDashboardSiteFilterData,
    analyticsDashboardReportFilterData,
    analyticsDashboardSiteOpensData,
    analyticsDashboardReportOpensData,
    analyticsDashboardSiteEngagementData,
    analyticsDashboardReportEngagementData,
    exportArchiveData,
    linkedFundData,
    manageDownloads,
    currentEditors,
    accessKeyData,
    createdAccessKey,
    btdSitesList,
    btdReportsList,
    impactSort,
};
