// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { GroupNameValidation } from '../../../components/form/validation';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const NewGroupModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ resolver: GroupNameValidation, mode: 'onBlur' });
    const values = watch();

    const onSubmit = (name) => {
        modalDispatch({
            type: modalActions.OPEN_MODAL,
            payload: {
                modalAction: (e) => props.hideModal(),
                modalVariant: 'primary',
                modalDataKey: ['systemAdmin', 'selectMembers'],
                data: { name: name },
            },
        });
    };

    useEffect(() => {
        if (values?.name) {
            setToggle(true);
        } else if (values?.name === '') {
            setToggle(false);
        }
    }, [values]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col lg={12}>
                    <FormInput type="text" id="name" label="Group Name *" errors={errors} register={register} />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Proceed" variant="primary" type="submit" disabled={toggle ? false : true} />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={(e) => props.hideModal()} />
            </div>
        </form>
    );
};

export default NewGroupModal;
