// @flow
import React, { useEffect } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useSelector } from 'react-redux';

const PublishContentPanelModal = (props) => {
    const { modalDispatch } = useModalContext();
    const { created, updated, updatePublish } = useSelector((state) => ({
        created: state?.content?.create,
        updated: state?.content?.update,
        updatePublish: state?.content?.updatePublish,
    }));

    useEffect(() => {
        if (created || updated || updatePublish) {
            props?.data?.publishCP(false);
            setTimeout(() => {
                props.setActive(props.active + 1);
            }, 500);
        }
    }, [created, updated, updatePublish]);

    return (
        <>
            <p>Are you sure you want to publish this edited content panel?</p>
            <div className="modal-footer">
                {props.active !== 0 && (
                    <ButtonElement
                        name="Previous"
                        variant="outline-primary"
                        className="btn-prev"
                        type="button"
                        onClick={() => props.setActive(props.active - 1)}
                    />
                )}
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
                <ButtonElement
                    name="Publish"
                    variant="primary"
                    className="btn-next"
                    type="button"
                    onClick={() => props?.data?.publishCP(true, props?.data?.selectedNode)}
                />
            </div>
        </>
    );
};
export default PublishContentPanelModal;
