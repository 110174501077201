export const DragDropInitialState = {
    item: [],
};

export const DragDropReducer = (state = DragDropInitialState, action) => {
    switch (action.type) {
        case 'Impact_data':
            return {
                ...state,
                item: action.payload.item,
            };
        case 'Builder_Data':
            return {
                ...state,
                item: action.payload.item,
            };
        default:
            return { ...state };
    }
};
