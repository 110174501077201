// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
// components
import ButtonElement from '../../../../components/button/ButtonElement';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';
import {
    cpSiteGetHeaderImageList,
    cpReportGetHeaderImageList,
    cpSiteUpdateHeaderImage,
    cpReportUpdateHeaderImage,
} from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';

const BtdGreetingImageModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [enable, setEnable] = useState(null);
    const [imgList, setImgList] = useState([]);
    const [headerImg, setHeaderImg] = useState('');
    const dispatchAction = useDispatch();
    const {
        siteGreetingImageList,
        reportGreetingImageList,
        msgsgInfo,
        msgsgInfoForReport,
        updateHeaderImage,
        updateReportHeaderImage,
    } = useSelector((state) => ({
        siteGreetingImageList: state?.cpSite?.getHeaderImageList,
        reportGreetingImageList: state?.cpReport?.getHeaderImageList,
        authInfo: state?.auth?.me,
        msgsgInfo: state?.cpSite?.getHeaderImage?.data,
        msgsgInfoForReport: state?.cpReport?.getHeaderImage?.data,
        updateHeaderImage: state?.cpSite?.updateHeaderImage,
        updateReportHeaderImage: state?.cpReport?.updateHeaderImage,
    }));

    useEffect(() => {
        if (props?.data?.siteOrReport == 'site') {
            dispatchAction(cpSiteGetHeaderImageList({ brandId: props?.data?.templateId }));
        }
        if (props?.data?.siteOrReport == 'report') {
            dispatchAction(
                cpReportGetHeaderImageList({
                    brandId: props?.data?.templateId,
                    reportYear: props?.data?.year,
                })
            );
        }
    }, [props?.data?.templateId]);
    useEffect(() => {
        if (props?.data?.siteOrReport == 'site') {
            console.log('msgsgInfoForReport...', msgsgInfo);
            msgsgInfo && setHeaderImg(msgsgInfo?.colHeaderImageUrl);
        }
        if (props?.data?.siteOrReport == 'report') {
            console.log('msgsgInfoForReport', msgsgInfoForReport);
            msgsgInfoForReport && setHeaderImg(msgsgInfoForReport?.colHeaderImageUrl);
        }
    }, [msgsgInfo, msgsgInfoForReport]);
    useEffect(() => {
        if (props?.data?.siteOrReport == 'site') {
            siteGreetingImageList?.data && setImgList(siteGreetingImageList?.data?.assetsDetails);
        }
        if (props?.data?.siteOrReport == 'report') {
            reportGreetingImageList?.data && setImgList(reportGreetingImageList?.data?.assetsDetails);
        }
    }, [siteGreetingImageList, reportGreetingImageList]);
    console.log('siteGreetingImageList', siteGreetingImageList);
    useEffect(() => {
        if (updateHeaderImage || updateReportHeaderImage) {
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
        }
    }, [updateHeaderImage, updateReportHeaderImage]);
    const onImageClick = (id, img) => {
        setHeaderImg(img);
        setEnable(id);
    };

    const handleUpdateGreeting = () => {
        const payload = {
            templateId: props?.data?.templateId,
            imageUrl: headerImg,
        };
        props?.data?.siteOrReport == 'report' && (payload.reportYear = props?.data?.year);
        headerImg &&
            (props?.data?.siteOrReport == 'site'
                ? dispatchAction(cpSiteUpdateHeaderImage(payload))
                : dispatchAction(cpReportUpdateHeaderImage(payload)));
    };

    return (
        <>
            <Row>
                <Col lg={12}>
                    <Row className="header-image-wrap">
                        {imgList?.length === 0 ||
                            (imgList === null && (
                                <div className="text-center">
                                    <h5>No Image Found.</h5>
                                </div>
                            ))}
                        {imgList?.length > 0 &&
                            imgList.map((img, index) => {
                                return (
                                    <Col lg={6} className="mb-2 d-flex justify-content-center">
                                        <img
                                            key={index}
                                            src={img.assetsPath}
                                            id={img.id}
                                            max-height="150"
                                            height="130"
                                            className={`${
                                                enable === img.id || img.assetsPath === headerImg
                                                    ? 'active selected'
                                                    : 'inActive'
                                            }`}
                                            alt=""
                                            onClick={(e) => onImageClick(img.assetFolderViewDTO.id, img.assetsPath)}
                                        />
                                    </Col>
                                );
                            })}
                    </Row>
                </Col>
            </Row>

            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Select"
                    variant="outline-primary"
                    type="button"
                    onClick={() => handleUpdateGreeting()}
                    disabled={enable ? false : true}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default BtdGreetingImageModal;
