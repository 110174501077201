//@flow
import React from 'react';
//components
import ButtonElement from '../../../components/button/ButtonElement';

const ClearSystem = (props) => {
    return (
        <>
            <p>Are you sure you want to proceed with clearing this system data?</p>
            <div className="modal-footer">
                {props.active !== 0 && (
                    <ButtonElement
                        name="Previous"
                        variant="outline-primary"
                        className="btn-prev"
                        type="button"
                        onClick={() => props.setActive(props.active - 1)}
                    />
                )}
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() => props.data.onDelete(false, null, null, null)}
                />
                <ButtonElement
                    name="Next"
                    variant="primary"
                    className="btn-next"
                    type="button"
                    onClick={() => props.setActive(props.active + 1)}
                />
            </div>
        </>
    );
};

export default ClearSystem;
