// @flow
import React from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { groupAdd, groupUpdate } from '@ovrture/react-redux';
import { useDispatch } from 'react-redux';

const CreateEditConfirmation = (props) => {
    let data = {
        id: props.data.name.id,
        name: props.data.name.title,
        unCheckedUserIdList: props.data.unCheckedUserIdList,
    };
    const dispatch = useDispatch();
    return (
        <>
            <p>
                Are you sure you want to{' '}
                {props.data.title === 'CreateGroup'
                    ? 'create'
                    : props.data.title === 'EditGroup'
                    ? 'edit membership of'
                    : ''}{' '}
                this group?
            </p>
            <div className="modal-footer d-flex justify-content-center">
                <ButtonElement
                    name="Yes"
                    variant="primary"
                    onClick={() => {
                        if (props.data.title === 'EditGroup') {
                            dispatch(groupUpdate({ ...data }));
                        } else if (props.data.title === 'CreateGroup') {
                            dispatch(
                                groupAdd({
                                    name: props.data.name.name,
                                    unCheckedUserIdList: props.data.unCheckedUserIdList,
                                })
                            );
                        }
                    }}
                />
                <ButtonElement name="No" variant="light" type="button" onClick={(e) => props.hideModal()} />
            </div>
        </>
    );
};

export default CreateEditConfirmation;
