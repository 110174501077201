// @flow
import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions, notificationActions } from '../../../reducers/actions';
import { useNotificationContex } from '../../../contexts/NotificationContext';
import { recordsReset, recordsCopySiteUrl, recordsCopyReportUrl } from '@ovrture/react-redux';

const CopyUrlModal = (props) => {
    const { notificationDispatch } = useNotificationContex();
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    let { copySiteUrl, copyReportUrl } = useSelector((state) => ({
        copySiteUrl: state?.records?.copySiteUrl,
        copyReportUrl: state?.records?.copyReportUrl,
    }));
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({});

    useEffect(() => {
        dispatch(recordsReset());
        if (props?.data?.params?.type === 'Site') {
            dispatch(recordsCopySiteUrl({ pkRecordsId: props?.data?.params.recordId }));
        } else if (props?.data?.params.type === 'Report') {
            dispatch(
                recordsCopyReportUrl({
                    pkRecordsId: props?.data?.params.recordId,
                    reportYear: props?.data?.original?.colFiscalYear,
                })
            );
        }
    }, []);

    const onSubmit = (data) => {
        console.log(data);
    };

    const handleCopyUrl = (text, msg) => {
        navigator.clipboard.writeText(text);
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
            payload: {
                modalAction: notificationDispatch({
                    type: notificationActions.OPEN_NOTIFICATION,
                    payload: {
                        role: 'success',
                        title: 'Success',
                        iconClass: 'mdi mdi-check-circle h2',
                        className: 'icon',
                        message: msg,
                    },
                }),
            },
        });
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <b className="d-block mb-1">Donor-Facing URL (Analytics Enabled):</b>
                <Col lg={10}>
                    <FormInput
                        type="text"
                        id="urlEnabled"
                        className="urlEnabled"
                        register={register}
                        floatinglabel="false"
                        readOnly
                        value={copySiteUrl?.copyURL || copyReportUrl?.copyURL}
                        //  props?.data.original?.htmlURL || ''}
                    />
                </Col>
                <Col lg={2} className="px-1">
                    <ButtonElement
                        name="Copy"
                        variant="outline-primary"
                        type="button"
                        className="mb-3"
                        onClick={() =>
                            copySiteUrl?.copyURL || copyReportUrl?.copyURL
                                ? handleCopyUrl(
                                      copySiteUrl?.copyURL || copyReportUrl?.copyURL,
                                      'Donor-Facing URL copied.'
                                  )
                                : modalDispatch({
                                      type: modalActions.CLOSE_MODAL,
                                  })
                        }
                    />
                </Col>
            </Row>
            <Row>
                <b className="d-block mb-1">Internal Review URL (Analytics Disabled):</b>
                <Col lg={10} className="internal-review-input">
                    <FormInput
                        type="text"
                        id="urlDisabled"
                        register={register}
                        floatinglabel="false"
                        readOnly
                        value={copySiteUrl?.reviewURL || copyReportUrl?.reviewURL}
                        //  props?.data.original?.htmlURL ? props?.original?.htmlURL + '?review=1' : ''}
                    />
                </Col>
                <Col lg={2} className="px-1">
                    <ButtonElement
                        name="Copy"
                        variant="outline-primary"
                        type="button"
                        onClick={() =>
                            copySiteUrl?.reviewURL || copyReportUrl?.reviewURL
                                ? handleCopyUrl(
                                      copySiteUrl?.reviewURL || copyReportUrl?.reviewURL,
                                      'Internal review URL copied.'
                                  )
                                : modalDispatch({
                                      type: modalActions.CLOSE_MODAL,
                                  })
                        }
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </form>
    );
};

export default CopyUrlModal;
