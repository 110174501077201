// @flow
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const ChangeCoverImage = (props) => {
    const { modalDispatch } = useModalContext();
    const [enable, setEnable] = useState(null);
    const [imgList, setImgList] = useState([]);
    const [headerImg, setHeaderImg] = useState('');

    const onImageClick = (id, img) => {
        setHeaderImg(img);
        setEnable(id);
    };

    return (
        <>
            <Row>
                <Col lg={12}>
                    <Row className="header-image-wrap">
                        {imgList?.length === 0 ||
                            (imgList === null && (
                                <div className="text-center">
                                    <h5>No Image Found.</h5>
                                </div>
                            ))}
                        {imgList?.length > 0 &&
                            imgList.map((img, index) => {
                                return (
                                    <Col lg={6} className="mb-2 d-flex justify-content-center">
                                        <img
                                            key={index}
                                            src={img.assetsPath}
                                            id={img.id}
                                            max-height="150"
                                            height="130"
                                            className={`${
                                                enable === img.id || img.assetsPath === headerImg
                                                    ? 'active selected'
                                                    : 'inActive'
                                            }`}
                                            alt=""
                                            onClick={(e) => onImageClick(img.assetFolderViewDTO.id, img.assetsPath)}
                                        />
                                    </Col>
                                );
                            })}
                    </Row>
                </Col>
            </Row>

            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default ChangeCoverImage;
