//@flow
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
//components
import ButtonElement from '../../../components/button/ButtonElement';
import { FormInput } from '../../../components/form/input';
import service from '../../../constants/service.constant';
import { convertIntoBase64 } from '../../../utility';
import { accountReset, accountBulkReset, organizationReset, organizationDetail } from '@ovrture/react-redux';

const VerifyIdentityForCloudDetailsUpdate = (props) => {
    const [toggle, setToggle] = useState(false);
    const [toggle1, setToggle1] = useState(false);
    const [customError, setCustomError] = useState(null);
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({});

    const { account, errorAccountBulk, organization } = useSelector((state) => ({
        account: state?.account,
        errorAccountBulk: state?.accountBulk?.error,
        organization: state?.organization,
    }));

    useEffect(() => {
        if (organization?.platformUpdate?.code === service.accepted) {
            setTimeout(() => {
                dispatch(organizationReset());
                dispatch(organizationDetail({ orgId: 0 }));
            }, 1000);
        }
        if (organization?.platformUpdate?.code === service.notfound) {
            console.log('first');
            console.log('organization?.platformUpdate', organization?.platformUpdate);
            setCustomErrorMsg(organization?.platformUpdate?.data);
        }
    }, [organization?.platformUpdate]);

    const setCustomErrorMsg = (lst) => {
        const err = {};
        const requirePayload = {
            message: 'Required.',
            ref: 'input#accountPassword.is-invalid.form-control',
            type: 'required',
        };
        const errorMsg = {
            type: 'manual',
            message: 'Verification failed.',
        };
        if (lst != 'Data not found.') {
            lst?.map((d) => {
                d?.password && setError('password', errorMsg);
                d?.key && setError('key', errorMsg);
            });
        }
        if (lst == 'Data not found.') {
            setError('key', {
                type: 'manual',
                message: 'Data not found',
            });
        }
    };

    const onSubmit = (data) => {
        console.log('data', props?.data?.data);
        const payload = props?.data?.data?.data || {};
        payload.password = convertIntoBase64(data.password);
        payload.platformAdminSecretKey = data.key;
        console.log('props.data', payload);
        props?.data?.data?.handleSubmit(payload);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <p className="pb-0">Enter your password:</p>
            <FormInput
                type="password"
                id="password"
                label="Password *"
                register={register}
                errors={errors}
                className="mb-0"
                onChange={(e) => setToggle(e.target.value === '' ? false : true)}
            />
            <p className="pb-0">Enter the Platform Administrator key:</p>
            <FormInput
                type="password"
                id="key"
                label="Key *"
                register={register}
                errors={errors}
                onChange={(e) => setToggle1(e.target.value === '' ? false : true)}
            />
            <div className="modal-footer">
                {props.active !== 0 && (
                    <ButtonElement
                        name="Previous"
                        variant="outline-primary"
                        className="btn-prev"
                        type="button"
                        onClick={() => {
                            props.setActive(props.active - 1);
                        }}
                    />
                )}
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() => {
                        props?.data?.hideModal();
                    }}
                />
                <ButtonElement
                    name="Update"
                    variant="primary"
                    className="btn-next"
                    type="submit"
                    disabled={toggle && toggle1 ? false : true}
                />
            </div>
        </form>
    );
};

export default VerifyIdentityForCloudDetailsUpdate;
