//@flow
import React from 'react';
//components
import ButtonElement from '../../../components/button/ButtonElement';

const EnableAndDisableStatusModal = (props) => {
    return (
        <>
            <p>
                {props.data.status === 'Disable'
                    ? 'Are you sure you want to proceed with disabling this system? All admin and user accounts will lose access to platform.'
                    : 'Are you sure you want to proceed with enabling this system? All admin and user accounts will gain access to platform.'}
            </p>
            <div className="modal-footer">
                {props.active !== 0 && (
                    <ButtonElement
                        name="Previous"
                        variant="outline-primary"
                        className="btn-prev"
                        type="button"
                        onClick={() => props.setActive(props.active - 1)}
                    />
                )}
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() => props.data.onPlatformKeyVerifed()}
                />
                <ButtonElement
                    name="Next"
                    variant="primary"
                    className="btn-next"
                    type="button"
                    onClick={() => props.setActive(props.active + 1)}
                />
            </div>
        </>
    );
};

export default EnableAndDisableStatusModal;
