//@flow
import React, { useState } from 'react';
// import MultiStepForm from 'react-multistep';
import MultiStepForm from '../../../helpers/multiStep/Index';
import ApprovalConfimation from './ApprovalConfimation';
//components
import ApprovalSendNotification from './ApprovalSendNotification';

const ApproveMultiStepModalForm = (props) => {
    const [active, setActive] = useState(0);

    const steps = [
        {
            name: 'Confirmation',
            component: <ApprovalConfimation active={active} setActive={setActive} data={props} />,
        },
        {
            name: 'Notification',
            component: <ApprovalSendNotification active={active} setActive={setActive} data={props} />,
        },
    ];

    return (
        <>
            <MultiStepForm activeStep={active} showNavigation={false} steps={steps} />
        </>
    );
};

export default ApproveMultiStepModalForm;
