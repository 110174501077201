// @flow
import React, { useState } from 'react';
import { Col, Card } from 'react-bootstrap';
// components
import ButtonElement from '../../button/ButtonElement';
import { FormInput } from '../../form/input';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useSelector, useDispatch } from 'react-redux';
import { cpSitePanelList, cpSiteReset, cpReportPanelList, cpReportReset } from '@ovrture/react-redux';

const SelectBrandModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(false);
    const [brandId, setBrandId] = useState(props?.data?.id);
    const [brandName, setBrandName] = useState('');
    const dispatchAction = useDispatch();
    const { brands, cpSiteLoading, cpReportLoading, panelReportList, panelSiteList } = useSelector((state) => ({
        brands: state?.brand?.list,
        cpSiteLoading: state?.cpSite?.loading,
        cpReportLoading: state?.cpReport?.loading,
        panelReportList: state?.cpReport?.panelList,
        panelSiteList: state?.cpSite?.panelList,
    }));

    const handleChange = (id, name) => {
        if (props?.data?.page == 'multi-report') {
            dispatchAction(cpReportPanelList({ templateId: id, reportYear: props?.data?.year }));
        } else if (props?.data?.page == 'multi-site') {
            dispatchAction(cpSitePanelList({ templateId: id }));
        }
        setBrandId(id);
        setBrandName(name);
    };
    return (
        <>
            <Card>
                <Card.Body className="custom-height-fixed">
                    {brands?.data?.content &&
                        brands?.data?.content.map((data) => {
                            return (
                                <Col>
                                    <FormInput
                                        type="radio"
                                        id="BrandNamePrimary"
                                        className="col-lmd-12 m-1"
                                        name={data.name}
                                        label={data.name}
                                        value={data.id}
                                        checked={data.id == brandId}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            handleChange(e.target.value, e.target.name);
                                        }}
                                    />
                                </Col>
                            );
                        })}
                    {/* <Col>
                        <FormInput
                            type="radio"
                            id="BrandNameCampaign"
                            name="brandName"
                            label="Campaign"
                            onChange={(e) => setToggle(true)}
                        />
                    </Col>
                    <Col>
                        <FormInput
                            type="radio"
                            id="BrandName2"
                            name="brandName"
                            label="SubBrand"
                            onChange={(e) => setToggle(true)}
                        />
                    </Col> */}
                </Card.Body>
            </Card>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Proceed"
                    variant="primary"
                    type="button"
                    disabled={brandId ? (cpSiteLoading || cpReportLoading ? true : false) : true}
                    onClick={(e) => {
                        props?.data?.handleChangeTemplate({
                            pkRecordsId: props?.data?.pkRecordsId,
                            fkTemplateId: brandId,
                            templateName: brandName,
                            panels:
                                props?.data?.page == 'multi-report'
                                    ? panelReportList?.data || []
                                    : props?.data?.page == 'multi-site'
                                    ? panelSiteList?.data || []
                                    : [],
                        });
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) => {
                        props?.data?.page == 'multi-site' && dispatchAction(cpSiteReset());
                        props?.data?.page == 'multi-report' && dispatchAction(cpReportReset());
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    }}
                />
            </div>
        </>
    );
};

export default SelectBrandModal;
