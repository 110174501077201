// @flow
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// components
import { ResetPasswordAuthenticationValidation } from '../../../components/form/validation';
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { generalResetPasswordCommonReset, generalValidatePassword } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { convertIntoBase64 } from '../../../utility';
import service from '../../../constants/service.constant';

const ResetPasswordAuthentication = (props) => {
    const { modalDispatch } = useModalContext();
    const [customError, setCustomError] = useState(null);
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ resolver: ResetPasswordAuthenticationValidation, mode: 'onBlur' });

    const values = watch();

    const { validatePass } = useSelector((state) => ({
        validatePass: state?.general?.validatePass,
    }));

    useEffect(() => {
        dispatch(generalResetPasswordCommonReset());
        if (validatePass === 'Password does not match.' || validatePass === 'Bad Request') {
            const msg = validatePass === 'Bad Request' ? 'Password does not match.' : validatePass;
            setCustomError({
                accountPassword: msg
                    ? {
                          message: msg,
                          ref: 'input#accountPassword.is-invalid.form-control',
                          type: 'required',
                      }
                    : null,
            });
            dispatch(generalResetPasswordCommonReset());
        } else if (validatePass?.code === service.success) {
            dispatch(generalResetPasswordCommonReset());
            props.setActive(props.active + 1);
        }
    }, [validatePass]);

    const onSubmit = () => {
        if (values?.accountPassword) {
            dispatch(generalValidatePassword({ password: convertIntoBase64(values?.accountPassword) }));
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <p className="pb-0">Please re-enter your account password to proceed.</p>
            <FormInput
                type="password"
                id="accountPassword"
                label="Enter Password *"
                errors={customError}
                register={register}
            />
            <div className="modal-footer">
                {props.active !== 0 && (
                    <ButtonElement
                        name="Previous"
                        variant="outline-primary"
                        className="btn-prev"
                        type="button"
                        onClick={() => props.setActive(props.active - 1)}
                    />
                )}
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
                <ButtonElement
                    name="Next"
                    variant="primary"
                    className="btn-next"
                    type="submit"
                    disabled={values?.accountPassword ? false : true}
                />
            </div>
        </form>
    );
};
export default ResetPasswordAuthentication;
