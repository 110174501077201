// @flow
import React from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { modalActions } from '../../../reducers/actions';
import { useDispatch } from 'react-redux';
import { useModalContext } from '../../../contexts/ModalContext';
import { userBulkActivate, userBulkDeactivate } from '@ovrture/react-redux';
const BulkSuspendUnSuspend = (props) => {
    const dispatch = useDispatch();
    const { modalDispatch } = useModalContext();
    return (
        <>
            <p>
                Are you sure you want to{' '}
                {props.data.name === 'bulksuspended' || props.data.name === 'suspendUser' ? 'suspend ' : 'unsuspend '}
                {props.data.name === 'bulksuspended' || props.data.name === 'bulkUnsuspended'
                    ? 'these users? '
                    : 'this user? '}
            </p>
            <div className="modal-footer d-flex justify-content-center">
                <ButtonElement
                    name="Yes"
                    variant="warning"
                    type="button"
                    onClick={() => {
                        const isSelectAll = props.data.isSelectAll;
                        if (
                            props.data.name === 'bulksuspended' &&
                            props.data.selectedUserArr.map((item) => item?.isActive === true)
                        ) {
                            dispatch(
                                userBulkDeactivate({
                                    userIdList: isSelectAll ? [] : props.data.selectedUserArr.map((item) => item.id),
                                    isSelectAll,
                                    isAnd: true,
                                    filterCriteria: {},
                                })
                            );
                        } else if (
                            props.data.name === 'bulkUnsuspended' &&
                            props.data.selectedUserArr.map((item) => item?.isActive !== true)
                        ) {
                            dispatch(
                                userBulkActivate({
                                    userIdList: isSelectAll ? [] : props.data.selectedUserArr.map((item) => item.id),
                                    isSelectAll,
                                    isAnd: true,
                                    filterCriteria: {},
                                })
                            );
                        } else props.hideModal();
                    }}
                />
                <ButtonElement
                    name="No"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default BulkSuspendUnSuspend;
