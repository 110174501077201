// @flow
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { modal as modalData } from '../../constants/modal';
import { useModalContext } from '../../contexts/ModalContext';
import { modalActions } from '../../reducers/actions';
import ButtonElement from '../button/ButtonElement';

const Modals = () => {
    useEffect(() => {
        try {
            handleResize();
            function handleResize() {
                document
                    ?.getElementsByClassName('modal-resize')[0]
                    ?.querySelector('.modal-dialog')
                    ?.setAttribute('style', 'height: calc(' + window.innerHeight + 'px - 10%) ; min-height: auto');
                document
                    ?.getElementsByClassName('modal-resize')[0]
                    ?.querySelector('.modal-dialog')
                    ?.querySelector('.table-responsive')
                    ?.setAttribute('style', 'height:' + window.innerHeight / 2 + 'px ; min-height: auto');
            }

            window.addEventListener('resize', handleResize);
        } catch (ex) {}
    });
    let { modal, modalDispatch } = useModalContext();
    const { isOpen, modalDataKey, modalAction, modalCancelAction, data, modalColor, modalInfoIcon } = modal;
    const hideModal = {
        modalAction: () => modalAction(),
        modalCancelAction: () => modalCancelAction(),
        data: data,
    };
    const handleModal = () => {
        modalAction();
    };

    const handleCancelActionModal = () => {
        modalCancelAction();
    };
    const modalObj = modalDataKey ? modalData[modalDataKey[0]][modalDataKey[1]](hideModal)[0] : undefined;

    const toggle = () => {
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
    };
    let isFooter = typeof modalObj?.modalContent === 'string' || !!modalObj?.modalMessageText;
    return (
        <Modal
            show={isOpen}
            onHide={toggle}
            size={modalObj?.modalSize + ' modal-dialog-centered'}
            backdrop="static"
            className={modalObj?.modalResize}>
            <Modal.Header
                className={modalColor ? `bg-${modalColor} text-white modal-variant` : null}
                onHide={toggle}
                closeButton={modalObj?.crossIcon}>
                <h4 className="modal-title">
                    {modalObj?.modalTitle}
                    {modalObj?.modalInfoIcon && (
                        <span className="d-inline-block">
                            <ButtonElement
                                className="btn p-0 custom-tooltip-align modal-info-icon"
                                icon={<i className="dripicons-information"></i>}
                                tooltipAlign="right"
                                tooltip={modalObj?.modalInfoMsg}
                            />
                        </span>
                    )}
                </h4>
            </Modal.Header>
            <Modal.Body className={modalObj?.modalBodyClass}>
                <div>{modalObj?.modalContent}</div>
            </Modal.Body>
            {isFooter && (
                <Modal.Footer
                    className={`d-flex justify-content-center ${
                        typeof modalObj?.modalContent === 'string' ? '' : 'border-top-0 py-0'
                    }`}>
                    {typeof modalObj?.modalContent === 'string' && (
                        <>
                            {data?.element &&
                                data?.element?.map((item) =>
                                    item.pos === 'start' ? <span key={item}>{item.elm}</span> : null
                                )}
                            {!data?.saveBtnDisabled && (
                                <ButtonElement
                                    disabled={data?.loading}
                                    loading={String(data?.loading)}
                                    name={modalObj?.modalButton}
                                    variant={modalColor ? modalColor : 'primary'}
                                    onClick={handleModal}></ButtonElement>
                            )}
                            {data?.element &&
                                data?.element?.map((item) =>
                                    item.pos === 'center' ? <span key={item}>{item.elm}</span> : null
                                )}
                            {!data?.cancelBtnDisabled && (
                                <ButtonElement
                                    name={modalObj?.modalCancelButton ? modalObj?.modalCancelButton : 'Cancel'}
                                    variant="light"
                                    onClick={() => {
                                        modalObj?.modalActionOnClickCancelButton &&
                                            modalObj?.modalActionOnClickCancelButton();
                                        modalObj?.modalCancelButtonAction ? handleCancelActionModal() : toggle();
                                    }}></ButtonElement>
                            )}

                            {data?.element &&
                                data?.element?.map((item) =>
                                    item.pos === 'end' ? <span key={item}>{item.elm}</span> : null
                                )}
                        </>
                    )}
                    {modalObj?.modalMessageText && (
                        <div className="text-center mt-0 w-100 warning-wrapper">
                            <div className="footer-text">
                                {modalObj?.modalMessageNote && (
                                    <small className="text-warning">{modalObj?.modalMessageNote}</small>
                                )}
                                {modalObj?.modalMessageWarning && (
                                    <small className="text-danger">{modalObj?.modalMessageWarning}</small>
                                )}
                                <span>{modalObj?.modalMessageText}</span>
                            </div>
                        </div>
                    )}
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default Modals;
