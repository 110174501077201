import React from 'react';
import ButtonElement from '../../components/button/ButtonElement';

function PrivacySecurity(props) {
    return (
        <>
            <p>
                At Ovrture our highest priority is keeping your data safe and secure. Your data is stored at a highly
                secure Amazon Web Services server facility that uses the same secure AES-192 bit encryption in use at
                major banks and financial institutions worldwide. We perform regular security audits and have applied
                other industry leading security solutions and practices to protect our dedicated servers and the
                platform application.{' '}
            </p>
            <p>
                Electronic and procedural safeguards include the use of a unique user name, password, and Google
                reCAPTCHA to keep the bots out. For an extra measure of security we include device authentication that
                controls who can access your account, and on what device. Each and every device that links to your
                account is first authenticated by you with an email. Before you can access your account on a new
                computer, you"ll receive an automated email to confirm your identity, and approve the new device.
            </p>
            <div className="text-center top-border p-2">
                <div className="d-flex justify-content-center">
                    <ButtonElement name="OK" variant="light" onClick={() => props.hideModal()} />
                </div>
            </div>
        </>
    );
}

export default PrivacySecurity;
