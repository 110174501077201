// @flow
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
// components
import ButtonElement from '../../../../components/button/ButtonElement';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';
import TreeView from '../../../../components/treeview';
import { cpGetAllInitialFolder, cpFolderReset, cpUpdateFolder } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';

const SelectNewLocationModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [addfolderDisabled, setAddFolderDisabled] = useState(true);
    const [contentData, setContentData] = useState();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            getInitialFolderList();
        }, 1500);
    }, []);

    const getInitialFolderList = (parentId = '#') => {
        let eleId = document.querySelector('.card-body');
        const ele = document.getElementById(parentId);
        // if (ele && eleId?.children[0]?.children[0]?.children[0]?.classList?.contains('.changeLocation'))
        //     ele?.getElementsByClassName('jstree-icon jstree-ocl')[0]?.classList.add('loading');

        const changeLocation = $('.changeLocation');
        if (ele) {
            const ele1 = changeLocation.find('#' + parentId);
            ele1.find('.jstree-icon.jstree-ocl').addClass('loading');
        }
        dispatch(cpGetAllInitialFolder({ processId: 13, parentId }));
    };

    const { initialList, authInfo } = useSelector((state) => ({
        initialList: state?.cpFolder?.getAllInitialFolder,
        authInfo: state?.auth?.me,
    }));
    useEffect(() => {
        if (initialList?.data) {
            dispatch(cpFolderReset());
            setLoading(false);
            async function createInitialData() {
                await manageTreeData(data);
            }
            if (data.length > 0) {
                createInitialData(initialList?.data);
            } else {
                initialList?.data?.forEach((el) => {
                    data.push({
                        id: el.id,
                        parent_id: el.id,
                        text: el?.text || el?.name,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        canBeDeleted: el.canBeDeleted,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        assetRootNodeId: el.assetRootNodeId,
                        userId: el.userId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                        fkCpRootNodeId: el.fkCpRootNodeId,
                        cpContentId: el.cpContentId,
                    });
                });
                setData([...data]);
            }
            dispatch(cpFolderReset());
        }
    });

    const manageTreeData = (list) => {
        initialList?.data?.forEach((el) => {
            list?.forEach((d) => {
                if (el?.parentId === d?.id) {
                    d.child = false;
                    let disabled = false;
                    if (props?.data?.data?.id === el.id || el.icon === 'glyphicon glyphicon-text-background-cp')
                        disabled = true;
                    d.children.push({
                        id: el.id,
                        parent_id: el.parentId,
                        text: el?.text || el?.name,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        canBeDeleted: el.canBeDeleted,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        userId: el.userId,
                        assetRootNodeId: el.assetRootNodeId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                        fkCpRootNodeId: el.fkCpRootNodeId,
                        cpContentId: el.cpContentId,
                        state: {
                            disabled,
                        },
                    });
                    const filterArry = d.children.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
                    d.children = [...filterArry];
                } else {
                    if (d.children.length > 0) {
                        manageTreeData([...d.children]);
                    }
                }
            });
        });
        setData([...list]);
    };
    const handleGetList = (e, list) => {
        const id = list?.node?.original?.id;
        if (list.event?.type === 'dblclick' && list?.node?.original?.id && list?.node?.original?.child) {
            getInitialFolderList(id);
        }
        setContentData({
            ...list?.node?.original,
            parentRootId: list?.node?.parent,
            currentId: id,
        });
        if (list?.node?.original?.id) {
            const CpFile =
                (props?.data?.pageName === 'Content-panel' && parseInt(id) === parseInt(props.data?.data?.parent_id)) ||
                false;
            if (
                (list?.node.parent === '#' && list?.node?.original.text === 'SYSTEM CLOUD') ||
                list?.node?.original?.id === props?.data?.data?.id
            ) {
                props?.data?.pageName === 'edit-content-panel' || CpFile
                    ? setAddFolderDisabled(true)
                    : setAddFolderDisabled(false);
            } else if (list?.node?.original.text === 'PRIMARY') {
                setAddFolderDisabled(false);
            } else if (
                (list?.node?.original.icon == 'mdi mdi-cloud' && list?.node?.original.text == 'BRAND CLOUD') ||
                list?.node?.original.icon === 'mdi mdi-folder-lock'
            ) {
                setAddFolderDisabled(true);
            } else if (list?.node?.original.icon === 'fa-fa-folder-brand-closed') {
                setAddFolderDisabled(false);
            } else if (
                list?.node?.original.icon === 'fa-fa-folder-palette-closed' ||
                list?.node?.original.icon === 'mdi mdi-folder-account'
            ) {
                setAddFolderDisabled(false);
            } else if (list?.node.icon === 'mdi mdi-folder') {
                setAddFolderDisabled(CpFile);
            } else if (list?.node.children.length) {
                setAddFolderDisabled(false);
            } else if (
                list?.node.icon === 'glyphicon glyphicon-image' ||
                list?.node.icon === 'glyphicon glyphicon-doc-file' ||
                list?.node.icon === 'glyphicon glyphicon-video' ||
                list?.node.icon === 'glyphicon glyphicon-pdf-file'
            ) {
                setAddFolderDisabled(true);
            }
        }
    };

    const handleClick = () => {
        const parentId = contentData?.id;
        const disabledClass = 'disabled';
        const formData = {
            id: props?.data?.data?.id,
            fkPaletteId: props?.data?.data?.fkPaletteId,
            fkRoleId: props?.data?.data?.fkRoleId || authInfo?.role?.id,
            icon: 'mdi mdi-folder',
            name: props?.data?.data?.text,
            permissionbyId: props?.data?.data?.permissionbyId,
            userId: props?.data?.data?.userId || authInfo?.id,
            brandId: props?.data?.data?.brandId,
            canBeDeleted: props?.data?.data?.canBeDeleted,
            cpContentId: props?.data?.data?.cpContentId,
            parentId,
            disabledClass,
            icon: props?.data?.data?.icon,
            fkCpRootNodeId: props?.data?.data?.fkCpRootNodeId || props?.data?.data?.id,
        };
        let activeJstreePath = JSON.parse(localStorage.getItem('activeJstreePath'));
        localStorage.removeItem('activeJstreePath');
        props.data.setMovedPath({
            data: data,
            path: activeJstreePath,
            fromNodeParentId: props?.data?.data?.parent_id,
            fromNodeId: props?.data?.data?.id,
        });
        dispatch(cpUpdateFolder(formData));
        sessionStorage.setItem(
            process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY,
            props?.data?.data?.icon === 'mdi mdi-folder' ? 'Folder moved.' : 'Content Panel moved.'
        );
    };

    return (
        <Row className="jstree-custom-style">
            {loading && (
                <div className="jstree-default">
                    <i className="loading jstree-icon jstree-ocl" />
                    <span className="loading-text">Loading...</span>
                </div>
            )}
            <TreeView handleChange={handleGetList} data={data} identifier="changeLocation" />
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Move"
                    variant="outline-primary"
                    onClick={() => {
                        localStorage.removeItem('contentJstreeHistory');
                        if (props?.data?.pageName === 'edit-content-panel') {
                            let activeJstreePath = JSON.parse(localStorage.getItem('activeJstreePath'));
                            localStorage.removeItem('activeJstreePath');
                            props?.data?.handlePublish(contentData, {
                                data: data,
                                path: activeJstreePath,
                            });
                        } else {
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: handleClick,
                                    modalVariant: 'primary',
                                    modalDataKey: ['common', 'changeLocationConfirmation'],
                                    data: props?.data?.data?.icon === 'mdi mdi-folder' ? 'folder' : 'content-panel',
                                },
                            });
                        }
                    }}
                    disabled={addfolderDisabled}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </Row>
    );
};

export default SelectNewLocationModal;
