import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from '@ovrture/react-redux';
import { BrowserRouter } from 'react-router-dom';
import { TreeViewProvider } from './contexts/TreeViewContext';
import { NotificationProvider } from '@ovrture/react-redux';
import { unregister as unregisterServiceWorker } from 'register-service-worker';

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store({})}>
            <NotificationProvider dismissTime={3000}>
                <TreeViewProvider>
                    <App />
                </TreeViewProvider>
            </NotificationProvider>
        </Provider>
    </BrowserRouter>,
    document.getElementById('rootComponent')
);
unregisterServiceWorker();
