// @flow
import React, { useState } from 'react';
import { Row, Col, Form, FloatingLabel } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { apiKeyDelete } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';

const DeleteAccessKeyModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [enteredAccessKey, setEnteredAccessKey] = useState('');
    const [isMatchKey, setisMatchKey] = useState('');
    const onTextChangeHandler = (e) => {
        setEnteredAccessKey(e.target.value);
    };
    const dispatch = useDispatch();
    const { deleteItem } = useSelector((state) => ({
        apiKeyItems: state?.apiKey?.list,
        deleteItem: state?.apiKey?.delete,
    }));
    document.body.onkeydown = function (e) {
        if (e.key === 'Enter' || e.keyCode === 9) {
            handleDelete();
        }
    };

    const handleDelete = () => {
        if (props?.data?.accessKeyId === enteredAccessKey) {
            dispatch(apiKeyDelete({ id: props?.data?.id }));
            props?.hideModal();
        } else {
            if (enteredAccessKey === '') setisMatchKey('Required.');
            else setisMatchKey('Access key does not match.');
        }
    };

    return (
        <Row>
            <Col>
                <Row>
                    <Col lg={12} className="mb-3">
                        <p>
                            Do you want to permanently delete Access Key? To confirm the deletion, enter the Access Key
                            ID in the input field below.
                        </p>
                        <FloatingLabel label="Access Key:" className={`${isMatchKey ? 'is-invalid' : ''}`}>
                            <Form.Control
                                type="text"
                                id="accessKey"
                                placeholder="Access Key:"
                                onChange={onTextChangeHandler}
                                value={enteredAccessKey}
                                className={`${isMatchKey ? 'is-invalid' : ''}`}
                            />
                            <Form.Control.Feedback type="invalid">{isMatchKey}</Form.Control.Feedback>
                        </FloatingLabel>
                    </Col>
                </Row>
                <div className="button-list d-flex justify-content-center">
                    <ButtonElement
                        name="Delete"
                        variant="danger"
                        type="button"
                        disabled={enteredAccessKey === '' ? true : false}
                        onClick={() => handleDelete()}
                    />
                    <ButtonElement
                        name="Cancel"
                        variant="light"
                        type="button"
                        onClick={(e) =>
                            modalDispatch({
                                type: modalActions.CLOSE_MODAL,
                            })
                        }
                    />
                </div>
            </Col>
        </Row>
    );
};

export default DeleteAccessKeyModal;
