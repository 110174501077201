export const ROLE_ENUM = {
    PLATFORM_ADMIN: 0,
    SYSTEM_ADMIN: 1,
    BRAND_ADMIN: 2,
    SUPER_USER: 3, // SUPER_USER
    USER: 4,
    REPORT_ADMIN: 5,
    CONTENT_ADMIN: 6,
    DATA_ADMIN: 7,
    API_SUBSCRIBER_MONOLITHIC: 8,
    API_SUBSCRIBER_SYSTEM_ADMIN: 9,
    APPLICATION_ADMIN: 10,
};
