import { notificationActions } from './actions';

export const NotificationState = {
    role: '',
    title: '',
    isVisible: false,
    iconClass: '',
    className: '',
    message: '',
    link: '#',
};

export const NotificationReducer = (state = NotificationState, action) => {
    switch (action.type) {
        case notificationActions.OPEN_NOTIFICATION:
            return {
                role: action.payload.role,
                title: action.payload.title,
                iconClass: action.payload.iconClass,
                className: action.payload.className,
                message: action.payload.message,
                isVisible: true,
                link: action.payload.link,
            };
        case notificationActions.CLOSE_NOTIFICATION:
            return {
                ...state,
                isVisible: false,
                link: '#',
            };

        default:
            throw new Error();
    }
};
