import React, { createContext, useReducer, useContext } from 'react';
import { createPortal } from 'react-dom';
import Notifications from '../layouts/Notifications';
import { NotificationReducer, NotificationState } from '../reducers/NotificationReducer';
export const NotificationContext = createContext();
export const NotificationProvider = (props) => {
    const [notification, notificationDispatch] = useReducer(NotificationReducer, NotificationState);
    let notificationData = { notification, notificationDispatch };
    return (
        <NotificationContext.Provider value={notificationData}>
            {props.children}
            {createPortal(<Notifications />, document.body)}
        </NotificationContext.Provider>
    );
};

export function useNotificationContex() {
    const context = useContext(NotificationContext);
    if (context === undefined) {
        throw new Error('useNotificationContex must be used within a NotificationProvider');
    }
    return context;
}
