import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormInput } from '../../../components/form/input';
import { useForm } from 'react-hook-form';
import { resetModalFormValidation } from '../../../components/form/validation';
import ButtonElement from '../../../components/button/ButtonElement';
import { userAdminPlatformResetPassword, userAdminPlatformReset } from '@ovrture/react-redux';
import { convertIntoBase64 } from '../../../utility';
import service from '../../../constants/service.constant';

const ResetPasswordModal = (props) => {
    const [toggle, setToggle] = useState(false);
    const [toggle1, setToggle1] = useState(false);
    const [toggle2, setToggle2] = useState(false);
    const [customError, setCustomError] = useState(null);
    const dispatch = useDispatch();
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: resetModalFormValidation, mode: 'onBlur' });

    const onSubmit = (data) => {
        data.orgId = props?.data?.original?.id;
        data.userId = props?.data?.original?.systemAdmin?.id;
        data.currentPassword = convertIntoBase64(data.currentPassword);
        data.newPassword = convertIntoBase64(data.newPassword);
        data.confirmPassword = convertIntoBase64(data.confirmPassword);
        dispatch(userAdminPlatformResetPassword(data));
    };

    const { resetPassword } = useSelector((state) => ({
        resetPassword: state?.userAdminPlatform?.resetPassword,
    }));

    useEffect(() => {
        if (resetPassword?.code === service.accepted) {
            props.hideModal();
            reset();
            setCustomError(null);
            dispatch(userAdminPlatformReset());
        } else if (
            resetPassword?.code === service.notfound &&
            resetPassword?.data === 'Current password is not match with existing password.'
        ) {
            setCustomError({
                currentPassword: resetPassword?.data
                    ? {
                          message: resetPassword?.data,
                          ref: 'input#accountPassword.is-invalid.form-control',
                          type: 'required',
                      }
                    : null,
            });
        }
    }, [resetPassword]);

    return (
        <>
            <div className="alert alert-warning custom-alert">
                <p>Passwords Must:</p>
                <ul>
                    <li>Be 8-15 characters in length</li>
                    <li>
                        Include an upper case, a lower case, a number, and one of the following special characters:
                        !@#$%^&+=
                    </li>
                    <li>Contain no more than 2 consecutively repeating characters</li>
                    <li>Not include username (forwards or backwards)</li>
                </ul>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormInput type="hidden" id="userId" register={register} />
                <FormInput
                    type="password"
                    id="currentPassword"
                    label="Current Password *"
                    errors={customError || errors}
                    register={register}
                    onChange={(e) => {
                        setCustomError(null);
                        setToggle(e.target.value === '' ? false : true);
                    }}
                />
                <FormInput
                    type="password"
                    id="newPassword"
                    label="New Password *"
                    errors={errors}
                    register={register}
                    onChange={(e) => setToggle1(e.target.value === '' ? false : true)}
                />
                <FormInput
                    type="password"
                    id="confirmPassword"
                    label="Confirm Password *"
                    errors={errors}
                    register={register}
                    onChange={(e) => setToggle2(e.target.value === '' ? false : true)}
                />
                <div className="button-list d-flex justify-content-center">
                    <ButtonElement
                        name="Proceed"
                        variant="primary"
                        type="submit"
                        disabled={toggle && toggle1 && toggle2 ? false : true}
                    />
                    <ButtonElement
                        name="Cancel"
                        variant="light"
                        type="button"
                        onClick={(e) => {
                            dispatch(userAdminPlatformReset());
                            setCustomError(null);
                            props.hideModal();
                        }}
                    />
                </div>
            </form>
        </>
    );
};

export default ResetPasswordModal;
