// @flow
import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { reportDataExportReset, fundDonorExport, reportFundtDataYearList } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';

const ExportFyDonorsModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatchAction = useDispatch();
    const [reportYear, setReportYear] = useState('');
    const [toggle, setToggle] = useState(true);
    const { userInfo, donarExported, yearList } = useSelector((state) => ({
        userInfo: state?.auth?.me,
        donarExported: state?.reportDataExport?.fundDonorExport,
        yearList: state?.reportDataFund?.yearList,
    }));
    useEffect(() => {
        dispatchAction(reportFundtDataYearList());
    }, []);

    useEffect(() => {
        if (donarExported) {
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            dispatchAction(reportDataExportReset());
        }
    }, [donarExported]);

    const downloadFyDonar = () => {
        dispatchAction(fundDonorExport({ reportYear: reportYear || yearList[0] }));
    };

    return (
        <>
            <div className="custom-padding">
                <p>Download fund donor data for a specific fiscal year.</p>
                <Col md={6} className="mt-3">
                    <FormInput
                        type="select"
                        className="custom-select"
                        onChange={(e) => setReportYear(e.target.value)}
                        label="Fiscal Year">
                        {yearList &&
                            yearList.map((y) => {
                                return (
                                    <option key={y} value={y}>
                                        {y}
                                    </option>
                                );
                            })}
                    </FormInput>
                </Col>
            </div>
            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement name="Download" variant="primary" type="submit" onClick={() => downloadFyDonar()} />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default ExportFyDonorsModal;
