const config = {
    API_URL: process?.env?.REACT_APP_API_URL,
    ADMIN: localStorage.getItem('ADMIN') ? JSON.parse(localStorage.getItem('ADMIN')) : {},
    USER: localStorage.getItem('USER') ? JSON.parse(localStorage.getItem('USER')) : {},
    SUPER_USER: localStorage.getItem('SUPER_USER') ? JSON.parse(localStorage.getItem('SUPER_USER')) : {},
    REPORT_ADMIN: localStorage.getItem('REPORT_ADMIN') ? JSON.parse(localStorage.getItem('REPORT_ADMIN')) : {},
    BRAND_ADMIN: localStorage.getItem('BRAND_ADMIN') ? JSON.parse(localStorage.getItem('BRAND_ADMIN')) : {},
    CONTENT_ADMIN: localStorage.getItem('CONTENT_ADMIN') ? JSON.parse(localStorage.getItem('CONTENT_ADMIN')) : {},
    APPLICATION_ADMIN: localStorage.getItem('APPLICATION_ADMIN')
        ? JSON.parse(localStorage.getItem('APPLICATION_ADMIN'))
        : {},
    DATA_ADMIN: localStorage.getItem('DATA_ADMIN') ? JSON.parse(localStorage.getItem('DATA_ADMIN')) : {},
    ROLE_ID: sessionStorage.getItem('ROLE_ID') ? sessionStorage.getItem('ROLE_ID') : 'PLATFORM_ADMIN', //   PLATFORM_ADMIN  SYSTEM_ADMIN  USER REPORT_ADMIN BRAND_ADMIN CONTENT_ADMIN
    ROLE_NUM: sessionStorage.getItem('ROLE_NUM') ? sessionStorage.getItem('ROLE_NUM') : '0', //   PLATFORM_ADMIN  SYSTEM_ADMIN  USER REPORT_ADMIN BRAND_ADMIN CONTENT_ADMIN
    // pagination
    PAGE_SIZE: 10,
    supportEmail: 'saurabh.rai@techment.com',
};
export default config;
