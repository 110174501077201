//@flow
import React, { useState } from 'react';
// import MultiStepForm from 'react-multistep';
import MultiStepForm from '../../../helpers/multiStep/Index';
//components
import CloudDetailsUpdateConfimation from './CloudDetailsUpdateConfimation';
import VerifyIdentityForCloudDetailsUpdate from './VerifyIdentityForCloudDetailsUpdate';

const CloudDetailsUpdateMultiStepForm = (props) => {
    const [active, setActive] = useState(0);

    const steps = [
        {
            name: 'Confirmation',
            component: <CloudDetailsUpdateConfimation active={active} setActive={setActive} data={props} />,
        },
        {
            name: 'Authorise',
            component: <VerifyIdentityForCloudDetailsUpdate active={active} setActive={setActive} data={props} />,
        },
    ];

    return (
        <>
            <MultiStepForm activeStep={active} showNavigation={false} steps={steps} />
        </>
    );
};

export default CloudDetailsUpdateMultiStepForm;
