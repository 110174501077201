// @flow
import React from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { modalActions } from '../../../reducers/actions';
import { useModalContext } from '../../../contexts/ModalContext';

const RestoreActions = (props) => {
    const { modalDispatch } = useModalContext();
    const restoreItem = (data) => {
        props.data.restoreItem(data);
    };
    return (
        <>
            <p>Who should own this restored record?</p>
            <div className="modal-footer d-flex justify-content-center">
                <ButtonElement
                    name="Original Owner"
                    variant="primary"
                    type="button"
                    onClick={() =>
                        props?.data?.restoreItem(
                            {
                                pkRecordsId: props.data.selectedUserArr,
                                fkUserId: props.data.fkUserId,
                            },
                            false
                        )
                    }
                />
                <ButtonElement
                    name="New Owner"
                    variant="primary"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.OPEN_MODAL,
                            payload: {
                                modalVariant: 'primary',
                                modalDataKey: ['systemAdmin', 'restoreOriginalOwner'],
                                data: {
                                    data: props.data,
                                    restoreItem: restoreItem,
                                },
                            },
                        })
                    }
                />
            </div>
        </>
    );
};

export default RestoreActions;
