// @flow
import React from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import {
    userBulkHardDelete,
    userSoftDelete,
    bulkSoftDelete,
    bulkHardDelete,
    siteBulkDeleteMultiple,
    bulkReportDelete,
} from '@ovrture/react-redux';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../../reducers/actions';
import { useModalContext } from '../../../contexts/ModalContext';

const DeleteActions = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    return (
        <>
            {props?.data?.name === 'softDeleteUserandGroups' ? (
                <p>Last chance. Are you sure you want to delete this user?</p>
            ) : props?.data?.name === 'bulkDeleteUserandGroups' ? (
                <p>Last chance. Are you sure you want to delete this user?</p>
            ) : props?.data?.name === 'bulkRecordsDelete' ? (
                <p>
                    Are you sure you want to delete the record(s) permanently? Doing so will discard all related sites,
                    reports, content, and data.
                </p>
            ) : props?.data?.item === 'deleteRecord' ? (
                <p>
                    Are you sure you want to delete {props.data.original?.user?.organaisationName} the record(s)
                    permanently? Doing so will discard all related sites, reports, content, and data.
                </p>
            ) : props?.data?.name === 'deleteRecordsRestore' ? (
                <p>
                    Are you sure you want to delete this/these record(s) permanently? Doing so will discard all related
                    sites, reports, content, and data.
                </p>
            ) : props?.data?.item === 'deleteSite' ? (
                <p>Are you sure you want to delete this site permanently?</p>
            ) : props?.data?.item === 'deleteReport' ? (
                <p>Are you sure you want to delete this report permanently?</p>
            ) : props?.data?.name === 'bulkSitesDelete' ? (
                <p>Are you sure you want to delete this/these site(s) permanently?</p>
            ) : props?.data?.name === 'bulkReportsDelete' ? (
                <p>Are you sure you want to delete this/these report(s) permanently?</p>
            ) : props?.data?.name === 'hardDeleteRecord' ? (
                <p>Are you sure you want to delete this/these record(s) permanently?</p>
            ) : (
                ''
            )}

            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Delete"
                    variant="danger"
                    type="button"
                    onClick={() => {
                        if (props?.data?.name === 'softDeleteUserandGroups') {
                            dispatch(userSoftDelete({ userId: props.data.selectedUserArr }));
                        } else if (props?.data?.name === 'bulkDeleteUserandGroups') {
                            dispatch(userBulkHardDelete(props.data.selectedUserArr));
                        } else if (props?.data?.name === 'bulkRecordsDelete') {
                            dispatch(bulkSoftDelete(props.data.payload));
                        } else if (props?.data?.item === 'deleteRecord') {
                            props.data.onDelete(true, props?.data?.item, props.data.original);
                        } else if (props?.data?.name === 'deleteRecordsRestore') {
                            dispatch(bulkHardDelete(props.data.payload));
                        } else if (props?.data?.item === 'deleteSite') {
                            props.data.onDelete(true, props?.data?.item, props.data.original);
                        } else if (props?.data?.item === 'deleteReport') {
                            props.data.onDelete(true, props?.data?.item, props.data.original);
                        } else if (props?.data?.name === 'bulkSitesDelete') {
                            dispatch(
                                siteBulkDeleteMultiple({
                                    pkSiteIdList: props?.data.isSelectAll
                                        ? []
                                        : props.data.selectedUserArr.map((item) => item?.id || item?.orgId),
                                    isSelectAll: props?.data.isSelectAll,
                                    isAnd: true,
                                    filterCriteria: {},
                                })
                            );
                        } else if (props?.data?.name === 'bulkReportsDelete') {
                            dispatch(
                                bulkReportDelete({
                                    pkReportIdList: props?.data.isSelectAll
                                        ? []
                                        : props.data.selectedUserArr.map((item) => item?.id || item?.orgId),
                                    isSelectAll: props?.data.isSelectAll,
                                    isAnd: true,
                                    filterCriteria: {},
                                })
                            );
                        } else if (props?.data?.name === 'hardDeleteRecord') {
                            props.data.onDelete(true, props?.data?.item, props.data.original);
                        }
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default DeleteActions;
