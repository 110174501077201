// @flow
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// components
import { BroadcastValidation } from '../../../../components/form/validation';
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import { useDispatch, useSelector } from 'react-redux';
import { broadcastUpdate, broadcastAdd, broadcastList } from '@ovrture/react-redux';
import config from '../../../../config';

const BroadcastModal = (props) => {
    const dispatch = useDispatch();
    const [isValidated, setIsValidated] = useState(false);
    const { list, add, update } = useSelector((state) => ({
        list: state?.broadcast?.list,
        add: state?.broadcast?.add,
        update: state?.broadcast?.update,
    }));

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
    } = useForm({ resolver: BroadcastValidation, mode: 'onBlur' });
    const watchAll = watch();

    useEffect(() => {
        if (add) {
            props.hideModal();
            dispatch(
                broadcastList({
                    orgId: props?.data?.orgId,
                    isAnd: true,
                    page: list?.data?.number,
                    size: config.PAGE_SIZE,
                })
            );
        }

        if (update) {
            props.hideModal();
            dispatch(
                broadcastList({
                    orgId: props?.data?.original?.fkOrgId,
                    isAnd: true,
                    page: list?.data?.number,
                    size: config.PAGE_SIZE,
                })
            );
        }
        if (props?.data) {
            setValue('name', props.data.original?.name);
            setValue('htmlCode', props.data.original?.htmlCode);
            if (props.data.orgId) setValue('fkOrgId', props.data.orgId);
        }
    }, [props?.data, add, update]);
    useEffect(() => {
        if (watchAll && typeof watchAll.name !== 'undefined' && watchAll.htmlCode !== '') {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);

    const onSubmit = (data) => {
        if (props?.data?.orgId) {
            dispatch(broadcastAdd(data));
        } else {
            props.data.original.name = data.name;
            props.data.original.htmlCode = data.htmlCode;
            dispatch(broadcastUpdate(props?.data?.original));
        }
    };

    const toggle = () => props.hideModal();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput type="hidden" id="fkOrgId" label="fkOrgId *" errors={errors} register={register} />
            <FormInput type="text" id="name" label="Name *" errors={errors} register={register} />
            <FormInput
                className="custom-textarea mb-3"
                type="textarea"
                as="textarea"
                id="htmlCode"
                label="Html *"
                errors={errors}
                register={register}
                height="true"
            />
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name={props?.data?.orgId ? 'Save' : 'Update'}
                    variant="primary"
                    type="submit"
                    disabled={!isValidated}
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default BroadcastModal;
