// @flow
import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// components
import ButtonElement from '../../../../components/button/ButtonElement';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';
//Uploaders
import { userValidationErrorDownload, userImportReset } from '@ovrture/react-redux';
import { downloadFile } from '../../../../helpers/downloadFile/downloadFile';
import { useHistory } from 'react-router-dom';
import { COMMON_PATH } from '../../../../routes/role/path';

const ImportDataModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const history = useHistory();

    const { userValidationErrorDownload_ } = useSelector((state) => ({
        userValidationErrorDownload_: state?.userImport?.userValidationErrorDownload,
    }));

    useEffect(() => {
        if (userValidationErrorDownload_) {
            downloadFile(
                userValidationErrorDownload_?.data,
                'application/zip',
                userValidationErrorDownload_?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(userImportReset());
        }
    }, [userValidationErrorDownload_]);

    const downloadAnnotationSheet = (errorId) => {
        if (props?.data?.KeyName) {
            props?.data?.handleDownloadError({
                pkXLSErrorId: errorId,
                isPreflight: false,
                isUpdater: props?.data?.isUpdater,
            });
        } else {
            dispatch(
                userValidationErrorDownload({
                    pkXLSErrorId: errorId,
                    isPreflight: false,
                    isUpdater: props?.data?.isUpdater,
                })
            );
        }
    };

    return (
        <>
            <Row>
                <Col lg={12}>
                    <p>The following results were achieved by importing this data file:</p>
                    {(props?.data?.data?.successRowCount > 0 || props?.data?.data?.success) && (
                        <div className="success">
                            <p>
                                <b>
                                    {props?.data?.data?.failedRowCount === 0 || props?.data?.data?.success
                                        ? 'All'
                                        : props?.data?.data?.successRowCount}
                                </b>{' '}
                                rows <b>passed</b> the validation process.
                            </p>
                        </div>
                    )}
                    {props?.data?.data?.failedRowCount > 0 && (
                        <div className="fail hide">
                            <p>
                                <b>
                                    {props?.data?.data?.successRowCount === 0
                                        ? 'All'
                                        : props?.data?.data?.failedRowCount}
                                </b>{' '}
                                rows <b>failed</b> the validation process; download the failed rows to learn why,
                                correct the data, and upload again.
                            </p>
                            <div className="button-list d-flex justify-content-center mt-2">
                                <ButtonElement
                                    name="Download Failed Rows"
                                    variant="outline-primary"
                                    type="button"
                                    onClick={() => downloadAnnotationSheet(props?.data?.data?.pkXlsErrorId)}
                                />
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
            <div className="modal-footer d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) => {
                        props?.data?.redirectOn === 'portfolio' &&
                        ((props?.data?.data?.failedRowCount > 0 && props?.data?.data?.successRowCount > 0) ||
                            props?.data?.data?.failedRowCount === 0 ||
                            props?.data?.data?.success)
                            ? props?.data?.onRedirect()
                            : console.log('');

                        if (!props?.data?.isNotification) props.data.resetForm();
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    }}
                />
            </div>
        </>
    );
};

export default ImportDataModal;
