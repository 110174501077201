import React, { useState } from 'react';
import { Col, Row, ListGroup, Dropdown, Card, Button } from 'react-bootstrap';
import { dateTimeFormat } from '../../../utility';
import ButtonElement from '../../button/ButtonElement';

import HyperDatepicker from '../../datepicker/Datepicker';

export const DateRangeFilter = (props) => {
    const setdataList = props[1];
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const onStartDateChange = (date) => {
        if (date) {
            setStartDate(date);
        }
    };
    const onEndDateChange = (date) => {
        if (date) {
            setEndDate(date);
        }
    };

    const handleSubmit = () => {
        setdataList({
            startDate: startDate ? dateTimeFormat(startDate) : '',
            endDate: endDate ? dateTimeFormat(endDate) : '',
        });
    };

    const clearFilter = () => {
        setStartDate('');
        setEndDate('');
        setdataList(null);
    };

    return (
        <>
            <Dropdown align="right" className="dropdown-card">
                <Dropdown.Toggle bsPrefix="btn-sm btn-default btn-filter shadow-none">
                    <i className="mdi mdi-filter-menu-outline"></i> Filter
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Card.Header>
                        <Row className="justify-content-between align-items-center">
                            <Col xs="auto">
                                <h4 className="card-header-title">Filters</h4>
                            </Col>
                            <Col xs="auto">
                                <Button
                                    onClick={clearFilter}
                                    variant="link"
                                    className="ml-auto p-0 custom-button-shadow">
                                    Clear
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <ListGroup>
                            <ListGroup.Item className="border-0">
                                <Row className="g-1">
                                    <Col xs={5}>
                                        <HyperDatepicker
                                            value={startDate}
                                            inputClass="form-control-light"
                                            hideAddon={true}
                                            onChange={(date) => {
                                                onStartDateChange(date);
                                            }}
                                        />
                                    </Col>
                                    <Col xs={2} className="text-center">
                                        <p className="mt-1">To</p>
                                    </Col>
                                    <Col xs={5}>
                                        <HyperDatepicker
                                            value={endDate}
                                            inputClass="form-control-light"
                                            hideAddon={true}
                                            onChange={(date) => {
                                                onEndDateChange(date);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                        <ButtonElement
                            name="Filter"
                            className="btn btn-primary w-100 mt-1 custom-button-shadow"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={startDate === null || endDate === null}
                        />
                    </Card.Body>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};
