import React from 'react';
import { Route } from 'react-router-dom';
const PrivacyPolicy = React.lazy(() => import('../pages/public/PrivacyPolicy'));
const Login = React.lazy(() => import('../pages/public/Login'));
const ForgetPassword = React.lazy(() => import('../pages/public/ForgetPassword'));
const SetPassword = React.lazy(() => import('../pages/public/SetPassword'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const ActivateAccount = React.lazy(() => import('../pages/account/ActivateAccount'));

export const PublicRoute = [
    {
        path: '/privacypolicy',
        name: 'Privacy Policy',
        component: PrivacyPolicy,
        route: Route,
        exact: true,
        breadcrumbs: [{ label: 'Privacy Policy', path: '/', active: true }],
        pathExact: '/privacypolicy',
    },
    {
        path: '/public/login',
        name: 'Login',
        component: Login,
        exact: true,
        route: Route,
        pathExact: '/public/login',
    },
    {
        path: '/public/forgot-password',
        name: 'Forget Password',
        component: ForgetPassword,
        exact: true,
        route: Route,
        pathExact: '/public/forgot-password',
    },
    {
        path: '/public/set-password',
        name: 'Set Password',
        component: SetPassword,
        exact: true,
        route: Route,
        pathExact: '/public/set-password',
    },
    {
        path: '/public/reset-password',
        name: 'Reset Password',
        component: SetPassword,
        exact: true,
        route: Route,
        pathExact: '/public/reset-password',
    },
    {
        path: '/account/logout',
        name: 'Logout',
        component: Logout,
        exact: true,
        route: Route,
        pathExact: '/account/logout',
    },
    {
        path: '/account/activateaccount',
        name: 'Signup',
        component: ActivateAccount,
        exact: true,
        route: Route,
        pathExact: '/account/activateaccount',
    },
    {
        path: '/public/register-device',
        name: 'New Device Registered',
        component: Login,
        exact: true,
        route: Route,
        pathExact: '/public/register-device',
    },
];
