// @flow
import React, { useEffect } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useNotification } from '@ovrture/react-redux';

const SendNotificationModal = (props) => {
    const { modalDispatch } = useModalContext();
    const { Toast } = useNotification();

    return (
        <>
            <p>Would you like to send a notification to all users that this content panel has been created?</p>
            <div className="modal-footer">
                {/* {props.active !== 0 && (
                    <ButtonElement
                        name="Previous"
                        variant="outline-primary"
                        className="btn-prev"
                        type="button"
                        onClick={() => props.setActive(props.active - 1)}
                    />
                )} */}
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() => {
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    }}
                />
                <ButtonElement
                    name="No"
                    variant="outline-primary"
                    className="btn-next"
                    type="button"
                    onClick={() => {
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                        Toast.success({ description: 'Broadcast alert activated.' });
                        setTimeout(() => {
                            props?.data?.redirectToLandingPage();
                        }, 2000);
                    }}
                />
                <ButtonElement
                    name="Yes"
                    variant="primary"
                    className="btn-next"
                    type="button"
                    onClick={() => props?.data?.handleSendNotification()}
                />
            </div>
        </>
    );
};
export default SendNotificationModal;
