// @flow
import React, { useState, useEffect } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useSelector, useDispatch } from 'react-redux';
import { addReport, reportReset } from '@ovrture/react-redux';

const BuildReportModal = (props) => {
    const { modalDispatch } = useModalContext();

    const dispatchAction = useDispatch();

    const { report } = useSelector((state) => ({
        report: state?.report?.addReport,
    }));

    useEffect(() => {
        if (report) {
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            dispatchAction(reportReset());
        }
    }, [report]);

    const buildReport = () => {
        dispatchAction(
            addReport({
                fkRecordId: props?.data?.id,
                fkTemplateId: props?.data?.fkTemplateId,
            })
        );
    };

    return (
        <>
            <p>
                This report will be built by pulling the funds (if any) linked to this record and will inherit the
                content panels per the default sequence set by the System Administrator. Once built, the report can be
                edited.
            </p>
            <div className="button-list d-flex justify-content-center mt-2">
                <ButtonElement
                    name="View/Edit Linked Funds"
                    variant="outline-primary"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.OPEN_MODAL,
                            payload: {
                                modalAction: (e) => console.log(e),
                                modalVariant: 'primary',
                                modalDataKey: ['common', 'linkFunds'],
                                data: {
                                    id: props?.data?.id,
                                    username: props?.data?.username,
                                    portfolioData: props?.data,
                                    roleId: props?.data?.roleId,
                                },
                            },
                        })
                    }
                />
            </div>
            <div className="modal-footer  d-flex justify-content-center mt-3">
                <ButtonElement name="Build" variant="primary" type="button" onClick={() => buildReport()} />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default BuildReportModal;
