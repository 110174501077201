export const boldString = (subStr) => subStr.bold();
// Common Error Message for Form Inputs
export const Message = {
    GenericMsg: 'Required.',
    urlValidatorMessage: 'Please enter valid url.',
    phoneValidatorMessage: 'Please enter 10 digit phone number (with area code, if applicable).',
    emailValidatorMessage: 'Please enter a valid Email.',
    spaceAndSpecialCharacterValidatiorMessage: 'Incompatible special characters.',
    nameValidatorMessage:
        'Incompatible special characters; ' + boldString("space '. - * ( ) : & _ !") + ' accent permitted.',
    prefixAndSuffixValidatorMessage: 'Incompatible special characters; ' + boldString(". , - : _'") + ' permitted.',
    titleValidatorMessage:
        'Incompatible special characters; ' + boldString("@ & , _ - ; : / . '") + ' accent permitted.',
    infoValidatorMessage:
        'Incompatible special characters; ' +
        boldString("~ ! @ # $ % & * ( ) _ + - = [ ] | ; : ' / ? . ,") +
        ' permitted.',
    classYearValidatorMessage: 'Incompatible special characters; ' + boldString(", ; : - _ '") + ' permitted.',
    addressValidatorMessage:
        'Incompatible special characters; ' + boldString('` ! @ # & * ( ) - _ = + | : ; \' , / . "') + ' permitted.',
    cityStateZipCountryValidatorMessage:
        'Incompatible special characters; ' + boldString('space , . : - ( ) /') + ' permitted.',
    numberWithHyphenAndDotValidatorMessage: 'Incompatible special character; ' + boldString('- .') + ' only permitted.',
};
