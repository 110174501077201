// @flow
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';

// component
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { cpPreviewImage, cpFolderReset } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import { addSlickSlider } from '../../../helpers/editor/slick/SlickSlider';
import _ from 'underscore';
import { Helmet } from 'react-helmet';

const ContentPanelPreviewModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();

    const { cpPreview, details } = useSelector(
        (state) => ({
            cpPreview: state?.cpFolder?.cpPreview,
            details: state?.auth?.me,
        }),
        _.isEqual
    );

    useEffect(() => {
        props?.data?.data?.id && dispatch(cpPreviewImage({ cpFolderViewId: props?.data?.data?.id }));
    }, []);

    useEffect(() => {
        if (cpPreview?.data?.htmlCode) {
            document.getElementById('previewOnly').innerHTML = cpPreview?.data?.htmlCode;
            let doc = new DOMParser().parseFromString(cpPreview?.data?.htmlCode, 'text/html');
            const photoGalleryCarousel = doc?.querySelectorAll('#photoGalleryCarousel .frame_adjuster');
            photoGalleryCarousel.length > 0 && setTimeout(() => addSlickSlider(photoGalleryCarousel), 1000);
        }
    }, [cpPreview?.data?.htmlCode]);

    return (
        <>
            <Helmet>
                <link
                    rel="stylesheet"
                    href={`https://common-assets-relay.s3.us-west-2.amazonaws.com/demo/react-test/Org${details?.organaisationId}/assets/style/style.css`}></link>
            </Helmet>
            <Card>
                <Card.Body className="previewOnly" id="previewOnly"></Card.Body>
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Back"
                    variant="outline-primary"
                    type="button"
                    onClick={() => {
                        if (props?.data?.jstreeData) {
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: (e) =>
                                        modalDispatch({
                                            type: modalActions.CLOSE_MODAL,
                                        }),
                                    modalVariant: 'primary',
                                    modalDataKey: ['common', 'selectContentPanel'],
                                    data: {
                                        info: props?.data?.info,
                                        handleData: props?.data?.handleData,
                                        jstreeData: props?.data?.jstreeData,
                                        data: props?.data?.data,
                                    },
                                },
                            });
                        } else {
                            props.hideModal();
                        }
                    }}
                />
            </div>
        </>
    );
};

export default ContentPanelPreviewModal;
