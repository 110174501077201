export const CustomLoader = (props) => {
    return (
        props.loader && (
            <>
                <div className="spinner-border-loader">
                    <img src={'/static/media/loader@2x.c44e3899.gif'}></img>
                </div>
                {document.getElementsByClassName('modal-backdrop')?.length === 0 && (
                    <div className="modal-backdrop-notification opacity-less loader-backdrop"></div>
                )}
            </>
        )
    );
};
