// @flow
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// components
import { VimeoAccountValidation } from '../../../../components/form/validation';
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import { useSelector, useDispatch } from 'react-redux';
import { organizationVimeoUpdate, organizationReset, organizationFetch } from '@ovrture/react-redux';

const VimeoAccountModal = (props) => {
    const dispatch = useDispatch();
    let { organization, update } = useSelector((state) => ({
        organization: state?.organization?.fetch?.data,
        update: state?.organization?.vimeoUpdate,
    }));

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({ resolver: VimeoAccountValidation, mode: 'onBlur' });
    const watchAll = watch();
    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        dispatch(organizationFetch({ orgId: props.data.orgId }));
    }, []);

    useEffect(() => {
        if (organization) {
            setValue('vimeoToken', organization?.vimeoToken);
            setValue('vimeoUploadUri', organization?.vimeoUploadUri);
        }
        if (update) {
            props.hideModal();
            update = null;
            dispatch(organizationReset());
            dispatch(organizationFetch({ orgId: props.data.orgId }));
        }
    }, [organization, update]);
    useEffect(() => {
        if (
            watchAll &&
            watchAll.vimeoToken !== null &&
            watchAll.vimeoToken !== '' &&
            watchAll.vimeoUploadUri !== null &&
            watchAll.vimeoUploadUri !== ''
        ) {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);

    const onSubmit = (data) => {
        data.id = organization.id;
        dispatch(organizationVimeoUpdate(data));
    };
    const toggle = () => props.hideModal();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput type="text" id="vimeoToken" label="Token *" errors={errors} register={register} />
            <FormInput type="text" id="vimeoUploadUri" label="Upload Url *" errors={errors} register={register} />
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Update" variant="primary" type="submit" disabled={!isValidated} />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default VimeoAccountModal;
