import React, { createContext, useReducer, useContext } from 'react';
import { TreeViewReducer, TreeViewIntialState } from '../reducers/TreeViewReducer';

const TreeViewContext = createContext('');

export const TreeViewProvider = (props) => {
    const [treeState, treeDispatch] = useReducer(TreeViewReducer, TreeViewIntialState);
    let treeData = { treeState, treeDispatch };

    return (
        <TreeViewContext.Provider value={treeData}>
            {props.children}
        </TreeViewContext.Provider>
    );
};


export function useTreeViewContext() {
    const context = useContext(TreeViewContext);
    if (context === undefined) {
        throw new Error('useTreeViewContext must be used within a TreeViewProvider');
    }
    return context;
}


