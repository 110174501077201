//@flow
import React from 'react';
//components
import ButtonElement from '../../../components/button/ButtonElement';

const ApprovalConfimation = (props) => {
    return (
        <>
            <p>Are you sure you want to approve this {props?.data?.data?.name === 'Site' ? 'site' : 'report'}?</p>
            <div className="modal-footer">
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() => props?.data?.hideModal()}
                />
                <ButtonElement
                    name="Next"
                    variant="primary"
                    className="btn-next"
                    type="button"
                    onClick={() => props.setActive(props.active + 1)}
                />
            </div>
        </>
    );
};

export default ApprovalConfimation;
