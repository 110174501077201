// @flow
import React from 'react';
//components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const SessionRecordingModal = (props) => {
    const { modalDispatch } = useModalContext();
    return (
        <>
            <iframe src={props?.url} className="recording-iframe" />
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default SessionRecordingModal;
