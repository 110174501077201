// @flow
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Card, Col, Row, ListGroup } from 'react-bootstrap';
// components
import { table as TableColumn } from '../../../constants/table';
import Table from '../../../components/table/Table';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { FormInput } from '../../../components/form/input';
import { useDispatch, useSelector } from 'react-redux';
import {
    fundList,
    recordsList,
    fundLinkSingle,
    fundUnlinkSingle,
    recordFundReset,
    recordFundLinkUnlinkReset,
    useNotification,
} from '@ovrture/react-redux';
import config from '../../../config';
import ListItem from '../../../components/listItem';
import service from '../../../constants/service.constant';
import debounce from 'lodash.debounce';

const LinkedRecordsModal = (props) => {
    const { Toast } = useNotification();
    const { modalDispatch } = useModalContext();
    const [enteredRecordVal, setEnteredRecordVal] = useState(null);
    const [page, setpage] = useState(0);
    const [dataList, setdataList] = useState([]);
    const [recordId, setrecordId] = useState(null);
    const [recordList, setRecordList] = useState([]);
    const [recordName, setRecordName] = useState([]);
    const [itemSelected, setItemSelected] = useState(false);

    const [deleted, setDeleted] = useState(false);
    const [fundLoading, setFundLoading] = useState(false);
    const dispatch = useDispatch();
    const { funds, records, linkSingle, unlinkSingled } = useSelector((state) => ({
        funds: state?.fund?.list,
        records: state?.records?.list,
        linkSingle: state?.fund?.linkSingle,
        unlinkSingled: state?.fund?.unlinkSingle,
    }));
    useEffect(() => {
        if (linkSingle || unlinkSingled) {
            dispatch(recordFundLinkUnlinkReset());
            getData(unlinkSingled ? 0 : page);
            resetModal();
        }
        if (linkSingle?.code === service.alreadyExist) {
            Toast.danger({ description: linkSingle?.data });
            dispatch(recordFundLinkUnlinkReset());
            resetModal();
            setEnteredRecordVal();
        }
    }, [linkSingle, unlinkSingled]);

    useEffect(() => {
        if (props.data.pkFundId) getData(page, true);
    }, []);

    useEffect(() => {
        if (funds?.content) {
            setFundLoading(false);
            setDeleted(false);
            funds.content.map((fnd) => {
                fnd['Name'] = fnd.colRecordFullName;
                fnd['recordReference'] = fnd.colRecordReferenceNo;
                fnd['id'] = fnd.pkRecordsId;
            });
            setdataList(funds?.content);
            setpage(funds?.number);
        }
    }, [funds]);

    // useEffect(() => {
    //     if (enteredRecordVal && matchRecordList(enteredRecordVal)) {
    //         dispatch(
    //             recordsList({
    //                 isAnd: true,
    //                 isUnpaged: true,
    //                 searchText: enteredRecordVal,
    //             })
    //         );
    //     } else if (enteredRecordVal == null || enteredRecordVal == '') resetModal();
    // }, [enteredRecordVal]);

    useEffect(() => {
        if (records?.content) {
            setRecordList(records?.content);
        }
    }, [records?.content]);

    const getData = (page, isLoaderNeeded) => {
        isLoaderNeeded && setFundLoading(true);
        dispatch(
            fundList({
                pkFundId: props.data.pkFundId,
                page: page,
                size: config.PAGE_SIZE,
            })
        );
    };

    const resetModal = () => {
        setItemSelected(false);
        setRecordList([]);
    };
    const matchRecordList = (val) => {
        let matchItems = dataList.find((f) => f.Name === val);
        if (matchItems) return false;
        else return true;
    };
    const handleAddRecord = (pkRecordsId) => {
        setEnteredRecordVal([]);
        pkRecordsId &&
            dispatch(
                fundLinkSingle({
                    pkRecordsId: pkRecordsId,
                    fkFundId: props.data.pkFundId,
                })
            );
    };

    const DeleteAction = ({ row }) => {
        return (
            <>
                <ButtonElement
                    className={deleted ? 'action-icon pe-none' : 'action-icon hover-danger'}
                    icon={<i className="mdi mdi-delete" />}
                    tooltip="Delete"
                    onClick={() => {
                        dispatch(
                            fundUnlinkSingle({
                                fkRecordsId: row.original.pkRecordsId,
                                fkFundId: props.data.pkFundId,
                            })
                        );
                        setDeleted(true);
                    }}
                />
            </>
        );
    };

    const handleChange = (e) => {
        setEnteredRecordVal(e.target.value);
        setTimeout(() => {
            setItemSelected(true);
        }, 2000);
    };

    const sendQuery = (val) => {
        // setEnteredRecordVal(val);
        if (val && matchRecordList(val)) {
            setTimeout(() => {
                dispatch(
                    recordsList({
                        isAnd: true,
                        isUnpaged: true,
                        searchText: val,
                    })
                );
            }, 500);
        } else if (val == null || val == '') resetModal();
    };

    const delayedQuery = useCallback(
        debounce((q) => {
            sendQuery(q.target.value);
        }, 1000)
    );

    const handleReset = (e) => {
        setEnteredRecordVal(e.target.value);
        resetModal();
    };

    return (
        <>
            <p>The following records are linked to this fund:</p>
            <div className="linked-record mb-3">
                <Row>
                    {/* <b className="mb-1">Enter Record Reference Number or Name</b> */}
                    <Col md={10}>
                        <FormInput
                            type="text"
                            id="recordNumber"
                            className="mb-0"
                            // floatinglabel="false"
                            label="Enter Record Reference Number or Name"
                            maxLength="120"
                            feedback="hide"
                            onChange={(e) => {
                                setEnteredRecordVal(e.target.value);
                                setTimeout(() => {
                                    setItemSelected(true);
                                }, 2000);
                                e.target.value
                                    ? delayedQuery(e)
                                    : (e.target.value == null || e.target.value == '') && handleReset(e);
                            }}
                            value={enteredRecordVal}
                        />
                        {recordList && itemSelected && enteredRecordVal && (
                            <>
                                <ListGroup
                                    className={
                                        enteredRecordVal
                                            ? 'listheader custom-list-group-ellipsis custom-listgroup height-auto'
                                            : ''
                                    }>
                                    {recordList.map((rcd) => {
                                        return (
                                            <ListItem
                                                key={rcd.pkRecordsId}
                                                onClick={() => {
                                                    setEnteredRecordVal(rcd.colRecordFullName);
                                                    setrecordId(rcd.pkRecordsId);
                                                    setRecordName(rcd.colRecordFullName);
                                                    setItemSelected(false);
                                                }}>
                                                {rcd.colRecordFullName}
                                            </ListItem>
                                        );
                                    })}
                                    {recordList?.length === 0 && <b className="p-2">{'No Record Found.'}</b>}
                                </ListGroup>
                            </>
                        )}
                    </Col>
                    <Col md={2} className="px-1">
                        <ButtonElement
                            name="Add"
                            className="mt-10"
                            variant="primary"
                            type="button"
                            disabled={enteredRecordVal ? false : true}
                            onClick={(e) => handleAddRecord(recordId)}
                        />
                    </Col>
                </Row>
            </div>
            <Card>
                <Table
                    columns={TableColumn.contentPanel.linkedRecords([DeleteAction])}
                    data={dataList || []}
                    pageSizes={funds?.size}
                    pageNumber={funds?.number}
                    totalElements={funds?.totalElements}
                    totalPages={funds?.totalPages}
                    paginationDispatch={fundList}
                    sizePerPageList={TableColumn.sizePerPageList(dataList)}
                    customPaginationProps={{
                        isAnd: true,
                        page: funds?.number,
                        size: funds?.size,
                        pkFundId: props.data.pkFundId,
                    }}
                    isSortable={true}
                    pagination={true}
                    isSearchable={false}
                    isFilter={false}
                    isSelectable={false}
                    theadClass={TableColumn.table_light}
                    tableClass="report-data-linked-record"
                    paginationDisplayDrowpdownHide={true}
                    loading={fundLoading}
                />
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) => {
                        resetModal();
                        dispatch(recordFundReset());
                        modalDispatch({ type: modalActions.CLOSE_MODAL });
                    }}
                />
            </div>
        </>
    );
};

export default LinkedRecordsModal;
