// @flow
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import TreeView from '../../../components/treeview';
import {
    assetFolderViewInitialListChange,
    assetFolderViewReset,
    assetFolderViewUpdate,
    assetUpdate,
} from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../../reducers/actions';
import { useModalContext } from '../../../contexts/ModalContext';
import $ from 'jquery';

const ChangeLocationModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [addfolderDisabled, setAddFolderDisabled] = useState(true);
    const [data, setData] = useState([]);
    const [contentData, setContentData] = useState();
    const [loading, setLoading] = useState(true);
    const [toNode, setToNode] = useState(null);
    const dispatch = useDispatch();

    const { initialList, updated, authInfo } = useSelector((state) => ({
        initialList: state?.assetFolderView?.initialListChange,
        updated: state?.assetFolderView?.update,
        authInfo: state?.auth?.me,
    }));

    useEffect(() => {
        if (updated) {
            props.data.handleActions(props?.data?.fromNode?.original?.parent_id, toNode?.original?.id);
            props.hideModal();
        }
    }, [updated]);

    useEffect(() => {
        setTimeout(() => {
            getInitialFolderList();
        }, 1500);
    }, []);

    const getInitialFolderList = (parentId = '#') => {
        const ele = document.getElementById(parentId);
        const changeLocation = $('.changeLocation');
        if (ele) {
            const ele1 = changeLocation.find('#' + parentId);
            ele1.find('.jstree-icon.jstree-ocl').addClass('loading');
        }
        dispatch(assetFolderViewInitialListChange({ processId: '', parentId }));
    };

    useEffect(() => {
        if (initialList?.data) {
            dispatch(assetFolderViewReset());
            setLoading(false);
            async function createInitialData() {
                await manageTreeData(data);
            }
            if (data.length > 0) {
                createInitialData(initialList?.data);
            } else {
                initialList?.data?.forEach((el) => {
                    data.push({
                        id: el.id,
                        parent_id: el.id,
                        text: el?.name || el?.text,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        canBeDeleted: el.canBeDeleted,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        assetRootNodeId: el.assetRootNodeId,
                        userId: el.userId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                    });
                });
                setData([...data]);
            }
        }
    }, [initialList]);

    const manageTreeData = (list) => {
        initialList?.data?.forEach((el) => {
            list?.forEach((d) => {
                if (el?.parentId === d?.id) {
                    d.child = false;
                    let disabled = false;
                    if (props?.data?.fromNode?.id == el.id || el.icon === 'glyphicon glyphicon-text-background-cp')
                        disabled = true;
                    d.children.push({
                        id: el.id,
                        parent_id: el.parentId,
                        text: el?.text || el?.name,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        canBeDeleted: el.canBeDeleted,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        userId: el.userId,
                        assetRootNodeId: el.assetRootNodeId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                        state: {
                            disabled,
                        },
                    });
                    const filterArry = d.children.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
                    d.children = [...filterArry];
                } else {
                    if (d.children.length > 0) {
                        manageTreeData(d.children);
                    }
                }
            });
        });
        setData([...list]);
    };

    function handleGetList(e, list) {
        setToNode(list?.node);
        const id = list?.node?.original?.id;
        if (list.event?.type === 'dblclick' && list?.node?.original?.id && list?.node?.original?.child) {
            getInitialFolderList(id);
        }
        setContentData({ ...list?.node?.original, parentRootId: list?.node?.parent });
        if (list?.node?.original?.id) {
            const assetFile =
                props?.data?.pageName === 'Assets' &&
                parseInt(id) == parseInt(props.data.fromNode?.original?.parent_id);

            if (
                (list?.node.parent === '#' && list?.node?.original.text === 'SYSTEM CLOUD') ||
                list?.node?.original?.id === props?.data?.fromNode?.id
            ) {
                let disabled = false;
                if (
                    // props?.data?.pageName === 'Assets' ||
                    list?.node?.original?.parent_id === props.data.fromNode?.original?.parent_id ||
                    assetFile
                ) {
                    disabled = true;
                }
                setAddFolderDisabled(disabled);
            } else if (
                (list?.node?.original.icon == 'mdi mdi-cloud' && list?.node?.original.text == 'BRAND CLOUD') ||
                list?.node?.original.icon === 'mdi mdi-folder-lock\t'
            ) {
                setAddFolderDisabled(true);
            } else if (
                list?.node?.original.icon == 'fa-fa-folder-brand-closed' ||
                list?.node?.original.icon == 'fa-fa-folder-palette-closed' ||
                list?.node?.original.icon == 'mdi mdi-folder-account'
            ) {
                setAddFolderDisabled(false);
            } else if (list?.node.icon == 'mdi mdi-folder') {
                setAddFolderDisabled(assetFile);
            } else if (list?.node.children.length) {
                setAddFolderDisabled(false);
            } else if (
                list?.node.icon == 'glyphicon glyphicon-image' ||
                list?.node.icon == 'glyphicon glyphicon-doc-file' ||
                list?.node.icon == 'glyphicon glyphicon-video' ||
                list?.node.icon == 'glyphicon glyphicon-pdf-file'
            ) {
                setAddFolderDisabled(true);
            }
        }
    }

    const handleClick = () => {
        const parentId = toNode?.original?.id;
        props.data.fromNode.original.parentId = parentId;
        let activeJstreePath = JSON.parse(localStorage.getItem('activeJstreePath'));
        console.log(activeJstreePath);
        localStorage.removeItem('activeJstreePath');
        props.data.setMovedPath({
            data: data,
            path: activeJstreePath,
            fromNodeParentId: props?.data?.data?.parent_id,
            fromNodeId: props?.data?.data?.id,
        });
        dispatch(assetFolderViewUpdate(props?.data?.fromNode?.original));
        sessionStorage.setItem(
            process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY,
            props?.data?.fromNode?.icon === 'mdi mdi-folder' ? 'Folder moved.' : 'Asset moved.'
        );
    };

    return (
        <Row className="jstree-custom-style">
            {loading && (
                <div className="jstree-default">
                    <i className="loading jstree-icon jstree-ocl" />
                    <span className="loading-text">Loading...</span>
                </div>
            )}
            <TreeView data={data} handleChange={handleGetList} identifier="changeLocation" />
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Move"
                    variant="outline-primary"
                    onClick={() => {
                        modalDispatch({
                            type: modalActions.OPEN_MODAL,
                            payload: {
                                modalAction: handleClick,
                                modalVariant: 'primary',
                                modalDataKey: ['common', 'changeLocationConfirmation'],
                                data: props?.data?.fromNode?.icon === 'mdi mdi-folder' ? 'folder' : 'asset',
                            },
                        });
                    }}
                    disabled={addfolderDisabled}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() => {
                        dispatch(assetFolderViewReset());
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    }}
                />
            </div>
        </Row>
    );
};

export default ChangeLocationModal;
