const EnterKeyFormSubmit = (props) => {
    document.onkeydown = function (e) {
        try {
            if (e.code == 'Enter' || e.keyCode == 13) {
                e.preventDefault();
                const modalFormSubmitBtn = document?.querySelector('.modal-open .show form button[type*="submit"]');
                const pageFormSubmitBtn = document?.querySelector('.content-page form button[type*="submit"]');
                const modalActionBtn = document.querySelector(
                    '.modal.show button[type*="button"]:not([disabled]):not(.skippable):not(.btn-prev):not(.modal-info-icon):not(.action-icon)'
                );
                if (modalActionBtn) {
                    modalActionBtn.click();
                } else if (!modalFormSubmitBtn?.attributes?.disabled && modalFormSubmitBtn !== null) {
                    modalFormSubmitBtn.click();
                } else if (
                    !document?.body?.classList?.contains('modal-open') &&
                    !pageFormSubmitBtn?.attributes?.disabled
                ) {
                    pageFormSubmitBtn.click();
                }
            }
        } catch (e) {}
    };
    return props.children;
};

export default EnterKeyFormSubmit;
