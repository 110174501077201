//@flow
import React, { useState } from 'react';
import MultiStepForm from '../../../helpers/multiStep/Index';
//components
import ResetPasswordAuthentication from '../commonModals/ResetPasswordAuthentication';
import ResetPasscode from '../commonModals/ResetPasscodeModal';

const MultiStepResetPassword = (props) => {
    const [active, setActive] = useState(0);

    const steps = [
        {
            name: 'Authentication Required',
            component: (
                <ResetPasswordAuthentication
                    active={active}
                    setActive={setActive}
                    data={props.data}
                    hideModal={props.hideModal}
                />
            ),
        },
        {
            name: 'Reset Passcode',
            component: (
                <ResetPasscode active={active} setActive={setActive} data={props.data} hideModal={props.hideModal} />
            ),
        },
    ];

    return (
        <>
            <MultiStepForm activeStep={active} showNavigation={false} steps={steps} />
        </>
    );
};

export default MultiStepResetPassword;
