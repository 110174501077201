import React, { useState, useEffect } from 'react';
import ButtonElement from '../button/ButtonElement';
import { Row, Col } from 'react-bootstrap';
import Cropper from 'cropperjs';

function ImageCropper(props) {
    const {
        image,
        hideModal,
        setCropData,
        zoom,
        aspectRatio,
        canvasData,
        cropBoxData,
        minCanvasHeight,
        minCanvasWidth,
        minCropBoxHeight,
        minCropBoxWidth,
        minContainerWidth,
        minContainerHeight,
        name,
    } = props;
    const [zoomTo, setZoomTo] = useState(zoom);
    const [cropper, setCropper] = useState('');
    const [loading, setLoading] = useState(true);
    const getCropData = () => {
        if (typeof cropper !== 'undefined') {
            setCropper(cropper.getCroppedCanvas().toDataURL());
            cropper.getCroppedCanvas().toBlob((blob) => {
                setCropData({
                    base64Image: cropper.getCroppedCanvas().toDataURL(),
                    blob: blob,
                    filename: props?.filename,
                });
            });
            hideModal && props?.hideModal();
        }
    };
    const handleAction = (actionFor = '') => {
        const imgData = cropper.getImageData();
        actionFor == 'rotate-clock'
            ? cropper.rotate(90)
            : actionFor == 'rotate-anti-clock'
            ? cropper.rotate(-90)
            : actionFor == 'scaleX'
            ? cropper.scaleX(imgData.scaleX == 1 ? -1 : 1)
            : actionFor == 'scaleY'
            ? cropper.scaleY(imgData.scaleY == 1 ? -1 : 1)
            : cropper.rotate(0);
    };

    const zoomInOut = (val) => {
        setZoomTo(val);
        const containerData = cropper.getContainerData();
        cropper.zoomTo(val, {
            x: containerData.width / 2,
            y: containerData.height / 2,
        });
    };

    document.addEventListener('zoom', (e) => {
        if (e.detail.ratio > 3) {
            e.preventDefault();
        } else {
            setZoomTo(e.detail.ratio);
        }
    });

    document.addEventListener('ready', function (e) {
        setLoading(false);
        if (cropper && cropper?.initialCanvasData?.height) {
            const ratio = zoom ? zoom : cropper?.initialCanvasData?.height / cropper?.initialCanvasData?.naturalHeight;
            // setZoomTo(ratio);
            // zoomInOut(ratio);
            cropBoxData && cropper.setCropBoxData(cropBoxData);
            canvasData && cropper.setCanvasData(canvasData);
        }
    });

    useEffect(() => {
        if (image) {
            const img = document.getElementById('image');
            const cropper = new Cropper(img, {
                viewMode: 1,
                aspectRatio:
                    props?.otherProps?.minCropBoxHeight > 0 && props?.otherProps?.minCropBoxWidth > 0
                        ? props?.otherProps?.minCropBoxWidth / props?.otherProps?.minCropBoxHeight
                        : 1 / 1,
                autoCropArea: 0,
                cropBoxMovable: false,
                dragMode: 'move',
                cropBoxResizable: false,
                toggleDragModeOnDblclick: false,
                minCanvasWidth: minCanvasHeight || 0,
                minCanvasHeight: minCanvasWidth || 0,
                minCropBoxWidth: minCropBoxHeight || 0,
                minCropBoxHeight: minCropBoxWidth || 0,
                minContainerWidth: minContainerWidth || 200,
                minContainerHeight: minContainerHeight || 100,
            });
            setCropper(cropper);
        }
        return () => {
            setCropper('');
        };
    }, [image]);

    return (
        <>
            <div className="cropper-main-container">
                <Row>
                    <center>
                        <strong>{loading && 'Loading...'}</strong>
                    </center>
                    <Col md={12}>
                        <img
                            id="image"
                            src={image}
                            alt=""
                            style={{
                                visibility: cropper?.initialCanvasData?.height ? 'visible' : 'hidden',
                            }}
                        />
                    </Col>
                </Row>
                {image && (
                    <Row>
                        <Col sm={6} md={7}>
                            <>
                                <span>
                                    <i className="mdi mdi-image-outline font-20"></i>

                                    <input
                                        type="range"
                                        value={zoomTo ? zoomTo : 0.41}
                                        onChange={(e) => zoomInOut(e.target.value)}
                                        className="form-range w-70 m-3 vertical-sub"
                                        min="0"
                                        max={zoomTo > 2 ? zoomTo : 1.82}
                                        step="0.1"
                                        id="customRange2"></input>

                                    <i className="mdi mdi-image-outline font-24"></i>
                                </span>
                            </>
                        </Col>
                        <Col sm={6} md={5} className="text-end">
                            <ButtonElement
                                icon={<i className="mdi mdi-rotate-right font-20"></i>}
                                className="action-icon hover-primary p-0"
                                tooltip="Rotate clockwise"
                                onClick={() => {
                                    handleAction('rotate-clock');
                                }}
                            />
                            <ButtonElement
                                icon={<i className="mdi mdi-rotate-left font-20"></i>}
                                className="action-icon hover-primary p-0"
                                tooltip="Rotate anti-clockwise"
                                onClick={() => {
                                    handleAction('rotate-anti-clock');
                                }}
                            />
                            <ButtonElement
                                icon={<i className="mdi mdi-arrow-left-right-bold font-20"></i>}
                                className="action-icon hover-primary p-0"
                                tooltip="Flip horizontally"
                                onClick={() => {
                                    handleAction('scaleX');
                                }}
                            />
                            <ButtonElement
                                icon={<i className="mdi mdi-arrow-up-down-bold font-20"></i>}
                                className="action-icon hover-primary p-0"
                                tooltip="Flip vertically"
                                onClick={() => {
                                    handleAction('scaleY');
                                }}
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col className="text-center">
                        {image && (
                            <div className="box">
                                <ButtonElement type="button" name="Save" variant="primary" onClick={getCropData} />
                            </div>
                        )}
                        {hideModal && (
                            <ButtonElement
                                type="button"
                                name="Cancel"
                                variant="light"
                                onClick={() => {
                                    props?.hideModal();
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ImageCropper;
