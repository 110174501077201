import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormInput } from '../../../components/form/input';
import { useForm } from 'react-hook-form';
import { resetModalFormValidation } from '../../../components/form/validation';
import ButtonElement from '../../../components/button/ButtonElement';
import { userResetPasswordCommon, userResetPasswordCommonReset } from '@ovrture/react-redux';
import service from '../../../constants/service.constant';
import { convertIntoBase64 } from '../../../utility';

const ResetPasswordModal = (props) => {
    const [toggle, setToggle] = useState(false);
    const [toggle1, setToggle1] = useState(false);
    const [toggle2, setToggle2] = useState(false);
    const dispatch = useDispatch();
    const {
        reset,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: resetModalFormValidation, mode: 'onBlur' });

    const onSubmit = (data) => {
        if (props?.data?.isSystem) {
            data.orgId = props?.data?.original?.id;
        } else {
            data.orgId = 0;
        }
        data.userId = props?.data?.original?.systemAdmin?.id;
        data.currentPassword = convertIntoBase64(data.currentPassword);
        data.newPassword = convertIntoBase64(data.newPassword);
        data.confirmPassword = convertIntoBase64(data.confirmPassword);
        dispatch(userResetPasswordCommon(data));
    };

    const { resetPasswordCommon } = useSelector((state) => ({
        resetPasswordCommon: state?.user?.resetPasswordCommon,
    }));

    useEffect(() => {
        if (resetPasswordCommon?.code === service.accepted) {
            props.hideModal();
            reset();
            dispatch(userResetPasswordCommonReset());
        }
    }, [resetPasswordCommon]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput type="hidden" id="userId" register={register} />
            <FormInput
                type="password"
                id="currentPassword"
                label="Current Password *"
                errors={errors}
                register={register}
                onChange={(e) => setToggle(e.target.value === '' ? false : true)}
            />
            <FormInput
                type="password"
                id="newPassword"
                label="New Password *"
                errors={errors}
                register={register}
                onChange={(e) => setToggle1(e.target.value === '' ? false : true)}
            />
            <FormInput
                type="password"
                id="confirmPassword"
                label="Confirm Password *"
                errors={errors}
                register={register}
                onChange={(e) => setToggle2(e.target.value === '' ? false : true)}
            />
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Proceed"
                    variant="primary"
                    type="submit"
                    disabled={toggle && toggle1 && toggle2 ? false : true}
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={(e) => props.hideModal()} />
            </div>
        </form>
    );
};

export default ResetPasswordModal;
