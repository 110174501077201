import {
    sitesModule,
    reportsModule,
    accountModule,
    assetsModule,
    privacyPolicyModule,
    profileModule,
} from '../module/generalModules';
import { portfolioModule } from '../module/portfolioModule';
import { systemSettingsModule } from '../module/systemSettingsModule';

// System Admin left sidebar
export const UserRoutes = [
    { ...portfolioModule },
    { ...sitesModule },
    { ...reportsModule },
    { ...assetsModule },
    { ...profileModule },
    { ...systemSettingsModule },
    { ...accountModule },
    { ...privacyPolicyModule },
];
