// @flow
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// components
import { AddNewBrandValidation } from '../../../../components/form/validation';
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import { brandAdd, brandUpdate, brandList, brandReset, brandAllList } from '@ovrture/react-redux';
import config from '../../../../config';
import { useDispatch, useSelector } from 'react-redux';

const BrandName = (props) => {
    const dispatch = useDispatch();
    const { list, add, update } = useSelector((state) => ({
        list: state?.brand?.list,
        add: state?.brand?.add,
        update: state?.brand?.update,
    }));

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({ resolver: AddNewBrandValidation, mode: 'onBlur' });
    const watchAll = watch();
    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        if (add) {
            dispatch(brandReset());
            dispatch(
                brandList({
                    orgId: props?.data?.orgId ? props?.data?.orgId : props?.data?.original?.fkOrgId,
                    isAnd: true,
                    page: list?.data?.number,
                    size: config.PAGE_SIZE,
                })
            );
            dispatch(
                brandAllList({
                    orgId: props?.data?.orgId ? props?.data?.orgId : props?.data?.original?.fkOrgId,
                    isUnpaged: true,
                    isAnd: true,
                })
            );
            props.hideModal();
        }
        if (update) {
            dispatch(brandReset());
            dispatch(
                brandList({
                    orgId: props?.data?.orgId ? props?.data?.orgId : props?.data?.original?.fkOrgId,
                    isAnd: true,
                    page: list?.data?.number,
                    size: config.PAGE_SIZE,
                })
            );
            dispatch(
                brandAllList({
                    orgId: props?.data?.orgId ? props?.data?.orgId : props?.data?.original?.fkOrgId,
                    isUnpaged: true,
                    isAnd: true,
                })
            );
            props.hideModal();
        }
        if (props?.data) {
            if (props.data.original) setValue('name', props.data.original.name);
            if (props.data.orgId) setValue('fkOrgId', props.data.orgId);
        }
    }, [props?.data, add, update]);

    useEffect(() => {
        if (watchAll && watchAll.name !== '') {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);

    const onSubmit = (data) => {
        if (props?.data?.orgId) {
            dispatch(brandAdd({ brandType: data.brandType }, data));
        } else {
            props.data.original.name = data.name;
            dispatch(brandUpdate(props.data.original));
        }
    };

    const toggle = () => props.hideModal();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {!props?.data?.original && (
                <>
                    <FormInput
                        type="radio"
                        id="brandType"
                        label="Sub-Brand"
                        register={register}
                        defaultValue="0"
                        defaultChecked
                    />
                    <FormInput type="radio" id="brandType" label="Co-Brand" register={register} defaultValue="1" />
                </>
            )}
            <FormInput type="hidden" id="fkOrgId" label="fkOrgId" register={register} />
            <FormInput type="text" id="name" label="Brand Name *" errors={errors} register={register} />

            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name={props?.data?.orgId ? 'Save' : 'Update'}
                    variant="primary"
                    type="submit"
                    disabled={!isValidated}
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default BrandName;
