// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
// component
import ButtonElement from '../../../components/button/ButtonElement';
import FileUploader from '../../../components/FileUploader';
import { assetBulkUploadResource, assetBulkUploadReset } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import service from '../../../constants/service.constant';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const AssetUploadModal = (props) => {
    const [files, setFiles] = useState();
    const [index, setIndex] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false);
    const [allSubmit, setAllSubmit] = useState(false);
    const dispatch = useDispatch();
    const errorMessage = 'Asset upload failed due to heavy file size.';
    const { modalDispatch } = useModalContext();

    const { uploadResource, socketSuccess, progressBar } = useSelector((state) => ({
        uploadResource: state?.assetBulkUpload?.uploadResource,
        socketSuccess: state?.socket?.success,
        progressBar: state?.socket?.loadingList,
    }));

    useEffect(() => {
        if (uploadResource?.code === service.bulkUpdated && socketSuccess) {
            setTimeout(() => {
                files[index].uploaded = true;
                files[index].uploadedSuccess = true;
                setFiles(files);
                dispatch(assetBulkUploadReset());
                fileUploaded(index);
            }, 1000);
        }
    }, [uploadResource, socketSuccess]);

    const dummyUpload = (index) => {
        files[index].progressBar = { error: errorMessage };
        files[index].uploaded = true;
        setFiles(files);
        setTimeout(() => {
            fileUploaded(index);
        }, 2000);
    };
    useEffect(() => {
        if (progressBar?.length > 0) {
            files[index].progressBar = progressBar;
            setFiles(files);
        }
    }, [progressBar]);

    const fileUploaded = (index) => {
        let newIndex = index + 1;
        if (newIndex <= files.length - 1) {
            setIndex(newIndex);
            onsubmit(newIndex);
        } else {
            setTimeout(() => {
                setIndex(newIndex);
                localStorage.removeItem('skipBulkOperation');
                !files.find((f) => f?.progressBar?.error === errorMessage) && props.hideModal();
            }, 1500);
        }
    };

    const onsubmit = (index = 0) => {
        if (files[index]) {
            setIsSubmit(true);
            localStorage.setItem('skipBulkOperation', true);
            if (files[index].typeFile === 'image' && files[index].fileSizeMore32MB) {
                dummyUpload(index);
            } else if (files[index].typeFile === 'video' && files[index].fileSizeMore1GB) {
                dummyUpload(index);
            } else {
                dispatch(
                    assetBulkUploadResource(
                        { parentId: props?.data?.fromNode?.original?.id },
                        { multipartFiles: [files[index]] }
                    )
                );
            }
        }
        //dispatch(assetBulkUploadResource({ parentId: props?.data?.fromNode?.original?.id }, { multipartFiles: files }));
    };

    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <FileUploader
                                onFileUpload={(files) => setFiles(files)}
                                onRemoveFiles={(files) => setFiles(files)}
                                index={index}
                                isSubmit={isSubmit}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Add Asset(s)"
                    variant="primary"
                    type="button"
                    onClick={() => onsubmit()}
                    disabled={files?.length > 0 && isSubmit ? true : files?.length > 0 ? false : true}
                    //disabled={files?.length > 0 ? false : true}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        files?.find((f) => f?.uploadedSuccess === true)
                            ? props.hideModal()
                            : modalDispatch({ type: modalActions.CLOSE_MODAL })
                    }
                />
            </div>
        </>
    );
};

export default AssetUploadModal;
