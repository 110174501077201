export const downloadFile = (arrayBuffer, type, fileName) => {
    var blob = new Blob([arrayBuffer], { type: type });
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display:none';
    var url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
    a.remove();
};
