import 'easymde/dist/easymde.min.css';
import React, { useState, useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyMceTextEditor = ({ label, id, value, setValue, maxLength, plugins, height, disableEnter }) => {
    const [count, setCount] = useState(0);
    const editorRef = useRef(null);
    const charCount = (editor) => editor.plugins.wordcount.body.getCharacterCount();
    const pluginsList = plugins ? plugins : [];

    const handleUpdate = (value, editor) => {
        const cCount = charCount(editor);
        if (maxLength) {
            if (cCount <= maxLength) {
                setValue(value);
                setCount(cCount);
            }
        } else {
            setCount(cCount);
            setValue(value);
        }
    };

    useEffect(() => {
        handleAppendElement();
    }, [value]);

    useEffect(() => {
        if (editorRef?.current) {
            const cCount = charCount(editorRef?.current);
            setCount(cCount);
        }
    }, [editorRef?.current]);

    const handleAppendElement = () => {
        setTimeout(() => {
            if (maxLength) {
                let list = document?.getElementsByClassName('tox-statusbar__text-container');
                for (let i = 0; i < list.length; i++) {
                    list[i]?.appendChild(document?.getElementsByClassName('text-limit')[i]);
                }
            }
        }, 500);
    };

    return (
        <>
            {label && <label>{label}</label>}
            <Editor
                id={id}
                apiKey={process.env.REACT_APP_TINY_TOKEN}
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={value}
                onEditorChange={handleUpdate}
                init={{
                    height: height ? height : 300,
                    menubar: false,
                    plugins: [...pluginsList, 'wordcount', 'charmap'],
                    toolbar:
                        'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    setup:
                        disableEnter &&
                        function (ed) {
                            ed.on('keydown', function (e) {
                                if (e.keyCode == 13) {
                                    e.preventDefault();
                                }
                            });
                        },
                }}
            />
            <p className="text-limit ms-2">{`${maxLength - count >= 0 ? maxLength - count : 0} characters`}</p>
        </>
    );
};

export default TinyMceTextEditor;
