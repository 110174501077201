// @flow
import React from 'react';
import { Card } from 'react-bootstrap';
import { useEffect } from 'react';

// component
import ButtonElement from '../../../../components/button/ButtonElement';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';

const FundPreviewModal = (props) => {
    const { modalDispatch } = useModalContext();
    const toggle = () => {
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
    };

    useEffect(() => {}, []);
    return (
        <>
            <Card>
                <Card.Body>
                    <div className="text-center no-preview text-muted">
                        <i className="mdi mdi-eye-off "></i>
                        <p className="font-16">Data not available for preview.</p>
                    </div>
                </Card.Body>
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Back" variant="light" type="button" onClick={toggle} />
            </div>
        </>
    );
};

export default FundPreviewModal;
